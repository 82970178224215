import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../images/kikoimg.svg";
import BuyerSliderImg from "../../images/buyer-slider-img.png";
import "./styles.scss";

const Loginpage = (props) => {
  const sliderSetting = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <>
      <section className="login-section">
        <div className="auth-row">
          <div className="auth-col auth-is-dark">
            <div className="auth-slider-container">
              <Slider className="auth-slider" {...sliderSetting}>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
                <div>
                  <img alt ="BuyerSliderImg" src={BuyerSliderImg} className="auth-img" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="auth-col  d-flex align-items-center justify-content-center">
            <div className="auth-wrapper">
              <div className="auth-header">
                  <img  alt ="Logo" src={Logo} />
                <h1 className="auth-title">Sign Up</h1>
                <p className="auth-subtitle">
                  Get access to your Orders, Wishlist and Recommendations
                </p>
              </div>
              <div className="auth-form-container">
                <div className="did-floating-label-content">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Full Name</label>
                </div>
                <div className="did-floating-label-content">
                  <input
                    className="did-floating-input"
                    type="email"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Email ID </label>
                </div>
                <div className="did-floating-label-content did-error-input">
                  <input
                    className="did-floating-input"
                    type="number"
                    placeholder=" "
                  />
                  <label className="did-floating-label">Mobile Number</label>
                </div>
                <div className="did-floating-label-content">
                  <button type="button" className="btn btn-primary btn-sm w-100">
                    Sign Up
                  </button>
                </div>
                <div className="auth-footer">
                  Already have an account? <a href="/signin">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Loginpage;
