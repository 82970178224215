import React from "react";
import Info from "../../components/svgicons/infoIcon";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import OrderPlacedIcon from "../../images/order-placed-icon.svg";
import "./styles.scss";

function ThankYouModal({
  openModal,
  setOpenModal,
  orderAmount,
  orderPaymentMode,
  orderDetails,
  openErrorModal,
  setOpenErrorModal
}) {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        isOpen={openModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thankyouModal"
        style={{ maxWidth: '360px', margin: 'auto' }}
      >
        <ModalHeader>
          <div className="order-placed-icon">
            <img src={OrderPlacedIcon} alt="orderPlaced"/>
          </div>
          <div className="thank-you-order">
            Thank you for your order!
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="order-number">{`Order Number : `}
            <a
              href="/buyer-order-details"
              className={'underline'}
              style={{ color: '#7459af' }}
              onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
              onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
              onClick={() => {
                setOpenModal(false);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
                navigate("/buyer-order-details", {
                  state: { orderId: orderDetails?._id },
                });
              }}
            >
              {`${orderDetails?.orderId}`}
            </a></div>
          <div className="d-flex justify-content-center">
            <button
              className="trackOrder"
              onClick={() => {
                setOpenModal(true);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
              }}
            >
              CONTINUE SHOPPING
            </button>
          </div>
          <div style={{ height: "0px" }}></div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openErrorModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thankyouModal oops-modal">
        <ModalHeader>
          <div>
            <h1>
              <Info className="icon" />
              Oops!!
              <br /> <br />
              Somthing Went Wrong !
              <br /> <br />
              Your money will be refunded back in 2-3 business days.
            </h1>
            <button
              className="trackOrder"
              onClick={() => {
                setOpenErrorModal(false);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
              }}
            >
              Explore More
            </button>
          </div>

        </ModalHeader>
        <div style={{ height: "0px" }}></div>
      </Modal>
    </>
  );
}

export default ThankYouModal;
