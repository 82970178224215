import React from "react";
import Facebook from "../../images/facebook.svg";
import Insta from "../../images/instagram.svg";
import Youtube from "../../images/youtube.svg";
import LinkedIn from "../../images/linkedin11.svg";
import AppStore from "../../images/appstore.svg";
import PlayStore from "../../images/playstore.svg";
import BrandLogo from "../../images/kikoimg.svg";

const Footer = () => {
  return (
    <>
      <footer className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="kiko-logo">
                <img src={BrandLogo} alt="Logo" className="logo-icon" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-widget-area">
                <div className="widget widget_text">
                  <div className="textwidget">
                    <p>
                      Mulund (W), Mumbai 400080.
                      <br />
                      Maharashtra, India.
                    </p>
                    <p>
                      <a href="mailto:support@kiko.media">support@kiko.media</a>
                    </p>
                  </div>
                </div>
                <div className="widget widget_text">
                  <div className="textwidget">
                    <ul>
                      <li>
                        <a
                          href="https://facebook.com/profile.php/?id=100090104332961"
                          target="_blank"
                          rel="noreferrer"
                          className="socialIcon"
                        >
                          <img src={Facebook} alt="Facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/kikoliveapp/"
                          target="_blank"
                          rel="noreferrer"
                          className="socialIcon"
                        >
                          <img src={Insta} alt="Instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://in.linkedin.com/company/kikolive"
                          target="_blank"
                          rel="noreferrer"
                          className="socialIcon"
                        >
                          <img src={LinkedIn} alt="LinkedIn" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@kikolive5631"
                          target="_blank"
                          rel="noreferrer"
                          className="socialIcon"
                        >
                          <img src={Youtube} alt="LinkedIn" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-widget-area">
                <div className="menu-footer-container">
                  <ul className="menu">
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/faq/"
                        className="menu-link"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/disclaimer/"
                        className="menu-link"
                      >
                        Disclaimer
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/privacy-policy/"
                        className="menu-link"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/delivery-policy/"
                        className="menu-link"
                      >
                        Delivery Policy
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/return-refund-policy/"
                        className="menu-link"
                      >
                        Return & Refund Policy
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://kiko.live/terms-conditions/"
                        className="menu-link"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="downloadAppButtons">
                  <div className="AppButtons">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/in/app/kiko-live/id1567183166"
                      className="downloadLink"
                    >
                      <img src={AppStore} alt="App Store" />
                    </a>
                  </div>
                  <div className="AppButtons">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=live.kiko.user&pcampaignid=web_share"
                      className="downloadLink"
                    >
                      <img src={PlayStore} alt="Play Store" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright-block">
        <div className="container">
          <p>Kiko © All Rights Reserved - 2024-25</p>
        </div>
      </section>
    </>
  );
};
export default Footer;
