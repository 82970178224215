import React from "react";

const Icon = (props) => (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#clip0_10569_12737)">
      <path
        fill="#323232"
        d="M2.797 14.197v3h3l8.848-8.848-3-3zm14.728-8.728-3-3L12.5 4.5l3 3z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_10569_12737">
        <path fill="#fff" d="M.398.398h19.2v19.2H.398z"></path>
      </clipPath>
    </defs>
  </svg>
);


export default Icon;
