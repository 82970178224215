import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="27"
    fill="none"
    viewBox="0 0 24 27"
    {...props}
  >
    <path
      fill="CurrentColor"
      d="M20.001 3.47a11.314 11.314 0 10-16 16l7.027 7.04a1.334 1.334 0 001.893 0l7.08-7.107a11.267 11.267 0 000-15.933zm-1.907 14.027l-6.093 6.12-6.093-6.12a8.613 8.613 0 1112.187 0zM8.002 7.377a5.76 5.76 0 000 8.133 5.747 5.747 0 009.813-4 5.653 5.653 0 00-1.68-4.067 5.732 5.732 0 00-8.133-.066zm6.254 6.24a3.106 3.106 0 11.893-2.174 3.107 3.107 0 01-.96 2.174h.066z"
    ></path>
  </svg>
);

export default Icon;