import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "reactstrap";
import OtpInput from "react-otp-input";
import "./styles.scss";
import API from "../../api/indexv2";
import { useDispatch } from "react-redux";
import { LOGIN, REQUEST_OTP, } from "../../api/apiList";
import { handleError } from "../../components/common/utils";
import crossIcon from "../../images/cross-icon.svg";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/common/loader";
import { loginUser } from "../../slices/userSlice";
import { fetchSellers } from "../../slices/sellerSlice";


const Login = (props) => {
  const dispatch = useDispatch();
  const [mobile, setMobileNumber] = useState("");
  const [otpNew, setOtpNew] = useState("");
  const [otpOpen, setOtpOpen] = useState(false);
  const [requestOtpLoading, setRequestOtpLoading] = useState(false);
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));


  let sellerDetails = {};
  try {
    sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));
  } catch (error) {
    sellerDetails = null;
  }
  const getSellers = async (data) => {
    try {
      dispatch(
        fetchSellers({
          areaCode: defaultAddress?.zipcode,
          userId: data?._id
        })
      );
    } catch (error) {
      console.log("ERROR FETCH SELLER ", error);
    }
  };
  const verifyOtp = () => {
    const obj = {
      loginType: "browser",
      screenType: "user",
      phone: `+91${mobile}`,
      countryCode: "+91",
      mobile: mobile,
      code: otpNew,
    };
    setOtpVerifyLoading(true);
    API.post(LOGIN, obj)
      .then(({ data }) => {
        setOtpVerifyLoading(false);
        if (data.status) {
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("token", JSON.stringify(data.token));
          props.setModalIsOpen(false);
          if (props.isOpenFromCart) {
            props.onSuccess()
          }
          getSellers(data.user)
          dispatch(loginUser(data))
          setErrorMessage("");
        } else {
          setErrorMessage(data?.message);
          setOtpVerifyLoading(false);
          notify("error", "Please provide valid details");
        }
      })
      .catch((error) => {
        setOtpVerifyLoading(false);
        handleError(error);
      });
  };

  const requestOtp = () => {
    if (sellerDetails?.mobile === mobile) {
      setErrorMessage(
        "Buyer and seller number should not be same please use another number"
      );
    } else if (mobile.length === 10) {
      setRequestOtpLoading(true);
      API.post(REQUEST_OTP, { phone: `+91${mobile}` })
        .then(({ data }) => {
          if (data.success) {
            setOtpOpen(true);
          }
          setRequestOtpLoading(false);
        })
        .catch((error) => {
          setRequestOtpLoading(false);
          handleError(error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={props.modalIsOpen}
        aria-labelledby="contained-modal-title-vcenter"
        contentLabel="Login Modal"
        centered
        toggle={() => {
          props.setModalIsOpen(false);
        }}
      >
        <header className="ModalHeader">
          <h4>LOGIN</h4>
          <img
            alt="Close"
            src={crossIcon}
            className="login-cross"
            onClick={() => {
              props.setModalIsOpen(false);
            }}
          />
        </header>
        <div className="ModalBody text-center">
          <p className="ParagraphMd">Enter 10 Digit Mobile Number</p>
          <div className="OtpFlex">
            <h5>+91</h5>
            <input
              disabled={otpOpen ? otpOpen : false}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength="10"
              placeholder="Enter 10-digit number"
              className="InputStyleOtp"
              value={mobile === 0 ? "" : mobile}
              onChange={(event) => {
                setMobileNumber(event.target.value);
                setErrorMessage("");
              }}
              onKeyPress={(event) => {
                if (event.target.value.length >= 10) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          {!otpOpen ? (
            <>
              <div className="button-loader mt-4">
                <button
                  className="btn btn-primary"
                  style={{ width: "268px" }}
                  type="button"
                  disabled={
                    requestOtpLoading || mobile.length < 10 || mobile === 0
                  }
                  onClick={() => {
                    requestOtp();
                  }}
                >
                  {!requestOtpLoading && "Get OTP"}
                </button>
                <div className="dotLoader loginloder">
                  {requestOtpLoading && <Loader />}
                </div>
              </div>

              {errorMessage && <p className="error">{errorMessage}</p>}
            </>
          ) : (
            <>
              <div>
                <p className="purple">
                  An OTP has been sent to your mobile Number please verify it
                  below
                </p>
                <div className="InputFlex">
                  <div className="inputflexBlock">
                    <OtpInput
                      value={otpNew}
                      inputType="number"
                      onChange={(event) => {
                        setOtpNew(event);
                      }}
                      numInputs={6}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>
                <p className="RecievedOtp">
                  Didn’t Receive OTP ?{" "}
                  <button
                    type="button"
                    onClick={() => {
                      requestOtp();
                    }}
                  >
                    RESEND OTP
                  </button>
                </p>
              </div>
              <div className="button-loader">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "268px" }}
                  disabled={
                    otpVerifyLoading || otpNew.length < 6 || otpNew === ""
                  }
                  onClick={() => {
                    verifyOtp();
                  }}
                >
                  {!otpVerifyLoading && "VERIFY"}
                </button>
                <div className="dotLoader loginloder">
                  {otpVerifyLoading && <Loader />}
                </div>
              </div>
              {errorMessage && <p className="error">{errorMessage}</p>}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Login;
