import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import BrandLogo from "../../images/kikoimg.svg";
import { GET_CATEGORIES, GET_BUYER_APP_SETTINGS } from "../../api/apiList";
import API2 from "../../api/indexv2";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fetchSellers,
  favouriteSellers,
  toggleFavorite,
} from "../../slices/sellerSlice";
import { get } from "lodash";
import Loader from "../../components/common/loader";
import Footer from "../../components/common/Footer";
import { LikeIcon, Pin } from "../../components/svgicons";
import "./styles.scss";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { getDistance } from "../../components/common/utils";

const HomePage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [categoriesData, setCategoriesData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const categoryParam = searchParams.get("category") || "Grocery";
  // const [imageLoaded, setImageLoaded] = useState([]);
  const [offerImages, setOfferImages] = useState([]);
  const [dealImages, setDealImages] = useState([]);
  const [brandImages, setBrandImages] = useState([]);
  const [categoryImages, setCategoryImages] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);

  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const initialVisibleCount = 12;
  const [showAllForMobile, setShowAllForMobile] = useState(false)
  const initialCountForMobile = 5

  const sellers = useSelector((s) => s.sellers.sellers);
  const sellersStatus = useSelector((s) => s.sellers.sellersStatus);
  const token = useSelector((s) => s.user.token);
  const buyItAgainSellers = useSelector((s) => s.sellers.buyItAgainSellers);
  const favoriteSellers = useSelector((s) => s.sellers.favoriteSellers);

  const [isRecommended, setIsRecommended] = useState(true);
  const [isAllStore, setIsAllStore] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  console.log("sellers=====>>> ", sellers.length);

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) {
      getSellers();
    }
  }, [selectedCategory]);

  const getCategoriesData = async () => {
    try {
      const response = await API2.post(GET_CATEGORIES);

      // Check if the response has the expected structure
      console.log("API Response:", response);

      if (response?.data) {
        let categories = response.data.data;

        // Ensure "Grocery" is the first category
        const groceryIndex = categories.findIndex(
          (category) => category?.title === "Grocery"
        );
        if (groceryIndex > -1) {
          const [groceryCategory] = categories.splice(groceryIndex, 1);
          categories.unshift(groceryCategory);
        }

        setCategoriesData(categories);

        const matchedCategory = categories.find(
          (category) => category?.title === categoryParam
        );
        setSelectedCategory(matchedCategory || categories[0]);
      } else {
        console.error("No data returned from API");
      }
    } catch (error) {
      console.log("ERROR CATEGORIES: ", error);
    }
  };

  const getSellers = async () => {
    try {
      dispatch(
        fetchSellers({
          areaCode: defaultAddress?.zipcode,
          category: selectedCategory?.title,
          userId: user?._id,
          longitude: defaultAddress?.longitude,
          latitude: defaultAddress?.latitude,
        })
      );
    } catch (error) {
      console.log("ERROR FETCH SELLER ", error);
    }
  };

  const handleFavouriteSeller = async (seller) => {
    try {
      if (!user) {
        console.error("User is not logged in");
        return;
      }

      dispatch(
        favouriteSellers({
          userId: user._id,
          providerId: seller.providerId,
          sellerId: seller._id,
        })
      ).then((data) => {
        if (data?.payload?.success) {
          notify("success", data?.payload?.message);
        } else {
          notify("error", "Somthing Went Wrong !");
        }
      });
    } catch (error) {
      console.log("ERROR FETCH FAV SELLER", error);
    }
  };

  useEffect(() => {
    const fetchOfferImages = async () => {
      try {
        const res = await API2.get(GET_BUYER_APP_SETTINGS);
        if (res?.data) {
          setOfferImages(res.data.offerImages);
          setDealImages(res.data.dealoftheday);
          setBrandImages(res.data.brands);
          setCategoryImages(res.data.categories);
          setCarouselImages(res.data.carouselImages);
        }
      } catch (error) {
        console.error("ERROR BuyerAPPSettings: ", error);
      } finally {
      }
    };

    fetchOfferImages();
  }, []);

  const toggleFavoriteSeller = (sellerId) => {
    dispatch(toggleFavorite(sellerId));
  };

  const handleSellerClick = (seller) => {
    localStorage.setItem("sellerDetails", JSON.stringify(seller));
    const encodedValue = encodeURIComponent(seller?.providerId);
    navigate(`seller?pid=${encodedValue}`, {
      state: {
        category: categoryParam || "All",
      },
    });
  };

  const handleCategoryOnClick = (categoryImages) => {
    const encodedValue = encodeURIComponent(categoryImages?.categoryName);
    navigate(`/category?name=${encodedValue}`);
  };

  const categoriesSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 865,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const BannerSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  let ExcitingSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    // slidesToShow: offerImages?.length > 2 ? 4 : offerImages?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 812,
        settings: {
          slidesToShow: offerImages?.length > 3 ? 3 : offerImages?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          initialSlide: 0.5,
          slidesToScroll: 1,
          slidesToShow: 1.5
        },
      },
    ],
  };
  const DealsOfTheSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    // slidesToShow: dealImages?.length > 3 ? 4 : dealImages?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          initialSlide: 0.5,
          slidesToScroll: 2,
          slidesToShow: 2.2
        },
      },
    ],
  };
  const BrandsInSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          // rows: brandImages?.length > 7 ? 2 : 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          // rows: brandImages?.length > 6 ? 2 : 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          // rows: brandImages?.length > 5 ? 2 : 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          // rows: brandImages?.length > 4 ? 2 : 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // rows: brandImages?.length > 3 ? 2 : 1,
        },
      },
    ],
  };
  const handleViewMore = () => {
    setShowAll(true);
  };

  const sellersToDisplay = showAll
    ? sellers
    : sellers.slice(0, initialVisibleCount);

  const handleMobileViewMore = () => {
    setShowAllForMobile(true)
  }

  const sellersToDisplayMobile = showAllForMobile
    ? sellers
    : sellers.slice(0, initialCountForMobile);

  const [isManualClick, setIsManualClick] = useState(false); // Track manual clicks
  const sliderRef = useRef(null);

  const handleSlideChange = () => {
    setIsManualClick(false); // Reset manual click state on slide change
  };

  const handleImageClick = async (data) => {
    // if (isManualClick) {
    //   setIsManualClick(false); // Reset manual click state after handling
    if (data?.providerId) {
      try {
        const res = await API2.post("/get-seller-details", {
          providerId: data.providerId,
        });
        if (res?.data?.sellerDetails) {
          localStorage.setItem(
            "sellerDetails",
            JSON.stringify(res.data.sellerDetails)
          );
          const encodedValue = encodeURIComponent(data?.providerId);
          navigate(`seller?pid=${encodedValue}`);
        }
      } catch (error) { }
    } else {
    }
    // } else {
    // }
  };

  function getClassForTag(tag) {
    switch (tag) {
      case "Fast Delivery":
        return "fast-delivery";
      case "Featured Seller":
        return "featured-seller";
      case "Local Legend":
        return "local-legend";
      case "Platinum Partner":
        return "platinum-partner";
      case "Top-Rated":
        return "top-rated";
      default:
        return "fast-delivery";
    }
  }


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {categoriesData === null ? (
        <div className="dotLoader">
          <Loader />
        </div>
      ) : (
        <>
          <Header />
          <>
            {sellersStatus === "loaded" ? (
              <div className="home-page-container">
                <section className="home-page-banner">
                  <div className="container">
                    <Slider
                      {...BannerSlider}
                      ref={sliderRef}
                      afterChange={handleSlideChange} // Reset manual clicks on slide change
                    >
                      {carouselImages && carouselImages.length > 0 ? (
                        carouselImages.map((image, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setIsManualClick(true);
                              handleImageClick(image);
                            }}
                            className="banner-img-block"
                            style={{ backgroundImage: `url(${image.bgImage})` }}
                          >
                              {/* <img src={image.bgImage} className="banner-bg-img" /> */}
                              <picture className="banner-img-box">
                              {/* {image.leftImage && ( */}
                                <source media="(min-width:650px)" srcset={image.leftImage} className="banner_left"/>
                              {/* // )} */}
                               {/* {image.rightImage && ( */}
                                <source media="(min-width:465px)" srcset={image.rightImage} className="banner_right"/>
                              {/* // )} */}
                                <img src={image.bgImage} alt="Flowers" className="banner_image" />
                              </picture>
                             <div className="banner-img-text-box">
                             <div className="banner-text">{image.title} </div>
                             </div>
                            
                                {/* <div className="banner-img-box-left">
                                  <img
                                    className="banner-img-box-left-thumb"
                                    src={image.leftImage}
                                    alt=""
                                  />
                                </div> */}
                                {/* <div className="banner-img-box-right">
                                  <img
                                    className="banner-img-box-right-thumb"
                                    src={image.rightImage}
                                    alt=""
                                  />
                                </div> */}
                          
                          </div>
                        ))
                      ) : (
                        <p>Loading images...</p>
                      )}
                    </Slider>
                  </div>
                </section>

                {
                  offerImages.length > 0 && (
                    <section className="Exciting-offer-section">
                      <div className="container">
                        <div className="heading-line-flex">
                          <div className="sub-heading">
                            <div className="heading-text">Exciting Offers</div>
                          </div>
                        </div>
                        <Slider
                          {...ExcitingSettings}
                        // slidesToShow={offerImages?.length}
                        // className="exciting-offer"
                        >
                          {offerImages.map((data) => {
                            console.log(
                              "offerImages.length ====>>>> ",
                              offerImages.length
                            );

                            return (
                              <div
                                key={data.key}
                                onClick={() => handleImageClick(data)}
                              >
                                <div className="exciting-offer-item">
                                  <img
                                    src={data.value}
                                    alt={`ExcitingImage ${data.key}`}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                        {/* <div className="exciting-offer-list">
                      <div className="exciting-offer-item">
                        <img src={ExcitingOfferImg} />
                      </div>
                      <div className="exciting-offer-item">
                        <img src={ExcitingOfferImg} />
                      </div>
                      <div className="exciting-offer-item">
                        <img src={ExcitingOfferImg} />
                      </div>
                    </div> */}
                      </div>
                    </section>
                  )
                }
                {
                  categoryImages.length > 0 && (
                    <section className="products-container">
                      <div className="container">
                        <div className="heading-line-flex">
                          <div className="sub-heading">
                            <div className="heading-text">Shop By Categories</div>
                          </div>
                        </div>
                        <Slider {...categoriesSettings}>
                          {categoryImages.map((data) => {
                            return (
                              <div
                                key={data.key}
                                onClick={() => handleCategoryOnClick(data)}
                              >
                                <div className="product-item-box">
                                  <div className="product-item">
                                    <div className="products-img">
                                      <img
                                        src={data.value}
                                        alt={`CategoryImage ${data.key}`}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                    <p class="category-title">
                                      {data.categoryName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </section>
                  )
                }
                {!isMobile && token && buyItAgainSellers?.length > 0 && (
                  <section className="store-section">
                    <div className="container">
                      <div className="heading-line-flex">
                        <div className="sub-heading">
                          <div className="heading-text">Buy It Again</div>
                        </div>
                      </div>
                      {/* <div className="stores-list"> */}
                      <div className="buy-it-again">
                        <Slider
                          dots={false} // Show navigation dots
                          infinite={buyItAgainSellers?.length > 6} // Enable infinite scroll only if items exceed visible cards
                          speed={500} // Transition speed
                          slidesToShow={6} // Display 6 cards at a time
                          slidesToScroll={1} // Scroll 1 card at a time
                          responsive={[
                            {
                              breakpoint: 1160,
                              settings: { slidesToShow: 4, slidesToScroll: 1 },
                            },
                            {
                              breakpoint: 768,
                              settings: { slidesToShow: 2, slidesToScroll: 1 },
                            },
                            {
                              breakpoint: 300,
                              settings: { slidesToShow: 1, slidesToScroll: 1 },
                            },
                          ]}
                        >
                          {buyItAgainSellers?.length > 0 ? (
                            buyItAgainSellers?.map((seller, index) => {
                              const storeName = get(
                                seller,
                                "name",
                                "Not Available"
                              );
                              const storePhoto = get(
                                seller,
                                "photo",
                                "Not Available"
                              );
                              const gps = seller?.locations?.[0]?.gps;
                              const gpsCoordinates = gps
                                ? gps
                                  .split(",")
                                  .map((coord) => parseFloat(coord))
                                : [null, null];

                              let distance = null;
                              if (
                                gpsCoordinates[0] !== null &&
                                gpsCoordinates[1] !== null
                              ) {
                                const sellerLat = gpsCoordinates[0];
                                const sellerLon = gpsCoordinates[1];
                                const defaultLat = parseFloat(
                                  defaultAddress?.latitude
                                );
                                const defaultLon = parseFloat(
                                  defaultAddress?.longitude
                                );

                                distance = getDistance(
                                  defaultLat,
                                  defaultLon,
                                  sellerLat,
                                  sellerLon
                                );
                              }
                              return (
                                <div className="buy-item-card-list" key={index}>
                                  <div className="buy-it-gain-box">
                                    <div className="buy-it-again-card">
                                      <div
                                        className="store-item clickable"
                                        onClick={() =>
                                          handleSellerClick(seller)
                                        }
                                      >
                                        <div className="fast-delivery-tag">
                                          <div className={seller?.storeTags ? `store-tag ${getClassForTag(seller?.storeTags)}` : ""}>
                                            {seller?.storeTags ?? ""}
                                          </div>
                                          <button
                                            type="button"
                                            className="like-icon"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              toggleFavoriteSeller(seller._id);
                                              handleFavouriteSeller(seller);
                                            }}
                                          >
                                            <LikeIcon
                                              className={
                                                seller.isFavorite
                                                  ? "active"
                                                  : ""
                                              }
                                            />
                                          </button>
                                        </div>
                                        <div className="store-img">
                                          <img
                                            alt="seller-pic"
                                            className="storeimg"
                                            src={storePhoto || BrandLogo}
                                            onError={(event) => {
                                              event.target.onerror = null; // Prevent infinite loop
                                              event.target.src = BrandLogo;
                                              event.target.classList.add(
                                                "default-img"
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="store_content">
                                          <div>
                                            <div className="store-name">
                                              {storeName}
                                            </div>
                                            <div className="near-store-discount">
                                              {seller?.storeTagline ?? ""}
                                            </div>
                                            <div className="store-km">
                                              <Pin />{" "}
                                              {distance >= 0
                                                ? `${distance.toFixed(2)} Km`
                                                : "Distance not available"}
                                            </div>
                                          </div>
                                          <div className="visite-store">
                                            <a
                                              href="# "
                                              className="visite-store-link"
                                            >
                                              Visit Store
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                            // <div className="nothing-match">
                            //   <div className="no-cart-img">
                            //     <img alt="No Data Image" src={NoData} />
                            //   </div>
                            //   <h6 className="text-center">No Stores Found</h6>
                            //   <h6 className="text-center" style={{ whiteSpace: "nowrap" }}>
                            //     At Your Current Location !!
                            //   </h6>
                            // </div>
                          )}
                        </Slider>
                      </div>

                      {/* </div> */}
                    </div>
                  </section>
                )}
                {!isMobile &&
                  token &&
                  (sellers?.length > 0 || favoriteSellers?.length > 0) && (
                    <section className="top-grocery-section">
                      <div className="container">
                        <div className="heading-line-flex">
                          <div className="sub-heading">
                            <div className="heading-text">
                              Top Grocery Shops
                            </div>
                          </div>
                        </div>
                        <div className="shops-tabs">
                          <ul class="shopslist-tabs">
                            <li
                              className={
                                isRecommended
                                  ? "shopslist-item active"
                                  : "shopslist-item"
                              }
                              onClick={() => {
                                setIsRecommended(true);
                              }}
                            >
                              <button className="shopslist-link ">
                                Recommended
                              </button>
                            </li>
                            <li
                              className={
                                isRecommended
                                  ? "shopslist-item"
                                  : "shopslist-item active"
                              }
                              onClick={() => {
                                setIsRecommended(false);
                              }}
                            >
                              <button className="shopslist-link">
                                <LikeIcon /> Favourites
                              </button>
                            </li>
                          </ul>

                          <div className="shopslist-content">
                            <div className="shopslist-all-store active">
                              {/* <div className="stores-list"> */}
                              <div className="top-grocery-shops">
                                <Slider
                                  dots={false} // Show navigation dots
                                  arrows={true}
                                  infinite={
                                    false
                                  } // Enable infinite scroll only if items exceed visible cards
                                  speed={500} // Transition speed
                                  slidesToShow={
                                    isRecommended
                                      ? sellers.length > 5
                                        ? 6
                                        : sellers.length
                                      : favoriteSellers.length > 5
                                        ? 6
                                        : 6
                                  } // Display 6 cards at a time
                                  slidesToScroll={1} // Scroll 1 card at a time
                                  responsive={[
                                    {
                                      breakpoint: 1160,
                                      settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                      },
                                    },
                                    {
                                      breakpoint: 768,
                                      settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                      },
                                    },
                                    {
                                      breakpoint: 300,
                                      settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                      },
                                    },
                                  ]}
                                >
                                  {/*Recommended Seller and Favorite Seller */}
                                  {isRecommended
                                    ? sellers.length > 0 &&
                                    sellers.map((seller, index) => {
                                      const storeName = get(
                                        seller,
                                        "name",
                                        "Not Available"
                                      );
                                      const gps = seller?.locations?.[0]?.gps;
                                      const gpsCoordinates = gps
                                        ? gps
                                          .split(",")
                                          .map((coord) => parseFloat(coord))
                                        : [null, null];
                                      let distance = null;
                                      if (
                                        gpsCoordinates[0] !== null &&
                                        gpsCoordinates[1] !== null
                                      ) {
                                        const sellerLat = gpsCoordinates[0];
                                        const sellerLon = gpsCoordinates[1];
                                        const defaultLat = parseFloat(
                                          defaultAddress?.latitude
                                        );
                                        const defaultLon = parseFloat(
                                          defaultAddress?.longitude
                                        );
                                        distance = getDistance(
                                          defaultLat,
                                          defaultLon,
                                          sellerLat,
                                          sellerLon
                                        );
                                      }
                                      if (
                                        distance <= 3 ||
                                        distance === null
                                      ) {
                                        return (
                                          <div key={index}>
                                            <div className="top-grocery-box">
                                              <div className="top-grocery-card">
                                                <div
                                                  className="store-item clickable"
                                                  onClick={() =>
                                                    handleSellerClick(seller)
                                                  }
                                                >
                                                  <div className="fast-delivery-tag">
                                                    <div className={seller?.storeTags ? `store-tag ${getClassForTag(seller?.storeTags)}` : ""}>
                                                      {seller?.storeTags ?? ""}
                                                    </div>
                                                    <button
                                                      type="button"
                                                      className="like-icon"
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        toggleFavoriteSeller(
                                                          seller._id
                                                        );
                                                        handleFavouriteSeller(
                                                          seller
                                                        );
                                                      }}
                                                    >
                                                      <LikeIcon
                                                        className={
                                                          seller.isFavorite
                                                            ? "active"
                                                            : ""
                                                        }
                                                      />
                                                    </button>
                                                  </div>
                                                  <div className="store-img">
                                                    <img
                                                      alt="seller-pic"
                                                      className="storeimg"
                                                      src={
                                                        seller?.photo ||
                                                        BrandLogo
                                                      }
                                                      onError={(event) => {
                                                        event.target.onerror =
                                                          null; // Prevents infinite loop
                                                        event.target.src =
                                                          BrandLogo;
                                                        event.target.classList.add(
                                                          "default-img"
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="store_content">
                                                    <div>
                                                      <div className="store-name">
                                                        {storeName}
                                                      </div>
                                                      <div className="near-store-discount">
                                                        {seller?.storeTagline ?? ""}
                                                      </div>
                                                      <div className="store-km">
                                                        <Pin />{" "}
                                                        {distance >= 0
                                                          ? `${distance.toFixed(
                                                            2
                                                          )} Km`
                                                          : "Distance not available"}
                                                      </div>
                                                    </div>
                                                    <div className="visite-store">
                                                      <a
                                                        href="# "
                                                        className="visite-store-link"
                                                      >
                                                        Visit Store
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );


                                      }
                                    })
                                    : favoriteSellers.length > 0 &&
                                    favoriteSellers.map((seller, index) => {
                                      const storeName = get(
                                        seller,
                                        "name",
                                        "Not Available"
                                      );
                                      const gps = seller?.locations?.[0]?.gps;
                                      const gpsCoordinates = gps
                                        ? gps
                                          .split(",")
                                          .map((coord) => parseFloat(coord))
                                        : [null, null];

                                      let distance = null;
                                      if (
                                        gpsCoordinates[0] !== null &&
                                        gpsCoordinates[1] !== null
                                      ) {
                                        const sellerLat = gpsCoordinates[0];
                                        const sellerLon = gpsCoordinates[1];
                                        const defaultLat = parseFloat(
                                          defaultAddress?.latitude
                                        );
                                        const defaultLon = parseFloat(
                                          defaultAddress?.longitude
                                        );

                                        distance = getDistance(
                                          defaultLat,
                                          defaultLon,
                                          sellerLat,
                                          sellerLon
                                        );
                                      }
                                      return (
                                        <div key={index}>
                                          <div className="top-grocery-box">
                                            <div className="top-grocery-card">
                                              <div
                                                className="store-item clickable"
                                                onClick={() =>
                                                  handleSellerClick(seller)
                                                }
                                              >
                                                <div className="fast-delivery-tag">
                                                  <div className={seller?.storeTags ? `store-tag ${getClassForTag(seller?.storeTags)}` : ""}>
                                                    {seller?.storeTags ?? ""}
                                                  </div>
                                                  <button
                                                    type="button"
                                                    className="like-icon"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      toggleFavoriteSeller(
                                                        seller._id
                                                      );
                                                      handleFavouriteSeller(
                                                        seller
                                                      );
                                                    }}
                                                  >
                                                    <LikeIcon
                                                      className={"active"}
                                                    />
                                                  </button>
                                                </div>
                                                <div className="store-img">
                                                  <img
                                                    alt="seller-pic"
                                                    className="storeimg"
                                                    src={
                                                      seller?.photo ||
                                                      BrandLogo
                                                    }
                                                    onError={(event) => {
                                                      event.target.onerror =
                                                        null; // Prevent infinite loop
                                                      event.target.src =
                                                        BrandLogo;
                                                      event.target.classList.add(
                                                        "default-img"
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="store_content">
                                                  <div>
                                                    <div className="store-name">
                                                      {storeName}
                                                    </div>
                                                    <div className="near-store-discount">
                                                      {seller?.storeTagline ?? ""}
                                                    </div>
                                                    <div className="store-km">
                                                      <Pin />{" "}
                                                      {distance >= 0
                                                        ? `${distance.toFixed(
                                                          2
                                                        )} Km`
                                                        : "Distance not available"}
                                                    </div>
                                                  </div>
                                                  <div className="visite-store">
                                                    <a
                                                      href="# "
                                                      className="visite-store-link"
                                                    >
                                                      Visit Store
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                {dealImages.length > 0 && (
                  <section className="Deal-of-the-section">
                    <div className="container">
                      <div className="heading-line-flex">
                        <div className="sub-heading">
                          <div className="heading-text">Deals Of The Day</div>
                        </div>
                      </div>
                      <Slider
                        {...DealsOfTheSettings}
                      // slidesToShow={dealImages?.length}
                      >
                        {dealImages.map((data) => {
                          return (
                            <div
                              key={data.key}
                              onClick={() => {
                                handleImageClick(data);
                              }}
                            >
                              <div className="exciting-offer-item">
                                <img
                                  src={data.value}
                                  alt={`DealsImage ${data.key}`}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </section>
                )}
                {brandImages.length > 0 && (
                  <section className="brand-spotlight-section">
                    <div className="container">
                      <div className="heading-line-flex">
                        <div className="sub-heading">
                          <div className="heading-text">
                            Brands In Spotlight
                          </div>
                        </div>
                      </div>
                      <div className="brands-in-spotlight">
                        {/* <div className="brands-spotlight-flex"> */}
                        <Slider
                          {...BrandsInSettings}
                          slidesToShow={brandImages?.length}
                        >
                          {brandImages.map((data) => {
                            const seller = sellers.find(
                              (s) => s.providerId === data.providerId
                            );
                            const name = seller ? seller.name : "";
                            return (
                              <div
                                key={data.key}
                                onClick={() => {
                                  handleImageClick(data);
                                }}
                              >
                                <div className="brand-tag">
                                  <div className="brand-tag-img">
                                    <img
                                      src={data.value}
                                      alt={`BrandImage ${data.key}`}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div className="spotlight-name">{name}</div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                        {/* </div> */}
                      </div>
                    </div>
                  </section>
                )}
                {!isMobile &&
                  (sellersToDisplay?.length > 0 || sellers?.length > 0) && (
                    <section className="store-section">
                      <div className="container">
                        <div className="shops-tabs">
                          <ul class="shopslist-tabs">
                            <li
                              className={
                                isAllStore
                                  ? "shopslist-item active"
                                  : "shopslist-item"
                              }
                              onClick={() => {
                                setIsAllStore(true);
                              }}
                            >
                              <button class="shopslist-link ">
                                All Stores
                              </button>
                            </li>
                            <li
                              className={
                                isAllStore
                                  ? "shopslist-item"
                                  : "shopslist-item active"
                              }
                              onClick={() => {
                                setIsAllStore(false);
                              }}
                            >
                              <button class="shopslist-link">
                                Near & Fast Delivery
                              </button>
                            </li>
                          </ul>

                          <div class="shopslist-content">
                            {isAllStore ? (
                              <div className="buy-it-again">
                                <div className="All-stores-flex">
                                  {sellersToDisplay.length > 0 ? (
                                    sellersToDisplay.map((seller, index) => {
                                      const storeName =
                                        seller?.name || "Not Available";
                                      const gps = seller?.locations?.[0]?.gps;
                                      const gpsCoordinates = gps
                                        ? gps
                                          .split(",")
                                          .map((coord) => parseFloat(coord))
                                        : [null, null];

                                      let distance = null;
                                      if (
                                        gpsCoordinates[0] !== null &&
                                        gpsCoordinates[1] !== null
                                      ) {
                                        const sellerLat = gpsCoordinates[0];
                                        const sellerLon = gpsCoordinates[1];
                                        const defaultLat = parseFloat(
                                          defaultAddress?.latitude
                                        );
                                        const defaultLon = parseFloat(
                                          defaultAddress?.longitude
                                        );

                                        distance = getDistance(
                                          defaultLat,
                                          defaultLon,
                                          sellerLat,
                                          sellerLon
                                        );
                                      }
                                      return (
                                        <div
                                          className="buy-it-again-card"
                                          key={index}
                                        >
                                          <div
                                            className="store-item clickable"
                                            onClick={() =>
                                              handleSellerClick(seller)
                                            }
                                          >
                                            <div className="fast-delivery-tag">
                                              <div className={seller?.storeTags ? `store-tag ${getClassForTag(seller?.storeTags)}` : ""}>
                                                {seller?.storeTags ?? ""}
                                              </div>
                                              {token && (
                                                <button
                                                  type="button"
                                                  className="like-icon"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    toggleFavoriteSeller(
                                                      seller._id
                                                    );
                                                    handleFavouriteSeller(
                                                      seller
                                                    );
                                                  }}
                                                >
                                                  <LikeIcon
                                                    className={
                                                      seller.isFavorite
                                                        ? "active"
                                                        : ""
                                                    }
                                                  />
                                                </button>
                                              )}
                                            </div>
                                            <div className="store-img">
                                              <img
                                                alt="seller-pic"
                                                className="storeimg"
                                                src={seller?.photo || BrandLogo}
                                                onError={(event) => {
                                                  event.target.onerror = null; // Prevents potential infinite loop
                                                  event.target.src = BrandLogo;
                                                  event.target.classList.add(
                                                    "default-img"
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="store_content">
                                              <div>
                                                <div className="store-name">
                                                  {storeName}
                                                </div>
                                                <div className="near-store-discount">
                                                  {seller?.storeTagline ?? ""}
                                                </div>
                                                <div className="store-km">
                                                  <Pin />{" "}
                                                  {distance >= 0
                                                    ? `${distance.toFixed(
                                                      2
                                                    )} Km`
                                                    : "Distance not available"}
                                                </div>
                                              </div>
                                              <div className="visite-store">
                                                <a
                                                  href="# "
                                                  className="visite-store-link"
                                                >
                                                  Visit Store
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {!showAll &&
                                  sellers.length > initialVisibleCount && (
                                    <div className="view-more-container">
                                      <button
                                        onClick={handleViewMore}
                                        className="view-more-btn"
                                      >
                                        View More
                                      </button>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              <div className="buy-it-again">
                                <div className="All-stores-flex">
                                  {sellers.length > 0 ? (
                                    sellers.map((seller, index) => {
                                      const storeName = get(
                                        seller,
                                        "name",
                                        "Not Available"
                                      );
                                      const gps = seller?.locations?.[0]?.gps;
                                      const gpsCoordinates = gps
                                        ? gps
                                          .split(",")
                                          .map((coord) => parseFloat(coord))
                                        : [null, null];

                                      let distance = null;
                                      if (
                                        gpsCoordinates[0] !== null &&
                                        gpsCoordinates[1] !== null
                                      ) {
                                        const sellerLat = gpsCoordinates[0];
                                        const sellerLon = gpsCoordinates[1];
                                        const defaultLat = parseFloat(
                                          defaultAddress?.latitude
                                        );
                                        const defaultLon = parseFloat(
                                          defaultAddress?.longitude
                                        );

                                        distance = getDistance(
                                          defaultLat,
                                          defaultLon,
                                          sellerLat,
                                          sellerLon
                                        );
                                      }
                                      if (distance <= 3 || distance === null) {
                                        return (
                                          <div className="buy-it-again-card">
                                            <div
                                              className="store-item clickable"
                                              onClick={() =>
                                                handleSellerClick(seller)
                                              }
                                              key={index}
                                            >
                                              <div className="fast-delivery-tag">
                                                <div className={seller?.storeTags ? `store-tag ${getClassForTag(seller?.storeTags)}` : ""}>
                                                  {seller?.storeTags ?? ""}
                                                </div>
                                                {token && (
                                                  <button
                                                    type="button"
                                                    className="like-icon"
                                                    onClick={(event) => {
                                                      event.stopPropagation();

                                                      toggleFavoriteSeller(
                                                        seller._id
                                                      );
                                                      handleFavouriteSeller(
                                                        seller
                                                      );
                                                    }}
                                                  >
                                                    <LikeIcon
                                                      className={
                                                        seller.isFavorite
                                                          ? "active"
                                                          : ""
                                                      }
                                                    />
                                                  </button>
                                                )}
                                              </div>
                                              <div className="store-img">
                                                <img
                                                  alt="seller-pic"
                                                  className="storeimg"
                                                  src={
                                                    seller?.photo || BrandLogo
                                                  }
                                                  onError={(event) => {
                                                    event.target.onerror = null; // Prevents potential infinite loop
                                                    event.target.src =
                                                      BrandLogo;
                                                    event.target.classList.add(
                                                      "default-img"
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="store_content">
                                                <div>
                                                  <div className="store-name">
                                                    {storeName}
                                                  </div>
                                                  <div className="near-store-discount">
                                                    {seller?.storeTagline ?? ""}
                                                  </div>
                                                  <div className="store-km">
                                                    <Pin />{" "}
                                                    {distance >= 0
                                                      ? `${distance.toFixed(
                                                        2
                                                      )} Km`
                                                      : "Distance not available"}
                                                  </div>
                                                </div>
                                                <div className="visite-store">
                                                  <a
                                                    href="# "
                                                    className="visite-store-link"
                                                  >
                                                    Visit Store
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                <section className="mobile-near-stores">
                  <div className="container">
                    <div className="heading-line-flex">
                      <div className="sub-heading">
                        <div className="heading-text">Stores Near You</div>
                      </div>
                    </div>
                    {sellersToDisplayMobile.length > 0 ? (
                      <ul className="near-stores-list-block">
                        {sellersToDisplayMobile.map((seller, index) => {
                          const storeName = get(
                            seller,
                            "name",
                            "Not Available"
                          );
                          const gps = seller?.locations?.[0]?.gps;
                          const gpsCoordinates = gps
                            ? gps.split(",").map((coord) => parseFloat(coord))
                            : [null, null];

                          let distance = null;
                          if (
                            gpsCoordinates[0] !== null &&
                            gpsCoordinates[1] !== null
                          ) {
                            const sellerLat = gpsCoordinates[0];
                            const sellerLon = gpsCoordinates[1];
                            const defaultLat = parseFloat(
                              defaultAddress?.latitude
                            );
                            const defaultLon = parseFloat(
                              defaultAddress?.longitude
                            );

                            distance = getDistance(
                              defaultLat,
                              defaultLon,
                              sellerLat,
                              sellerLon
                            );
                          }

                          if (distance <= 3 || distance === null) {
                            return (
                              <li
                                key={index}
                                className="near-stores-list"
                                onClick={() => handleSellerClick(seller)}
                              >
                                <div className="store-img-block">
                                  <div className="near-store-img">
                                    <img
                                      alt="seller-pic"
                                      className="storeimg"
                                      src={seller?.photo || BrandLogo}
                                      onError={(event) => {
                                        event.target.onerror = null; // Prevents potential infinite loop
                                        event.target.src = BrandLogo;
                                        event.target.classList.add(
                                          "default-img"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className={`near-store-img-tag ${getClassForTag(seller?.storeTags ?? "")}`}>
                                    {seller?.storeTags ?? "Trusted Seller"}
                                  </div>
                                </div>
                                <div className="near-store-content">
                                  <div className="near-store-details">
                                    <div className="near-store-title">
                                      <div className="near-store-name">
                                        {storeName}
                                      </div>
                                      {token && (
                                        <button
                                          type="button"
                                          className="like-icon"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            toggleFavoriteSeller(seller._id);
                                            handleFavouriteSeller(seller);
                                          }}
                                        >
                                          <LikeIcon
                                            className={
                                              seller.isFavorite ? "active" : ""
                                            }
                                          />
                                        </button>
                                      )}
                                    </div>
                                    <div className="near-store-km">
                                      {distance >= 0
                                        ? `${distance.toFixed(2)} Km`
                                        : "Distance not available"}
                                    </div>
                                  </div>
                                  <div className="near-store-discount">
                                    {seller?.storeTagline ?? ""}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    ) : (
                      <p>No stores found near you.</p>
                    )}

                    {!showAllForMobile && 
                      sellers.length > initialCountForMobile && (
                        <div className="view-more-container">
                          <button className="view-more-btn" onClick={handleMobileViewMore}>View More</button>
                        </div>
                      )} 
                  </div>
                </section>
              </div>
            ) : (
              <Loader />
            )}
          </>
          <Footer />
        </>
      )}
    </>
  );
};

export default HomePage;
