import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "../../views/Login/component";
import clearCartIcon from "../../images/clear-cart.png";
import NoCart from "../../images/no-cart.svg";
import { Modal, ModalFooter, Button } from "reactstrap";
import { USER_DATA } from "./constant";
import { errorCodeMessages, notify } from "../../components/common/utils";
import { ondcSelect } from "../../slices/ondcPreorderSlice";
import { updateCartItem } from "../../slices/catalogSlice";
import ManageAddress from "../../views/ManageAddress/component";
import { DeleteIcon, MinusIcon, PlusIcon } from "../../components/svgicons";
import CrossIcon from "../../components/svgicons/Cross-icon";
import WarningIcon from "../../images/warning-icon.svg";
import "./styles.scss";
import Loader from "./loader";
import { v4 as uuidv4 } from "uuid";
import BrandLogo from "../../images/kikoimg.svg";

const Cart = (props) => {
  let { closeCart } = props;
  let cartItems = JSON.parse(localStorage.getItem("cart"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clearCart, setClearCart] = useState(false);
  const [cartError, setCartError] = useState("");
  const [loading, setLoading] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [cartItemsTemp, setCartItemsTemp] = useState(cartItems);
  const ondcCartDataLocal = JSON.parse(localStorage.getItem("ondcCart"));
  const [ondcCartData, setOndcCartData] = useState(ondcCartDataLocal);
  const [errorData, setErrorData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCartEmpty, setisCartEmpty] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [disableCrossButton, setDisableCrossButton] = useState(false);

  useEffect(() => {
    if (errorData && errorData.length > 0 && !cartError) {
      setCartError("Cart has errors ! please try updating your cart.");
    }
  }, [errorData, cartItemsTemp]);

  useEffect(() => {
    let cartItems = JSON.parse(localStorage.getItem("cart"));
    setCartItemsTemp(cartItems || []);
  }, [props.isCartOpen]);

  useEffect(() => {
    const checkIsCartEmpty =
      cartItemsTemp?.length === 0 || cartItemsTemp === null;
    setisCartEmpty(checkIsCartEmpty);
  }, [cartItemsTemp]);

  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };

  const isItemsFromDifferentSellers =
    new Set(cartItemsTemp?.map((item) => item?.catalogData?.providerId)).size >
    1;
  let totalAmount = 0;
  if (cartItems && cartItems !== undefined) {
    cartItems.forEach((item) => {
      totalAmount += item?.quantity * item?.catalogData.price;
    });
  }

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItemsTemp?.findIndex((item) => item._id === itemId);
    if (
      newQuantity > parseInt(singleCatalogData?.catalogData?.availableQuantity)
    ) {
      notify(
        "success",
        "Available Quantity is only " +
        singleCatalogData?.catalogData?.availableQuantity
      );
    } else if (
      newQuantity > parseInt(singleCatalogData?.catalogData?.maximumQuantity)
    ) {
      notify(
        "success",
        `Maximum quanity available is ${singleCatalogData?.catalogData?.maximumQuantity} for this product`
      );
    } else {
      if (itemIndex !== -1) {
        // Update the quantity of the item
        let updatedCartItems = [...cartItemsTemp];
        if (newQuantity === 0) {
          const arr = updatedCartItems.filter(function (item) {
            return item !== updatedCartItems[itemIndex];
          });
          updatedCartItems = arr;
        } else {
          updatedCartItems = cartItems.map((item, index) =>
            index === itemIndex ? { ...item, quantity: newQuantity } : item
          );
        }
        setCartItemsTemp(updatedCartItems);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        dispatch(updateCartItem(updatedCartItems));
      }
    }
  };

  const dispatchOndcSelect = () => {
    const token = localStorage.getItem("token");
    const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
    if (!token) {
      setModalIsOpen(true);
      setLoading(false);
      return;
    }
    if (!defaultAddress?.address) {
      setAddressModal(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    setDisableCrossButton(true);
    setCartError("");
    const user = JSON.parse(localStorage.getItem("user"));
    const select_transaction_id = JSON.parse(
      localStorage.getItem("transaction_id")
    );
    const items = cartItemsTemp?.map((item) => ({
      id: item.catalogData._id,
      quantity: item.quantity,
    }));

    const transaction_id = select_transaction_id
      ? select_transaction_id
      : uuidv4();
    dispatch(
      ondcSelect({
        userAddress: defaultAddress?.address,
        items,
        userId: user?._id,
        transactionId: transaction_id,
        orderValue: parseFloat(totalAmount),
      })
    ).then((response) => {
      localStorage.setItem("transaction_id", JSON.stringify(transaction_id));
      setDisableCrossButton(false);
      setLoading(false);
      setOndcCartData(response?.payload?.data);
      if (
        response?.payload?.success &&
        response?.payload?.data &&
        !response?.payload?.data?.error
      ) {
        setCartError("");
        localStorage.setItem(
          "ondcCart",
          JSON.stringify(response?.payload?.data)
        );
        getUserFromLocalStorage() &&
          getUserFromLocalStorage() !== null &&
          getUserFromLocalStorage() !== ""
          ? navigate("/buyer-checkout")
          : setModalIsOpen(true);
      } else {
        if (
          Array.isArray(response?.payload?.data?.error?.message) &&
          response?.payload?.data?.error?.message?.length > 0
        ) {
          setErrorData(JSON.parse(response?.payload?.data?.error?.message));
        }
        const error = errorCodeMessages(
          response?.payload?.data?.error?.code,
          response?.payload?.data?.error?.message
        );
        if (!response?.payload?.data?.error?.code) {
          setErrorPopupOpen(true);
        } else {
          setCartError(error);
        }
      }
    });
  };

  const disableCartItemInteraction = () => {
    return loading;
  };

  const handleSuccessfullLogin = () => {
    setModalIsOpen(false);
    setAddressModal(true);
  };

  const groupAndSortItems = (cartItems) => {
    const grouped = cartItems?.reduce((acc, item) => {
      const storename = item?.catalogData?.store?.name;
      if (!acc[storename]) {
        acc[storename] = [];
      }
      acc[storename].push(item);
      return acc;
    }, {});

    return cartItems?.length > 0
      ? Object.entries(grouped)
        .sort(([storenameA], [storenameB]) =>
          storenameA.localeCompare(storenameB)
        )
        .map(([storename, items]) => ({ storename, items }))
      : null;
  };
  const groupedCartItems = groupAndSortItems(cartItemsTemp);

  return (
    <>
      <div id="offcanvasDiv" data-bs-dismiss="offcanvas"></div>
      <div className={errorPopupOpen ? "offcanvas-body-wrapper" : ""}>
        <div className="offcanvas-header d-flex justify-content-between align-items-center">
          <div className="my-cart-title">My Cart</div>
          <Button
            close
            disabled={disableCrossButton}
            onClick={closeCart}
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          {isCartEmpty ? (
            <div className="cart-empty-body">
              <div className="nothing-match">
                <div className="no-cart-img">
                  <img alt="No Data" src={NoCart} />
                </div>
                <h6 className="cart-empty">Your cart is empty</h6>
              </div>
            </div>
          ) : (
            <div className="cart-content-body">
              <ul className="CartContainer">
                {groupedCartItems?.map(({ storename, items }) => {
                  return (
                    <li className="cart_list" key={storename}>
                      <div className="cart-shop-name">
                        <div className="product-shop-name">
                          {storename}{" "}
                          <span className="cart-product-item">{`${items?.length
                            } ${items?.length > 1 ? "Items" : "Item"}`}</span>
                        </div>
                      </div>
                      {items?.map((cartData) => {
                        const errorItem = errorData?.find(
                          (item) =>
                            item?.item_id === cartData?.catalogData?.productId
                        );
                        const errorCode = errorItem?.error;
                        const errorMessage = errorCode
                          ? errorCodeMessages(errorCode) || "Other not Found"
                          : null;
                        const item =
                          ondcCartData &&
                          ondcCartData?.message?.order?.quote?.breakup?.find(
                            (item) =>
                              item["@ondc/org/item_id"] ===
                              cartData?.catalogData?.productId
                          );
                        const availableQuantity = item
                          ? item?.item?.quantity?.available?.count
                          : 0;
                        return (
                          <div className="cart_items" key={cartData?._id}>
                            <div className="product__image">
                              <img
                                src={
                                  cartData?.catalogData?.productImages?.[0]
                                }
                                alt="Product"
                                onError={(event) => {
                                  event.target.onerror = null;
                                  event.target.src = BrandLogo;
                                  event.target.classList.add("default-img");
                                }}
                              />
                            </div>
                            <div className="cart-right">
                              <div className="cart-shop-details">
                                <div className="product_text">
                                  <div className="ProductTitle">
                                    {cartData?.catalogData?.productName}
                                  </div>
                                  <div className="defaultProductPrice">
                                    ₹
                                    {cartData?.quantity *
                                      cartData?.catalogData?.price}
                                  </div>
                                </div>
                                <div className="addtoCart">
                                  <div className="add-cart-btn">
                                    <div
                                      id={`ui${cartData._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className={`addtocart-button button-down minus ${disableCartItemInteraction() &&
                                          "disabled"
                                          }`}
                                        onClick={() => {
                                          !disableCartItemInteraction() &&
                                            updateCartItemQuantity(
                                              cartData._id,
                                              cartData.quantity === 1
                                                ? 0
                                                : cartData.quantity - 1,
                                              cartData
                                            );
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={cartData?.quantity}
                                        disabled={disableCartItemInteraction()}
                                      />
                                      <div
                                        className={`addtocart-button button-up ${disableCartItemInteraction() &&
                                          "disabled"
                                          }`}
                                        onClick={() => {
                                          !disableCartItemInteraction() &&
                                            updateCartItemQuantity(
                                              cartData?._id,
                                              cartData?.quantity + 1,
                                              cartData
                                            );
                                        }}
                                      >
                                        <PlusIcon />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="return-cancle-flex">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="cancellable-returnable-text">
                                    {cartData?.catalogData?.isCancellable
                                      ? "Cancellable : Yes"
                                      : "Cancellable : No"}
                                  </span>
                                  <div className="divder"></div>
                                  <span className="cancellable-returnable-text">
                                    {cartData?.catalogData?.isReturnable
                                      ? "Returnable : Yes"
                                      : "Returnable : No"}
                                  </span>
                                </div>
                                <button
                                  className={`cart-removeBtn ${disableCartItemInteraction() && "disabled"
                                    }`}
                                  onClick={() => {
                                    !disableCartItemInteraction() &&
                                      updateCartItemQuantity(
                                        cartData?._id,
                                        0,
                                        cartData.catalogData
                                      );
                                  }}
                                >
                                  <DeleteIcon />
                                  Remove
                                </button>
                              </div>
                            </div>
                            <div>
                              {errorMessage && (
                                <div className="stock-error">
                                  <div className="stock-error-msg">
                                    {errorMessage}
                                  </div>
                                  {errorCode === "40002" && (
                                    <div className="d-flex align-items-center gap-3">
                                      <p className="m-0">
                                        Cart Quantity: {cartData?.quantity}
                                      </p>
                                      <p className="m-0">
                                        Available Quantity:{" "}
                                        {availableQuantity}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </li>
                  );
                })}
              </ul>
              <div className="bill-detail-container">
                <div className="bill-details-title">Bill details</div>
                <ul className="total-list">
                  <li>
                    <div className="d-flex align-items-center  gap-2">
                      <div className="total-text">Sub total</div>
                    </div>
                    <div className="BillCard__Price">
                      <div className="total-text">₹{totalAmount}</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className="total-text">Grand total</div>
                    </div>
                    <div className="BillCard__Price">
                      <div className="total-text">₹{totalAmount}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="bill-detail-container">
                <div className="bill-details-title">Cancellation Policy</div>
                <div className="total-text">
                  Orders cannot be cancelled once packed for delivery. In case
                  of unexpected delays, a refund will be provided, if
                  applicable.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {errorPopupOpen && (
        <div className="technical-issue-popup">
          <div onClick={() => setErrorPopupOpen(false)} className="cross-icon">
            <CrossIcon />
          </div>
          <div className="technical-popup-body">
            <div className="warning-icon">
              <img alt="WarningIcon" src={WarningIcon} />
            </div>
            <h1 className="technical-popup-heading">
              {" "}
              Uh-oh! Seller facing a technical issue
            </h1>
            <p className="technical-popup-para">
              Please try again or explore other stores
            </p>
            <button
              onClick={() => setErrorPopupOpen(false)}
              className="btn btn-primary w-100"
            >
              Try Again
            </button>
            <div className="text-center mt-4 mb-5">
              <a href="/" className="explore-stores">
                Explore Other Stores
              </a>
            </div>
          </div>
        </div>
      )}

      {!isCartEmpty && (
        <div className="footer-cart">
          {isItemsFromDifferentSellers && (
            <p style={{ color: "red" }}>
              You are ordering from different stores. Please check your order
              again.
            </p>
          )}
          {cartError !== "" && <p className="error">{cartError}</p>}
          <button
            className="btn btn-primary"
            disabled={loading || isItemsFromDifferentSellers}
            onClick={() => {
              dispatchOndcSelect();
            }}
          >
            <div
              className="align-items-center gap-2"
              style={{ display: "inline-flex" }}
            >
              <div className="cart-items text-left">
                <div className="cart-item-title">Order Total</div>
                <div className="cart-item-price">₹ {totalAmount}</div>
              </div>
            </div>
            <div className="proceedBtn">{loading ? <Loader /> : "Proceed"}</div>
          </button>
        </div>
      )}

      {modalIsOpen && (
        <Login
          isOpenFromCart={true}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          onSuccess={handleSuccessfullLogin}
        ></Login>
      )}
      <Modal
        isOpen={clearCart}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setClearCart(false);
        }}
        centered
        className="clear-cart clearcartmodal "
      >
        <div className="clearCart">
          <img src={clearCartIcon} alt="Icon" />
          <h5 className="cart-title">Do you really want to clear the cart?</h5>
        </div>

        <ModalFooter className="border-0">
          <button
            onClick={() => {
              setClearCart(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              localStorage.removeItem("cart");
              setCartItemsTemp([]);
              setClearCart(false);
            }}
          >
            Clear
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={addressModal}
        size="lg"
        toggle={() => setAddressModal(false)}
      >
        <div className="addressmodalBlock">
          <ManageAddress
            onAddressSelected={dispatchOndcSelect}
            setZipcode={setZipcode}
            setAddressModal={setAddressModal}
            zipcode={zipcode}
          />
        </div>
      </Modal>
    </>
  );
};
export default Cart;
