import React, { useState } from "react";
import { get } from "lodash";
import axios from "axios";
import useRazorpay from "react-razorpay";
import Loader from "../../components/common/loader";

const RazorpayModule = (props) => {
  const Razorpay = useRazorpay();
  const [loading, setLoading] = useState(false);
  let orderDetails = props.orderDetails;
  const orderAmount = props.orderAmount;
  const transactionFee = props.transactionFee;
  const paymentMethod = props.paymentMethod;
  const updateRazorPayId = async () => {
    let payableAmount = 0;
    payableAmount = orderAmount;
    if (transactionFee > 0) {
      payableAmount = payableAmount + transactionFee;
    }
    const razorpayAccount = "old-account";
    const options = {
      method: "post",
      url: `${process.env.REACT_APP_KIKO_API_BASE_URL_V2}/update-razorpayid-v2`,
      data: {
        ondcOrderId: get(orderDetails, "ondcOrderId", ""),
        payableAmount,
        transactionFee,
        paymentMode: "OldAccount",
        razorpayAccount,
      },
    };
    const result = await axios(options);
    return get(result, "data.data", {});
  };

  const handlePayment = async (e) => {
    setLoading(true);
    const orderDetails1 = await updateRazorPayId();
    const razorpayId = get(orderDetails1, "razorPayOrderId", null);
    const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY;
    props.setOpenRazorpay(false);
    const options = {
      key: razorpayKey,
      currency: "INR",
      name: "Kiko Live",
      description: "Order Payment",
      image: "",
      order_id: razorpayId,
      handler: async (response) => {
        setLoading(false);
        let values = { transactionFee };
        values.razorPayTransactionId = response?.razorpay_payment_id;
        let payableAmount = orderAmount;
        if (transactionFee > 0) {
          payableAmount += transactionFee;
        }
        const razorpayAccount = "old-account";
        const options = {
          method: "post",
          url: `${process.env.REACT_APP_KIKO_API_BASE_URL_V2}/get-payment-details-razorpay`,
          data: {
            ondcOrderId: get(orderDetails, "ondcOrderId", ""),
            paymentId: response?.razorpay_payment_id,
            razorpayAccount,
          },
        };
        const result = await axios(options);
        props.onPaymentResponse({
          paymentResponse: response,
          payableAmount,
          transactionFee,
        });
      },
      prefill: {
        name: get(orderDetails, "buyerName", ""),
        email: "",
        contact: get(orderDetails, "buyerPhoneNumber", ""),
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#7459af",
      },
      config: {
        display: {
          hide:
            paymentMethod === "upi"
              ? [
                  { method: "card" },
                  { method: "wallet" },
                  { method: "netbanking" },
                  { method: "paylater" },
                ]
              : paymentMethod === "other"
              ? [{ method: "upi" }]
              : [],
        },
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  return (
    <div className="button-loader  mb-4">
      <button
        className="btn btn-primary btn-sm w-100 "
        onClick={handlePayment}
        disabled={loading}
      >
        {loading ? "" : "Proceed"}
      </button>
      <div className="dotLoader">{loading && <Loader />}</div>
    </div>
  );
};

export default RazorpayModule;
