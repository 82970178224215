
import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  loading: false,
  hasErrors: false,
  status: false,
  address: {},
  pinCodeDetails: {},
  locations: [],
  message: "",
  openAddressDetailsModel: false,
  startLoadingMap: false,
  selectAddressMapLoading: false
};

export const mapSlice = createSlice({
  name: 'mapApi',
  initialState,
  reducers: {
    selectAddressMapLoading: (state, action) => {
      state.selectAddressMapLoading = action.payload;
    },
    startLoadingMap: (state, action) => {
      state.startLoadingMap = action.payload;
    },
    getAddressDetailsSuccess: (state, action) => {
      state.address = action.payload;
      state.loading = false;
      state.hasErrors = false;
      state.status = true;
    },
    getFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.status = false;
    },
    getPinCodeDetails: (state, action) => {
      state.pinCodeDetails = action.payload
    },
    getLocations: (state, action) => {
      state.locations = action.payload
    },
    getOpenAddressDetailsModel: (state, action) => {
      state.openAddressDetailsModel = action.payload;
    },
  },
});

export const { startLoadingMap, selectAddressMapLoading, getAddressDetailsSuccess, getFailure, getPinCodeDetails, getLocations, getOpenAddressDetailsModel } = mapSlice.actions;

export default mapSlice.reducer;

