import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Header from "../Header/component";
import { ToastContainer } from "react-toastify";
import BackArrow from "../../images/left-arrow.svg"
import { Rightarrow } from "../../components/svgicons";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NewImages/no-data.png"
import { notify, formatAndLocalizeText } from "../../components/common/utils";
import { updateCartItem } from "../../slices/catalogSlice";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import Loader from "../../components/common/loader";
import BrandLogo from "../../images/kikoimg.svg";


const MobileSearchResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let cartItems = [];
  const [catalogDataTemp, setCatalogDataTemp] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const searchedcatalogues = useSelector((s) => s.catalog.searchedcatalogues);
  const searchCatalogueStatus = useSelector((s) => s.catalog.searchCatalogueStatus);
  const cartItems = useSelector((s) => s.catalog.cartItems);
  // cartItems = JSON.parse(localStorage.getItem("cart")) || [];

  useEffect(() => {
    catalogs();
  }, [cartItems]);

  useEffect(() => {
    if (searchedcatalogues) {
      catalogs();
    }
  }, [searchedcatalogues]);


  const handleSellerClick = (data, onPressViewAll) => {
    localStorage.setItem("sellerDetails", JSON.stringify(data));
    if (onPressViewAll) {
      const encodedValue = encodeURIComponent(data?.providerId);
      navigate(`/seller?pid=${encodedValue}`);
    }
  };

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems?.findIndex((item) => item._id === itemId);
    if (newQuantity >= parseInt(singleCatalogData?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleCatalogData?.availableQuantity);
    }
    else if (newQuantity > parseInt(singleCatalogData?.maximumQuantity)) {
      notify(
        "success",
        `Maximum quanity available is ${singleCatalogData?.maximumQuantity} for this product`
      );
    }
    else {
      let updatedCartItems;
      if (itemIndex !== -1) {
        // Create a new array with the updated item
        updatedCartItems = cartItems?.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        );
        // If the new quantity is 0, remove the item
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        }
      } else {
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: singleCatalogData._id,
            quantity: newQuantity,
            catalogData: singleCatalogData,
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));
    };
  }
  const catalogs = () => {
    if (!searchedcatalogues) {
      return;
    }
    const updatedSellers = searchedcatalogues?.map((seller) => {
      // Map through each seller's cataloguesArr
      const updatedCataloguesArr = seller.cataloguesArr.map((catalogue) => {
        // Find the index of the catalogue item in cartItems based on _id
        const index = cartItems?.findIndex((item) => item._id === catalogue._id);
        // If the item is found in cartItems, assign the quantity from cartItems, otherwise set it to 1
        if (index !== -1) {
          return { ...catalogue, quantity: cartItems[index].quantity };
        } else {
          return { ...catalogue, quantity: 1 }; // Default quantity if not found in cartItems
        }
      });
      // Return the seller with the updated cataloguesArr
      return { ...seller, cataloguesArr: updatedCataloguesArr };
    });
    setCatalogDataTemp(updatedSellers);
  };


  const setVariant = (variantId, product) => {

    if (variantId) {
      // Find the selected variant
      const singleVariant = product?.variants.find((item) => item?._id === variantId);

      if (singleVariant) {
        // Update the catalog data immutably
        const updatedSellerData = catalogDataTemp.map((seller) => {
          // Update catalog data only if the product ID matches
          const updatedCataloguesArr = seller.cataloguesArr.map((catalog) => {
            if (catalog._id === product._id) {
              // If product ID matches, update the variant
              return {
                ...catalog,
                _id: singleVariant?._id,
                productName: singleVariant?.productName,
                productImages: singleVariant?.productImages,
                weight: singleVariant?.weight,
                weightUnit: singleVariant?.weightUnit,
                price: singleVariant?.price,
                maximumPrice: singleVariant?.maximumPrice,
                description: singleVariant?.description,
                quantity: 1,  // or use the existing cart quantity if needed
              };
            }
            return catalog;
          });

          return { ...seller, cataloguesArr: updatedCataloguesArr };
        });

        setCatalogDataTemp(updatedSellerData)
        setSelectedVariant(variantId);
      } else {
        console.error("Variant not found");
      }
    }
  };
  const ProductDetailSliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1378,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
    ]
  }

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <div className="mobile-search-page">
        <div className="container">
          <div className="mobile-search-title">
            <div
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              href="#"


              className="shopping-title">
              <img src={BackArrow} style={{ width: '10px', height: '12px' }} className="back-arrow" alt="back"/>
              Your Shopping List
            </div>
            <div className="total-store-found">{catalogDataTemp.length} Stores Found</div>
          </div>
        </div>
        <div className="searchstore-flex">

          {searchCatalogueStatus === "loaded" && catalogDataTemp && catalogDataTemp.length > 0 ? (
            catalogDataTemp.map((data, index) => (
              <div className="search-product-bg blue">
                <div className="container">

                  <div key={index}>
                    <div className="search-shopping-list">
                      <div className="search-store-flex">
                        <div>
                          <div className="search-store-title">{data?.name}</div>
                          {/* <div className="search-total-item">12 of 13 items</div> */}
                        </div>
                        <button type="button" className="search-view-all"
                          onClick={() => handleSellerClick(data, true)}
                        >
                          View All <Rightarrow />
                        </button>
                      </div>

                      <Slider {...ProductDetailSliderSettings}>
                        {data?.cataloguesArr?.map((product, productIndex) => (
                          <div key={productIndex}>
                            <div className="store-product-block"
                            >
                              <div onClick={() => {
                                handleSellerClick(data, false)
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: data?.cataloguesArr,
                                    productCode: product?._id ?? "",
                                    providerId: product?.providerId ?? ""
                                  },
                                })
                              }
                              }
                                className="store-product-item">
                                <div className="store-product-img">
                                  <img
                                    src={product?.productImages?.[0]}
                                    alt="Product Catalog"
                                    className="categorys-imgs"
                                    onError={(event) => {
                                      if (event.target.src === product?.productImages?.[0] && product?.productImages?.[1]) {
                                        event.target.src = product.productImages[1];
                                      }
                                      else {
                                        event.target.onerror = null;
                                        event.target.src = BrandLogo;
                                        event.target.classList.add('default-img')
                                      }
                                    }}
                                  />
                                </div>
                                <div className="store-content">
                                  <div className="product--details">
                                    <div className="store-product-title">
                                      {product?.productName}
                                    </div>
                                    {/* <div className="product-rating">
                                      <StarIcon />
                                      <StarIcon />
                                      <StarIcon />
                                      <StarIcon />
                                      <StarIcon className="active" />
                                      <span className="product--text">(4.0)</span>
                                    </div> */}
                                    {/* <div className="store-product-para">SELLER: {data?.name}</div> */}
                                    <div className="store-product-rate">
                                      <span className="purpleText">₹ {product.price}</span>
                                      <span className="line-through">₹ {product.maximumPrice}</span>
                                    </div>
                                  </div>

                                  <div className="select-variant-flex">
                                    {product?.variants?.length > 1 ? (
                                      <div onClick={(e) => e.stopPropagation()} className="variant-box">

                                        <div className="select-variant">
                                          <select
                                            className="form-select"
                                            onChange={(e) => setVariant(e.target.value, product)}
                                          >
                                            {product?.variants?.map((variant) => (
                                              <option key={variant?._id} value={variant?._id}>
                                                {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="variant-box">
                                        <button type="button" className="btn btn-outline">
                                          {`${(product?.variants?.[0]?.weightUnit === "unit" && product?.variants?.[0]?.weight) ? "1" : product?.variants?.[0]?.weight} ${formatAndLocalizeText(product?.variants?.[0]?.weightUnit)}`}
                                        </button>
                                      </div>
                                    )}

                                    <div className="addtoCart" onClick={(e) => e.stopPropagation()}>
                                      <div className="add--cart-btn">
                                        <button
                                          type="button"
                                          id={product._id}
                                          className="addBtn"
                                          onClick={() => {
                                            updateCartItemQuantity(
                                              product._id,
                                              1,
                                              // product.quantity,
                                              product);
                                          }}
                                        >
                                          Add
                                        </button>

                                        <div
                                          style={{ visibility: (cartItems.some(item => item?._id === product?._id) ? "visible" : "hidden") }}

                                          id={`ui${product._id}`}
                                          className="addtocart-qty"
                                        >
                                          <div
                                            className="addtocart-button button-down minus"
                                            onClick={() => {
                                              updateCartItemQuantity(
                                                product._id,
                                                product.quantity <= 1 ? 0 : (product.quantity -= 1),
                                                product
                                              );
                                            }}
                                          >
                                            <MinusIcon />
                                          </div>

                                          <input
                                            type="number"
                                            className="addtocart-input"
                                            value={product.quantity}
                                          />

                                          <div
                                            className="addtocart-button button-up"
                                            onClick={() => {
                                              updateCartItemQuantity(product._id, (product.quantity += 1), product);
                                            }}
                                          >
                                            <PlusIcon />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>

                </div>

              </div>

            ))
          ) : (
            searchCatalogueStatus === "loading" ?
              <p className="search-loader"><Loader /></p> :
              (searchCatalogueStatus !== "loading" && searchCatalogueStatus !== "loaded") ?
                <div className="nothing-match">
                  <p></p>
                </div> :
                <div className="nothing-match">
                  <div className="no-cart-img">
                    <img alt="NoDataImage" src={NoData} />
                  </div>
                  <h6 className="text-center">No Stores Found</h6>
                  <h6 className="text-center" style={{ whiteSpace: "nowrap" }}>

                  </h6>
                </div>
          )}</div>
      </div>
    </>
  );
};




export default MobileSearchResult;