
import store from "../store";
import {
  getAddressDetailsSuccess,
  getFailure,
  getLocations,
  getOpenAddressDetailsModel,
  getPinCodeDetails,
  startLoadingMap,
  selectAddressMapLoading,
} from "../slices/mapSlice";
import API2 from './indexv2.js';
import { GET_SELLERS, GET_SELLER_CATALOG, GET_USER_ADDRESS, ONDC_SELECT, ONDC_CART, ONDC_INIT, ONDC_CONFIRM, ONDC_CANCEL_ORDER, SEARCH_CATALOG, ONDC_RETURN_ORDER, UPLOAD_FILES, RAISE_ISSUE, ONDC_ORDER_STATUS, ONDC_ISSUE_STATUS, ONDC_ORDER_TRACK, ONDC_ISSUE_COMPLETE, ONDC_ISSUE_ESCALATE, FETCH_FAVOURITE_SELLERS, GET_PRODUCT_DETAILS_BY_ID } from './apiList';
import axios from "axios";
import { handleError } from "../components/common/utils";

// Actions
export const fetchSellersData = async (body) => {
  try {
    const response = await API2.post(GET_SELLERS, body);
    return response.data;
  } catch (error) {

  }
};

export const ondcSelectData = async (body) => {
  try {
    const response = await API2.post(ONDC_SELECT, body);
    return response.data;
  } catch (error) {

  }
};

export const ondcCartData = async (body) => {
  try {
    const response = await API2.post(ONDC_CART, body);
    return response.data;
  } catch (error) {

  }
};

export const ondcInitData = async (body) => {
  try {
    const response = await API2.post(ONDC_INIT, body);
    return response.data;
  } catch (error) {

  }
};

export const ondConfirmData = async (body) => {
  try {
    const response = await API2.post(ONDC_CONFIRM, body);
    return response.data;
  } catch (error) {

  }
};

export const ondcorderStatusData = async (body) => {
  try {
    const response = await API2.post(ONDC_ORDER_STATUS, body);
    return response.data;
  } catch (error) {
  }
};

export const ondcIssuetatusData = async (body) => {
  try {
    const response = await API2.post(ONDC_ISSUE_STATUS, body);
    return response.data;
  } catch (error) {
  }
};

export const issueComplete = async (body) => {
  try {
    const response = await API2.post(ONDC_ISSUE_COMPLETE, body);
    return response.data;
  } catch (error) {
  }
};

export const escalateIssue = async (body) => {
  try {
    const response = await API2.post(ONDC_ISSUE_ESCALATE, body);
    return response.data;
  } catch (error) {
  }
};

export const ondcOrderTrackData = async (body) => {
  try {
    const response = await API2.post(ONDC_ORDER_TRACK, body);
    return response.data;
  } catch (error) {

  }
};

export const ondcCancelOrder = async (body) => {
  try {
    const response = await API2.post(ONDC_CANCEL_ORDER, body);
    return response.data;
  } catch (error) {
  }
};

export const ondcReturnOrder = async (body) => {
  try {
    const response = await API2.post(ONDC_RETURN_ORDER, body);
    return response.data;
  } catch (error) {
  }
};

export const uploadFileData = async (body) => {
  try {
    const response = await API2.post(UPLOAD_FILES, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
  }
};

export const raiseIssue = async (body) => {
  try {
    const response = await API2.post(RAISE_ISSUE, body)
    return response.data;
  } catch (error) {
  }
};

export const fetchSellersCatalogData = async (body) => {
  try {
    const response = await API2.post(GET_SELLER_CATALOG, body);
    return response.data;
  } catch (error) {
  }
};

export const fetchCateloguesData = async (body) => {
  try {
    let token = body.token
    delete body.token
    const response = await API2.post(SEARCH_CATALOG, body, { cancelToken: token });
    return response.data;
  } catch (error) {
  }
};
export const fetchFavouriteSellerData = async (body) => {
  try {
    const response = await API2.post(FETCH_FAVOURITE_SELLERS, body);
    return response.data;
  } catch (error) {
  }
};

export const getPlacesDetails = async (obj) => {
  store.dispatch(startLoadingMap(true));
  var config = {
    method: "get",
    url:
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      obj.latitude +
      "," +
      obj.longitude +
      "&key=" +
      process.env.REACT_APP_GOOGLE_PLACES_DETAILS,
  };
  let response = await axios(config);
  store.dispatch(selectAddressMapLoading(false));
  store.dispatch(startLoadingMap(false));
  if (response?.data?.results.length > 0) {
    const place_detail = response?.data?.results?.find(result =>
      result?.address_components?.some(ele => ele?.types?.includes("postal_code"))
    );

    const postal_code = place_detail?.address_components?.findIndex((ele) =>
      ele.types.includes("postal_code")
    );
    const zipcode =
      postal_code !== -1
        ? place_detail?.address_components?.[postal_code].long_name
        : "";
    const administrative_area_level_3 =
      place_detail?.address_components?.findIndex((ele) =>
        ele.types.includes("administrative_area_level_3")
      );
    const administrative_area_level_1 =
      place_detail?.address_components?.findIndex((ele) =>
        ele.types.includes("administrative_area_level_1")
      );
    const city =
      administrative_area_level_3 !== -1
        ? place_detail?.address_components[administrative_area_level_3].long_name
        : "";
    const state =
      administrative_area_level_1 !== -1
        ? place_detail?.address_components[administrative_area_level_1].long_name
        : "";

    const addressDetails = {
      latitude: obj.latitude,
      longitude: obj.longitude,
      state: state,
      city: city,
      pincode: zipcode,
      place_detail: place_detail?.formatted_address,
      title: place_detail?.formatted_address,
      address_line2: place_detail?.formatted_address,
      locations: [],
    };
    store.dispatch(getAddressDetailsSuccess(addressDetails));
    store.dispatch(getOpenAddressDetailsModel(true));
  } else {
    store.dispatch(startLoadingMap(false));
    store.dispatch(selectAddressMapLoading(false));
    store.dispatch(getFailure());
  }
};

export const getPincodeDetail = async (pincode) => {
  try {
    if (pincode.length > 4) {
      const options = {
        method: "post",
        url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/getpicodedetails`,
        data: { pincode: pincode },
      };
      const { data } = await axios(options);
      if (data.data.city) {
        localStorage.setItem(
          "city",
          data.data.city ? data.data.city : "Current Location"
        );
        localStorage.setItem(
          "pincode",
          data.data.pincode ? data.data.pincode : ""
        );
        const addressDetails = {
          latitude: data.data.latitude ? data.data.latitude : 0.0,
          longitude: data.data.city ? data.data.city : 0.0,
          state: data.data.state ? data.data.state : "",
          city: data.data.city ? data.data.city : "",
          pincode: data.data.pincode ? data.data.pincode : 0,
        };
        store.dispatch(getPinCodeDetails(addressDetails));
      }
    }
  } catch (error) {
  }
};
export const placeDetail = async (placeId) => {
  try {
    store.dispatch(selectAddressMapLoading(true));
    const mapToken = await mapTokenGenerator();
    const token = JSON.parse(localStorage.getItem("token")) || "";
    const options = {
      method: "post",
      url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/getdetails`,
      headers: {
        Authorization: `${token}`,
        desktop: true,
      },
      data: { placeId: placeId, token: mapToken },
    };
    const data = await axios(options);
    if (data?.data?.data["geometry"]) {
      const obj = {
        latitude: data?.data?.data?.geometry?.location?.lat,
        longitude: data?.data?.data?.geometry?.location?.lng,
      };
      getPlacesDetails(obj);
    } else {
      store.dispatch(selectAddressMapLoading(false));
      alert("lat long not found, please select another address");
    }
  } catch (error) {
    handleError(error);
    store.dispatch(selectAddressMapLoading(false));
  }
};

export const handleAddress = async (address) => {
  const mapToken = await mapTokenGenerator();
  const token = JSON.parse(localStorage.getItem("token")) || "";
  store.dispatch(getLocations([]));
  if (address.length > 2) {
    try {
      store.dispatch(startLoadingMap(true));
      const options = {
        method: "post",
        url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/autocomplete-v2`,
        headers: {
          Authorization: `${token}`,
          desktop: true,
        },
        data: { search: address, token: mapToken },
      };
      const data = await axios(options);
      if (data?.data?.success) {
        store.dispatch(getLocations(data.data.data));
      }
      store.dispatch(startLoadingMap(false));
    } catch (error) {
      store.dispatch(startLoadingMap(false));
    }
  }
};

export const mapTokenGenerator = async () => {
  const token = JSON.parse(localStorage.getItem("token")) || "";
  const options = {
    method: "get",
    url: `${process.env.REACT_APP_KIKO_API_BASE_URL}/maps/gettoken`,
    headers: {
      Authorization: `${token}`,
      desktop: true,
    },
  };
  return axios(options)
    .then(({ data }) => {
      if (data) {
        return data?.data;
      } else {
        throw new Error("Token data is missing");
      }
    })
    .catch((error) => {
    });
};

export const fetchAddressData = async (params) => {
  try {
    const response = await API2.get(GET_USER_ADDRESS, { params });
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
  }
};

export const getProductDetailsById = async (body) => {
  try {
    const response = await API2.post(GET_PRODUCT_DETAILS_BY_ID, body);
    return response.data;
  } catch (error) {
  }
};