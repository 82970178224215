import { combineReducers, configureStore } from "@reduxjs/toolkit";
import catalogSlice from './slices/catalogSlice';
import mapSlice from './slices/mapSlice';
import addressSlice from "./slices/addressSlice"
import sellers from './slices/sellerSlice';
import ondcPreorder from './slices/ondcPreorderSlice';
import ondcPostorder from "./slices/ondcPostorderSlice";
import userSlice from "./slices/userSlice";

const rootReducer = combineReducers({
    user:userSlice,
    catalog: catalogSlice,
    mapApi: mapSlice,
    address: addressSlice,
    sellers: sellers,
    ondcPreorder:ondcPreorder,
    ondcPostorder:ondcPostorder
})

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
})

export default store;