import React, { useEffect, useState } from "react";
import API2 from "../../api/indexv2";
import { handleError, notify, deliveryStatus } from "../../components/common/utils";
import { GET_ORDER_DETAIL } from "../../api/apiList";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import Header from "../Header/component";
import CancelOrderModal from "./cancelOrderModal";
import TrackOrderModal from "./trackOrderModal";
import ReturnOrderModal from "./returnOrderModal";
import RaiseIssueModal from "./raiseIssueModal";
import Loader from "../../components/common/loader";
import { ToastContainer } from "react-toastify";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  ondcOrderStatus,
  ondcIssueStatus,
  ondcOrderTrack,
  completeIssue,
  esclateIssue
} from "../../slices/ondcPostorderSlice";
import { get } from "lodash";
import { types } from "../../components/common/utils";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [trackModal, setTrackModal] = useState(false);
  const location = useLocation();
  const [RaiseTicket, setRaiseTicket] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [cancelledAndReturned, setCancelledAndReturned] = useState([]);
  const [orderAmounts, setOrderAmounts] = useState({
    TotalCurrentItemsAmount: 0,
    TotalOtherChargesAmount: 0,
  });
  const { ondcOrderstatus,
    ondcIssuestatus,
    ondcTrackOrderstatus,
    ondcComplteIssueStatus,
    ondcEsclateIssueStatus
  } =
    useSelector((state) => ({
      ondcOrderstatus: get(state, "ondcPostorder.ondcOrderstatus", ""),
      ondcIssuestatus: get(state, "ondcPostorder.ondcIssuestatus", ""),
      ondcTrackOrderstatus: get(state, "ondcPostorder.ondcTrackOrderstatus", ""),
      ondcComplteIssueStatus: get(state, "ondcPostorder.ondcComplteIssueStatus", ""),
      ondcEsclateIssueStatus: get(state, "ondcPostorder.ondcEsclateIssueStatus", ""),
    }));
  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = async () => {
    setLoading(true);
    const body = {
      _id: location.state?.orderId,
    };

    try {
      const response = await API2.post(GET_ORDER_DETAIL, body);
      if (response) {
        const data = response?.data?.data
        setOrderData(data);
        setItemData(data?.itemRes);
        const orderItems = data?.itemRes?.filter((item) => item?.type === "Item" && item.quantity.count !== 0)
        const cancelledAndReturned = data?.itemRes.filter((item) => (item.type === "Return" || item.type === "Cancel") && item.quantity.count !== 0)
        const updatedOrderItems = orderItems?.map(item => {
          const issueAlreadyRaised = data?.complaints?.some(complain =>
            complain?.items[0]?.productId === item?.id && complain?.raw?.message?.issue?.status === "OPEN"
          );
          return {
            ...item,
            issueAlreadyRaised
          };
        });
        setOrderItems(updatedOrderItems)
        setCancelledAndReturned(cancelledAndReturned)
        const totalCurrentItemsAmount = data?.itemRes?.reduce((acc, item) => {
          return acc + (item?.discountedPrice && (item?.status === "" || item?.status === "Return_Initiated" || item?.status === "Return_Approved") && item?.quantity?.count > 0 ? parseFloat(item?.discountedPrice) : 0);
        }, 0);
        let totalOAmount = 0;
        if (data?.otherChargesItems) {
          totalOAmount = data?.otherChargesItems?.reduce((acc, item) => {
            const price = parseFloat(item?.price?.value) ? item?.price?.value : item?.item?.price?.value
            if (!["item"].includes(item["@ondc/org/title_type"])) {
              return acc + parseFloat(price) || 0;
            }
            return acc;
          }, 0);
        }
        setOrderAmounts({
          TotalCurrentItemsAmount: totalCurrentItemsAmount,
          TotalOtherChargesAmount: totalOAmount,
        });
      }
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
      setOrderData({});
    }
  };

  const getOrdeStatus = () => {
    if (orderData && ondcOrderstatus !== "loading") {
      const res = dispatch(
        ondcOrderStatus({
          orderId: orderData?._id,
        })
      ).then((response) => {
        if (response?.payload?.success) {
          setOrderData((prevState) => ({
            ...prevState,
            orderStatus: response?.payload?.data?.orderStatus,
          }));
        }
        getOrderDetail()
      });
      notify("promise", "Order status fetched successfully!", res);
    }
  };
  const trackOrder = () => {
    dispatch(
      ondcOrderTrack({
        orderId: orderData?._id,
      })
    )
      .then((response) => {
        if (response?.payload?.data?.trackingDetails?.details?.url) {
          notify("success", "Track successfully!");
          const trackingUrl = response?.payload?.data?.trackingDetails?.details?.url;
          window.open(trackingUrl);
        } else {
          notify(
            "error",
            "Cannot proceed with your request now! Please try again later."
          );
        }
      })
      .catch((error) => {
        notify(
          "error",
          "An error occurred while processing your request. Please try again later."
        );
      });
  };

  const getIssueStatus = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        ondcIssueStatus({
          orderId: orderData?._id,
          issue_id: issueId,
        })
      ).then((response) => {
        if (response?.payload?.result?.context?.action === "issue_status") {
          notify("success", "Issue status updated successfully!");
          getOrderDetail()
        }
      });
    }
  };

  const closeIssue = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        completeIssue({
          orderId: orderData?._id,
          issueId: issueId,
        }),

      ).then((response) => {
        if (response?.payload?.result?.context?.action) {
          getOrderDetail();
          notify("success", "Issue Marked Resolved successfully!");
        }
      });
    }
  };

  const escalateIssue = (issueId) => {
    if (orderData && ondcOrderstatus !== "loading") {
      dispatch(
        esclateIssue({
          orderId: orderData?._id,
          issueId: issueId,
        })
      ).then((response) => {
        if (response?.payload?.result?.context?.action) {
          getOrderDetail()
          notify("success", "Issue Marked Not Resolved successfully!");
        }
      });
    }
  };

  function getSubcategoryLabel(subcategoryValue) {
    for (const type of types) {
      const subcategory = type.subcategories.find(
        (sub) => sub.value === subcategoryValue
      );
      if (subcategory) {
        return subcategory.label;
      }
    }
    return 'Subcategory not found';
  }

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        position="top-right"
      />
      <Header />
      {loading && !orderData?._id ? (
        <div className="dotLoader">
          <Loader />
        </div>
      ) : (
        <section className="order-details-section">
          <div className="buyercheckout-heading">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="title">Orders Details</h1>
              </div>
            </div>
          </div>
          <div className="order-details-contaier">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="cart-details-container">
                    <div className="summary-card">
                      <div className="order-delivery-status">
                        <div className="summary-heading">
                          <div className="summary-title mb-2">
                            Order Id: {orderData?.orderId}
                          </div>
                          <div className="summary-title mb-2">
                            Seller: {orderData?.delivery?.['@ondc/org/provider_name']}
                          </div>
                          <div className="order-on-title">
                            Ordered On:
                            {dayjs(orderData?.createdAt).format(
                              "MMM D, YYYY h:mm A"
                            )}{" "}
                            | Payment: Prepaid
                          </div>
                        </div>
                        <div className="order-status-btn">
                          <div className="order-status-title">Order Status </div>
                          <button className="btn btn-gray" type="button">{orderData?.orderStatus === "Cancelled" ? orderData?.orderStatus : deliveryStatus(orderData?.deliveryStatus)}</button>
                        </div>
                      </div>
                      <div className="summary-content-block">
                        {orderItems?.length > 0 && orderItems?.map((item, index) => {
                          return (
                            <div key={index} className="summary-content">
                              <div className="summary-product-list">
                                <div className="order-detail-product-img">
                                  {item?.productImages &&
                                    item?.productImages
                                      ?.length > 0 && (
                                      <img
                                        src={
                                          item.productImages?.[0]
                                        }
                                        alt={item?.title}
                                      />
                                    )}
                                </div>
                                <div>
                                  {item?.productName && <div className="summary-product-title">{item?.productName}</div>}
                                  {item?.quantity?.count && (<>
                                    <div className="summary-product-title">QTY: {item?.quantity?.count}</div>
                                    <div className="summary-product-title mb-0">₹ {item?.discountedPrice}</div>
                                  </>)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {cancelledAndReturned?.length > 0 && cancelledAndReturned?.map((item, index) => {
                          return (
                            <div key={index} className="summary-content">
                              <div className="d-flex align-items-center gap-2">
                                {item?.productImages &&
                                  item?.productImages
                                    ?.length > 0 && (
                                    <img
                                      src={
                                        item.productImages?.[0]
                                      }
                                      alt={item?.title}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}

                                {item?.productName && <p className="m-0">{item?.productName}</p>}
                              </div>
                              <div className="d-flex align-items-center justify-content-between pt-2">
                                {item?.quantity?.count && (
                                  <p className="text-end ">
                                    {item?.quantity?.count}
                                    * Base Price
                                  </p>
                                )}

                                <p>₹{item?.discountedPrice}</p>
                              </div>
                              <p><b>{item?.status}</b></p>
                              {
                                (item?.logisticsProviderName && item?.status !== "Return_Delivered") &&
                                <div className="summary-flex py-2">
                                  <p className="m-0">Return will be picked up by : {item?.logisticsProviderName}</p>
                                  {item?.pickUpOTP &&
                                    <p className="m-0">
                                      <b>PICK UP OTP : {item?.pickUpOTP}</b>
                                    </p>
                                  }
                                </div>
                              }
                              {
                                item?.refundStatus &&
                                <div>
                                  <p>Refund Status:{item?.refundStatus === "Completed" ? " Amount Refunded" : "Pending"}</p>
                                  <p className="m-0">Refund Amount: {item?.refundAmount}</p>
                                </div>
                              }
                            </div>

                          );
                        })}
                        {cancelledAndReturned?.length > 0 &&
                          <div
                            className="summary-flex pt-2"
                          >
                            <p className="m-0">
                              <b>Total Refund Amount</b>
                            </p>
                            <p className="m-0">
                              <b>₹{orderData?.totalRefundAmount}</b>
                            </p>
                          </div>
                        }
                      </div>
                      <div className="summary-content-footer">
                        <div className="summary-footer-btn m-0">
                          {
                            (orderData?.orderStatus === "Accepted" || orderData?.orderStatus === "Created")
                            && itemData?.filter((item) => item.type == "Item")?.every(item => item?.isCancellable === true) &&
                            <button type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setCancelModal(true);
                              }}
                              disabled={ondcOrderstatus === "loading"}
                            >
                              Cancel Order
                            </button>
                          }
                          {
                            orderData?.orderStatus !== "Cancelled" &&
                            orderItems?.length > 0 &&
                            (
                              <>
                                <button type="button"
                                  className="btn btn-outline"
                                  onClick={() => {
                                    setRaiseTicket(true);
                                  }}
                                  disabled={ondcOrderstatus === "loading"}
                                >
                                  Raise Issue
                                </button>
                              </>
                            )}
                          {orderData?.orderStatus === "Completed" && orderItems?.length > 0 &&
                            itemData?.filter((item) => item.type == "Item")?.some(item => item?.isReturnable === true) &&
                            (
                              <>
                                <button type="button"
                                  onClick={() => {
                                    setReturnModal(true);
                                  }}
                                  className="btn btn-danger"
                                  disabled={ondcOrderstatus === "loading"}
                                >
                                  Return
                                </button>
                              </>
                            )}
                          {(orderData?.deliveryStatus === "Order-picked-up" || orderData?.deliveryStatus === "Out-for-delivery") &&
                            <button type="button"
                              className="btn btn-outline"
                              onClick={() => trackOrder()}
                              disabled={ondcOrderstatus === "loading"}
                            >
                              {ondcTrackOrderstatus === "loading"
                                ? "Please Wait..."
                                : "Track Order"}
                            </button>}
                          <button type="button"
                            className="btn btn-outline "
                            onClick={() => getOrdeStatus()}
                          >
                            {ondcOrderstatus === "loading"
                              ? "Please Wait..."
                              : "Get Status"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {<div className="col-lg-4">
                  <div className="order-details-summary">
                    <div className="order-summary-heading">Order Summary</div>
                    <div className="order-summary-details">
                      <div className="order-detail-item-total">
                        <div className="item-total-heading">Item Total ({orderItems?.length} {orderItems?.length > 1 ? "Items" : "Item"})</div>
                        <div className="order-total-amount">₹ {orderAmounts?.TotalCurrentItemsAmount}</div>
                      </div>
                      <div className="order-detail-block">
                        {orderData?.otherChargesItems?.map((item, index) => {
                          const rtoCharges = item["@ondc/org/item_id"] !== orderData?.delivery?.id
                          return (
                            item["@ondc/org/title_type"] !== "item" &&
                            <>
                              <div className="order-details-list">
                                <div className="order-detail-item">
                                  {item?.title && `${item?.title} ${rtoCharges ? "(RTO)" : ""}`}
                                </div>
                                <div className="order-detail-item">₹ {parseFloat(item.price.value) ? item.price.value : parseFloat(item?.item?.price?.value) ? item.item.price.value : 0}</div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                      <div
                        className="summary-flex py-2"
                        style={{
                          borderBottom: "1px solid #d3d3d3",
                          borderTop: "1px solid #d3d3d3",
                        }}
                      >
                        <p className="m-0">
                          <b>Sub Total</b>
                        </p>
                        <p className="m-0">
                          <b>₹{orderAmounts?.TotalOtherChargesAmount?.toFixed(2)}</b>
                        </p>
                      </div>
                      <div className="order-details-header">
                        <div className="total-text">Total</div>
                        <div className="total-text">₹ {orderData?.totalAmountPaid}</div>
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="col-lg-8">
                  {orderData?.delivery &&
                    <div className="order-details-content">
                      <div className="customer-details">
                        <div className="delivered-title">Delivered To</div>
                        <div className="customer-name">
                          {orderData?.delivery?.end?.location?.address?.name},{" "}
                        </div>
                        <div className="customer-address">{orderData?.delivery?.end?.contact?.phone}</div>
                        <div className="customer-address mb-0">
                          {orderData?.delivery?.end?.location?.address?.locality}
                        </div>
                      </div>
                    </div>
                  }
                  <div className="complaints-block">
                    {orderData?.complaints && orderData?.complaints.length > 0 && (
                      <div className="card-accordion">
                        <div className="accordion" id="accordionExample">
                          {orderData?.complaints.map((complaint, complaintIndex) => {
                            const issue = complaint.raw?.message?.issue
                            const resActions = complaint?.raw?.message?.issue?.issue_actions?.respondent_actions
                            const complainant_actions = complaint.raw?.message?.issue?.issue_actions?.complainant_actions
                            const respondent_actions = complaint.raw?.message?.issue?.issue_actions?.respondent_actions
                            const description = complaint?.raw?.message?.issue?.description
                            const resolution = complaint?.raw?.message?.issue?.resolution
                            return (
                              <div className="accordion-item" key={complaintIndex}>
                                <h2 className="accordion-header" id={`heading${complaintIndex}`}>
                                  <button type="button"
                                    className="accordion-button p-0"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${complaintIndex}`}
                                    aria-expanded={complaintIndex === 0 ? "true" : "false"}
                                    aria-controls={`collapse${complaintIndex}`}
                                  >
                                    Complaints {complaintIndex + 1}
                                  </button>
                                </h2>
                                <div className="complaints-flex">
                                  <div className="complaints-items">
                                    <div className="d-flex gap-2 align-items-center">
                                      <div>
                                        <h1 className="missing-item-title">
                                          {issue?.category} -
                                          {getSubcategoryLabel(issue.sub_category)}
                                        </h1>
                                        <div className="issue-id-text">
                                          Issue raised on {dayjs(issue?.created_at).format("MMM D, YYYY h:mm A")}
                                        </div>
                                        <div className="issue-id-text">Issue Id: {complaint?.issue_id}</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="complaints-status">
                                    <div className="status-text">Status: <span className="green">{issue?.status}</span></div>
                                    <p><b>Expected response time:</b> {dayjs(issue?.created_at).add(1, "hour").format("MMM D, YYYY h:mm A")}</p>
                                    <p><b>Expected resolution time:</b> {dayjs(issue?.created_at).add(1, "day").format("MMM D, YYYY h:mm A")}</p>
                                  </div>
                                </div>
                                <div
                                  id={`collapse${complaintIndex}`}
                                  className={`accordion-collapse collapse ${complaintIndex === 0 ? "show" : ""}`}
                                  aria-labelledby={`heading${complaintIndex}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body p-0">
                                    <div className="complaints-msg">
                                      <div className="complaints-msg-text"><b>Subject:</b> {description?.short_desc}</div>
                                    </div>
                                    <div className="complaints-msg">
                                      <div className="complaints-msg-text"><b>Message:</b> {description?.long_desc}</div>
                                    </div>
                                    <div className="complaints-msg border-0">
                                      <div className="complaints-msg-text"><b>Action Taken</b></div>
                                    </div>
                                    <div className="Complaint-created-block">
                                      {complainant_actions &&
                                        complainant_actions.filter(action => action?.complainant_action !== "CLOSE")
                                          .map((item, index) => (
                                            <div className="Complaint-created-flex" key={index}>
                                              <div className="d-flex gap-2 align-items-start">
                                                <div className="created complaint-green">
                                                  <p></p>
                                                </div>
                                                <div className="action-text-flex">
                                                  <div className="action-taken-text"><b>{item.short_desc}</b></div>
                                                  <div className="action-taken-text">Updated by: {item.updated_by?.person?.name}, {complaint.raw?.context?.bap_id}</div>
                                                  <div className="action-taken-text">Updated at: {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}</div>
                                                </div>
                                              </div>
                                              <div>
                                                <div className="action-taken-right">Actions: {item.complainant_action}</div>
                                              </div>
                                            </div>
                                          ))
                                      }
                                      {respondent_actions &&
                                        respondent_actions.map((action, index) => (
                                          <div className="Complaint-created-flex" key={index}>
                                            <div className="d-flex gap-2 align-items-start">
                                              <div className={index !== complaint.raw.message.issue.issue_actions.respondent_actions.length - 1 || action.respondent_action === "RESOLVED" ? "created complaint-green" : "created complaint"}>
                                                <p></p>
                                              </div>
                                              <div className="action-text-flex">
                                                <div className="action-taken-text"><b>{action.short_desc}</b></div>
                                                <div className="action-taken-text">Updated by: {action.updated_by?.person?.name}, {complaint.raw?.context?.bpp_id}</div>
                                                <div className="action-taken-text">Updated at: {dayjs(action.updated_at).format("MMM D, YYYY h:mm A")}</div>
                                              </div>
                                            </div>
                                            <div>
                                              <div className="action-taken-right">Actions: {action.respondent_action}</div>
                                            </div>
                                          </div>
                                        ))
                                      }
                                      {complainant_actions &&
                                        complainant_actions.filter(action => action?.complainant_action === "CLOSE")
                                          .map((action, index) => (
                                            <div className="Complaint-created-flex" key={index}>
                                              <div className="d-flex gap-2 align-items-start">
                                                <div className={index !== complainant_actions.length - 1 ? "created complaint-green" : "created complaint"}>
                                                  <p></p>
                                                </div>
                                                <div>
                                                  <p><b>{action?.short_desc}</b></p>
                                                  <p>Updated by: {action?.updated_by?.person?.name}, {complaint?.raw?.context?.bpp_id}</p>
                                                  <p>Updated at: {dayjs(action?.updated_at).format("MMM D, YYYY h:mm A")}</p>
                                                </div>
                                              </div>
                                              <div>
                                                <p><b>Actions: </b>{action?.complainant_action}</p>
                                              </div>
                                            </div>
                                          ))
                                      }
                                    </div>
                                    {resolution &&
                                      <div className="Respondent">
                                        <div>
                                          {resolution?.short_desc && <p><b>Resolution :  {resolution?.short_desc} </b></p>}
                                          {resolution?.long_desc && <p>Description: {resolution?.long_desc}</p>}
                                          {resolution?.action_triggered && <p>Action Taken: {resolution?.action_triggered}</p>}
                                          {resolution?.refund_amount && <p>Refund Amount: {resolution?.refund_amount}</p>}
                                        </div>

                                      </div>
                                    }
                                    <div className="Respondent">
                                      <div className="respondent-flex">
                                        <div className="respondent-heading">Respondent </div>
                                        <div className="respondent-text">Email: {resActions?.[0]?.updated_by?.contact?.email}</div>
                                        <div className="respondent-text m-0">Phone: {resActions?.[0]?.updated_by?.contact?.phone}</div>
                                      </div>
                                      <div className="get-status-btn">
                                        <button type="button"
                                          onClick={() => getIssueStatus(issue?.id)}
                                          className="btn btn-outline"
                                          disabled={ondcIssuestatus === "loading"}
                                        >
                                          {ondcIssuestatus === "loading" ? "Please Wait..." : "Get Status"}
                                        </button>
                                      </div>
                                      {resActions?.slice(-1)[0]?.respondent_action === "RESOLVED" && complainant_actions.some(action => action?.complainant_action === "CLOSE") === false &&
                                        <div>
                                          <button type="button"
                                            onClick={() => escalateIssue(complaint._id)}
                                            className="btn btn-outline"
                                            disabled={ondcEsclateIssueStatus === "loading"}
                                          >
                                            {ondcEsclateIssueStatus === "loading" ? "Please Wait..." : "Escalate Issue"}
                                          </button>
                                        </div>
                                      }
                                      {resActions?.slice(-1)[0]?.respondent_action === "RESOLVED" && !complainant_actions.some(action => action?.complainant_action === "CLOSE") &&
                                        <div>
                                          <button type="button"
                                            onClick={() => closeIssue(complaint._id)}
                                            className="btn btn-outline"
                                            disabled={ondcComplteIssueStatus === "loading"}
                                          >
                                            {ondcComplteIssueStatus === "loading" ? "Please Wait..." : "Mark Resolved"}
                                          </button>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {cancelModal && (
        <CancelOrderModal
          setCancelModal={setCancelModal}
          getOrderDetail={getOrderDetail}
          cancelModal={cancelModal}
          orderId={orderData?._id}
        ></CancelOrderModal>
      )}
      {returnModal && (
        <ReturnOrderModal
          setReturnModal={setReturnModal}
          getOrderDetail={getOrderDetail}
          returnModal={returnModal}
          orderId={orderData?._id}
          itemData={orderItems}
        ></ReturnOrderModal>
      )}
      {RaiseTicket && (
        <RaiseIssueModal
          setRaiseTicket={setRaiseTicket}
          getOrderDetail={getOrderDetail}
          RaiseTicket={RaiseTicket}
          orderId={orderData?._id}
          itemData={orderItems}
          orderStatus={orderData?.orderStatus}
        ></RaiseIssueModal>
      )}
      {trackModal && (
        <TrackOrderModal
          setTrackModal={setTrackModal}
          trackModal={trackModal}
        ></TrackOrderModal>
      )}
    </>
  );
};
export default OrderDetails;
