
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ondcCancelOrder, ondcReturnOrder, uploadFileData, raiseIssue, ondcorderStatusData, ondcIssuetatusData, ondcOrderTrackData, issueComplete, escalateIssue } from '../api/ApiService';
export const initialState = {
  ondcCancelstatus: "",
  uploadFilestatus: "",
  ondcRetunstatus: "",
  raiseIssuestatus: "",
  hasErrors: "",
  paymentSuccessful: false,
  uploadFile: {},
  ondcOrderstatus: "",
  ondcIssuestatus: "",
  ondcTrackOrderstatus: "",
  ondcComplteIssueStatus: "",
  ondcEsclateIssueStatus: ""
};

export const ondcPostorderSlice = createSlice({
  name: 'ondc',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(ondcCancel.pending, (state) => {
      state.ondcCancelstatus = "loading"
    })
      .addCase(ondcCancel.fulfilled, (state, action) => {
        state.ondcCancelstatus = "loaded"
      })
      .addCase(ondcCancel.rejected, (state) => {
        state.ondcCancelstatus = "error"
      })
      .addCase(uploadFile.pending, (state) => {
        state.uploadFilestatus = "loading"
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.uploadFilestatus = "loaded"
        state.uploadFile = action.payload
      })
      .addCase(uploadFile.rejected, (state) => {
        state.uploadFilestatus = "error"
      })
      .addCase(ondcRetun.pending, (state) => {
        state.ondcRetunstatus = "loading"
      })
      .addCase(ondcRetun.fulfilled, (state, action) => {
        state.ondcRetunstatus = "loaded"
      })
      .addCase(ondcRetun.rejected, (state) => {
        state.ondcRetunstatus = "error"
      })
      .addCase(raiseIssuedata.pending, (state) => {
        state.raiseIssuestatus = "loading"
      })
      .addCase(raiseIssuedata.fulfilled, (state, action) => {
        state.raiseIssuestatus = "loaded"
      })
      .addCase(raiseIssuedata.rejected, (state) => {
        state.raiseIssuestatus = "error"
      })
      .addCase(ondcOrderStatus.pending, (state) => {
        state.ondcOrderstatus = "loading"
      })
      .addCase(ondcOrderStatus.fulfilled, (state, action) => {

        state.ondcOrderstatus = "loaded"
      })
      .addCase(ondcOrderStatus.rejected, (state) => {
        state.ondcOrderstatus = "error"
      })
      .addCase(ondcIssueStatus.pending, (state) => {
        state.ondcIssuestatus = "loading"
      })
      .addCase(ondcIssueStatus.fulfilled, (state, action) => {

        state.ondcIssuestatus = "loaded"
      })
      .addCase(ondcIssueStatus.rejected, (state) => {
        state.ondcIssuestatus = "error"
      })
      .addCase(ondcOrderTrack.pending, (state) => {
        state.ondcTrackOrderstatus = "loading"
      })
      .addCase(ondcOrderTrack.fulfilled, (state, action) => {
        state.ondcTrackOrderstatus = "loaded"
      })
      .addCase(ondcOrderTrack.rejected, (state) => {
        state.ondcTrackOrderstatus = "error"
      })
      .addCase(completeIssue.pending, (state) => {
        state.ondcComplteIssueStatus = "loading"
      })
      .addCase(completeIssue.fulfilled, (state, action) => {
        state.ondcComplteIssueStatus = "loaded"
      })
      .addCase(completeIssue.rejected, (state) => {
        state.ondcComplteIssueStatus = "error"
      })
      .addCase(esclateIssue.pending, (state) => {
        state.ondcEsclateIssueStatus = "loading"
      })
      .addCase(esclateIssue.fulfilled, (state, action) => {
        state.ondcEsclateIssueStatus = "loaded"
      })
      .addCase(esclateIssue.rejected, (state) => {
        state.ondcEsclateIssueStatus = "error"
      })
  }
})

export const ondcCancel = createAsyncThunk(
  'ondc/cancel',
  async (body) => {
    const data = ondcCancelOrder(body);
    return data

  }
);

export const ondcRetun = createAsyncThunk(
  'ondc/return',
  async (body) => {
    const data = ondcReturnOrder(body);
    return data

  }
);
export const uploadFile = createAsyncThunk(
  'upload/file',
  async (formData) => {
    const data = await uploadFileData(formData);
    return data

  }
);

export const raiseIssuedata = createAsyncThunk(
  'ondc/raiseIssue',
  async (body) => {
    const data = raiseIssue(body);
    return data

  }
);

export const ondcOrderStatus = createAsyncThunk(
  'ondc/orderStatus',
  async (body) => {
    const data = ondcorderStatusData(body);
    return data

  }
);

export const ondcOrderTrack = createAsyncThunk(
  'ondc/orderTrack',
  async (body) => {
    const data = ondcOrderTrackData(body);
    return data

  }
);
export const ondcIssueStatus = createAsyncThunk(
  'ondc/issueStatus',
  async (body) => {
    const data = ondcIssuetatusData(body);
    return data

  }
);

export const completeIssue = createAsyncThunk(
  'ondc/issuecomplete',
  async (body) => {
    const data = issueComplete(body);
    return data

  }
);

export const esclateIssue = createAsyncThunk(
  'ondc/esclateIssue',
  async (body) => {
    const data = escalateIssue(body);
    return data

  }
);
export const { showPaymentSuccessModal } = ondcPostorderSlice.actions;
export default ondcPostorderSlice.reducer;