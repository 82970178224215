import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import { useLocation, useNavigate } from "react-router-dom";
import CartHeader from "../../components/common/CartHeader";
import { Offcanvas } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { getProductDetails, updateCartItem } from "../../slices/catalogSlice";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import BrandLogo from "../../images/kikoimg.svg";
import ExternalLink from "../../images/v2-images/external-link.svg"
import Rightarrow from "../../images/right-arrow.svg"
import BackArrow from "../../images/left-arrow.svg"
import "./styles.scss"
import Loader from "../../components/common/loader";

const ProductDetails = (props) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const cartItems = useSelector((state) => get(state, "catalog.cartItems", []));
  const ondcSelectstatus = useSelector((s) => s.ondcPreorder.ondcSelectstatus);
  const location = useLocation();
  const navigate = useNavigate();
  const { catalogDataArray, productCode, providerId } = location.state;
  const [similarProducts, setSimilarProducts] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const loading = useSelector((s) => s.catalog.getProductDetailsLoading);
  const catalogData = useSelector((s) => s.catalog.productDetails);
  const [singleCatalogData, setSingleCatalogData] = useState(null);
  const [varientId, setVarientId] = useState("");

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };
  const closeCart = () => {
    setIsCartOpen(false);
  };

  const getCatalogueDetails = async (id, providerId) => {
    try {
      const body = { id: id, providerId: providerId }
      dispatch(getProductDetails(body));
    } catch (error) {
      console.log("getCatalogueDetails : ERROR", error);
    }
  };

  useEffect(() => {
    setVarientId(productCode)
    getCatalogueDetails(productCode, providerId)
  }, [])

  useEffect(() => {
    const itemIndex = cartItems?.filter((item) => item._id === catalogData?.variants[0]._id);
    if (itemIndex && itemIndex.length > 0) {
      setQuantity(itemIndex[0]?.quantity);
      let varientArry = catalogData?.variants?.map((v) => {
        const index = cartItems?.findIndex((item) => item?._id === v?._id);
        if (index !== -1) {
          return { ...v, quantity: cartItems[index].quantity };
        } else {
          return { ...v, quantity: 0 };
        }
      })
      const temp = { ...catalogData, variants: varientArry };
      setSingleCatalogData(temp)
      const tempVariant = temp?.variants?.find((item) => item._id === varientId || item._id === productCode)
      setSelectedVariant(tempVariant)
    } else {
      setSingleCatalogData(catalogData)
      const tempVariant = catalogData?.variants?.find((item) => item._id === varientId || item._id === productCode)
      setSelectedVariant(tempVariant)
    }
  }, [catalogData])

  useEffect(() => {
    if (catalogDataArray && catalogDataArray.length > 0 && singleCatalogData) {
      const similar = catalogDataArray.filter(
        (item) =>
          item.subCategoryId === singleCatalogData.subCategoryId &&
          item._id !== singleCatalogData._id
      );
      const tempArr = similar?.map((data) => {
        let variantArry = data.variants?.map((v) => {
          const index = cartItems?.findIndex((item) => item?._id === v?._id);
          if (index !== -1) {
            return { ...v, quantity: cartItems[index].quantity };
          } else {
            return { ...v, quantity: 1 };
          }
        })
        return { ...data, variants: variantArry };
      })
      const other = catalogDataArray.filter(
        (item) =>
          item._id !== singleCatalogData._id
      );
      const othertempArr = other?.map((data) => {
        let variantArry = data.variants?.map((v) => {
          const index = cartItems?.findIndex((item) => item?._id === v?._id);
          if (index !== -1) {
            return { ...v, quantity: cartItems[index].quantity };
          } else {
            return { ...v, quantity: 1 };
          }
        })
        return { ...data, variants: variantArry };
      })
      const index = cartItems?.findIndex((item) => item?._id === selectedVariant?._id);
      if (index !== -1) {
        setQuantity(cartItems[index].quantity)
      }
      setSimilarProducts(tempArr);
      setOtherProducts(othertempArr)
    }
  }, [catalogDataArray, singleCatalogData, cartItems]);

  const regex = /^P(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  const parseDuration = (duration) => {
    if (duration === 'P0D') {
      return "A Few Seconds"
    }
    const match = duration.match(regex);
    if (!match) return duration;
    const days = parseInt(match[1] || 0, 10);
    const hours = parseInt(match[2] || 0, 10);
    const minutes = parseInt(match[3] || 0, 10);
    const seconds = parseInt(match[4] || 0, 10);
    let formattedDuration = '';
    if (days) formattedDuration += `${days} day${days > 1 ? 's' : ''} `;
    if (hours) formattedDuration += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes) formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if (seconds) formattedDuration += `${seconds} second${seconds > 1 ? 's' : ''}`;
    return formattedDuration.trim();
  }

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalog, singleVariant, quantity) => {
    const itemIndex = cartItems.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleVariant?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleVariant?.availableQuantity);
    }
    else if (newQuantity > parseInt(singleCatalog?.maximumQuantity)) {
      notify(
        "success",
        `Maximum quanity available is ${singleCatalog?.maximumQuantity} for this product`
      );
    }
    else {
      if (quantity) {
        setQuantity(quantity)
      }
      let updatedCartItems;
      if (itemIndex !== -1) {
        updatedCartItems = cartItems.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        );
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        }
      } else {
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: itemId,
            quantity: newQuantity,
            catalogData: {
              ...singleCatalog, _id: singleVariant?._id, productName: singleVariant?.productName,
              productImages: singleVariant?.productImages, weight: singleVariant?.weight,
              weightUnit: singleVariant?.weightUnit,
              price: singleVariant?.price, description: singleVariant?.description
            },
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));
    };
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const setVariant = (variant) => {
    if (variant) {
      setSelectedVariant(variant)
      const itemInCart = cartItems.find(item => item?._id === variant?._id);
      setQuantity(itemInCart ? itemInCart?.quantity : 0);
    }
  }

  const onPressViewAll = (providerId) => {
    const encodedValue = encodeURIComponent(providerId);
    navigate(`/seller?pid=${encodedValue}`);
  }

  const ProductDetailSliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
    ]
  }
  const ProductDetailsSettings = {
    dots: false,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: true,
          vertical: false,
          verticalSwiping: false,
        }
      },
    ]
  }

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header cartItemLength={cartItems?.length} />
      {loading || !catalogData ?
        <div className="dotLoader">
          <Loader />
        </div>
        : <div className="productDetails-section">
          <section className="category-section">
            <div className="container">
              <div className="product-details-flex ">
                <div className="productLeft-section">
                  <div className="mobile-item-details">
                    <div onClick={() => { navigate(-1) }} className="mobile-heading">
                      <img alt="ArrowImage" src={BackArrow} style={{ maxWidth: '8px' }} />
                      Item Details
                    </div>
                  </div>
                  <div className="product-detail-slider">
                    <div className="product-slider-container">
                      {selectedVariant?.productImages?.length > 1 && (
                        <Slider
                          asNavFor={nav1}
                          ref={(slider2) => setNav2(slider2)}
                          slidesToShow={
                            selectedVariant?.productImages?.length > 3
                              ? 3
                              : selectedVariant?.productImages?.length
                          }
                          swipeToSlide={true}
                          focusOnSelect={true}
                          arrows={false}
                          centerMode={false}
                          vertical={true}
                          verticalSwiping={true}
                          infinite={false}
                          className="product-slider-as-nav"
                        >
                          {selectedVariant?.productImages?.map((data, index) => (
                            <div key={index} className="prodcutNavImage">
                              <img src={data}
                                alt="Product Catalog"
                                onError={(event) => {
                                  event.target.onerror = null;
                                  event.target.src = BrandLogo;
                                  event.target.classList.add("default-img");
                                }} />
                            </div>
                          ))}
                        </Slider>
                      )}
                      <Slider
                        {...ProductDetailsSettings}
                        slidesToShow={1}
                        slidesToScroll={1}
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                        className="product-slider"
                      >
                        {selectedVariant?.productImages?.map((data, index) => (
                          <div key={index} className="prodcutImage">
                            <img
                              src={data}
                              alt="Product Catalog"
                              onError={(event) => {
                                event.target.onerror = null; // Prevents potential infinite loop
                                event.target.src = BrandLogo;
                                event.target.classList.add("default-img");
                              }}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="product-details-right">
                  <div className="product-Details ">
                    <div className="product-Details-content">
                      <h3 className="product-details-title">{selectedVariant?.productName}</h3>
                      <div className="d-flex align-items-center gap-3">
                        <div className="subtext">Price:</div>
                        <span className="productRate">
                          ₹{selectedVariant?.price}
                        </span>
                        {selectedVariant?.maximumPrice &&
                          <span className="line-through">₹{selectedVariant?.maximumPrice}</span>
                        }
                      </div>
                      <div className="product-detail-block">
                        <div className="product-detail-flex">
                          <div className="subtext">Sold By :</div>
                          <div onClick={() => {
                            onPressViewAll(singleCatalogData?.providerId)
                          }} className="purpleText">{singleCatalogData?.store?.name ?? ""}
                            <img alt='Icon' src={ExternalLink} />
                          </div>
                        </div>
                        <div className="verticalLine" style={{ height: "16px" }}></div>
                        <div className="product-detail-flex">
                          <div className="subtext">Vender Source :</div>
                          <div className="GrayText">{singleCatalogData?.context?.bpp_id ?? ""}</div>
                        </div>
                      </div>
                      <div className="product-detail-block ">
                        <div className="product-detail-flex">
                          <div className="subtext">Manufactured date :</div>
                          <div className="GrayText">{singleCatalogData?.statutory_reqs_packaged_commodities?.month_year_of_manufacture_packing_import ?? "-"}</div>
                        </div>
                      </div>
                      <div className="product-detail-block ">
                        <div className="product-detail-flex">
                          <div className="subtext">Expiry date :</div>
                          <div className="GrayText">{"refer to back image"}</div>
                        </div>
                      </div>
                      <div className="product-detail-block ">
                        <div className="subtext">Quantity</div>
                        {singleCatalogData?.variants?.map((variant) => (
                          <button type="button"
                            key={variant?._id}
                            onClick={() => setVariant(variant)}
                            className={selectedVariant?._id === variant?._id ? "product-quantity active" : "product-quantity"}> {variant?.weight + " " + variant?.weightUnit}</button>
                        ))}
                      </div>
                    </div>
                    <div className="addtocart-selector">
                      {cartItems.some(item => item?._id === selectedVariant?._id) ?
                        <div className="addtocart-qty">
                          <div
                            className="addtocart-button button-down minus"
                            onClick={() => {
                              const qnty = quantity === 1 ? quantity : quantity - 1
                              const newQuantity = quantity === 1 ? 0 : quantity - 1;
                              updateCartItemQuantity(
                                selectedVariant?._id,
                                newQuantity,
                                singleCatalogData,
                                selectedVariant,
                                qnty
                              );
                            }}
                          >
                            <MinusIcon />
                          </div>
                          <input
                            disabled={true}
                            type="text"
                            className="addtocart-input"
                            value={quantity}
                          />
                          <div
                            className="addtocart-button button-up"
                            value={quantity}
                            onClick={() => {
                              const qnty = quantity ===
                                parseInt(selectedVariant?.availableQuantity)
                                ? quantity
                                : quantity + 1
                              const newQuantity = quantity + 1
                              updateCartItemQuantity(
                                selectedVariant?._id,
                                newQuantity,
                                singleCatalogData,
                                selectedVariant,
                                qnty
                              );
                            }}
                          >
                            <PlusIcon />
                          </div>
                        </div>
                        :
                        <div className="selector-btns">
                          <button type="button"
                            className="btn  btn-outline btn-cart"
                            onClick={() => {
                              updateCartItemQuantity(
                                selectedVariant?._id,
                                1,
                                singleCatalogData,
                                selectedVariant,
                                1
                              );
                            }}
                          >
                            Add To Cart
                          </button>
                        </div>
                      }
                      <div className="selector-btns">
                        <button type="button"
                          className="btn  btn-primary"
                          onClick={() => {
                            updateCartItemQuantity(
                              selectedVariant?._id,
                              quantity,
                              singleCatalogData,
                              selectedVariant
                            );
                            toggleCart(!isCartOpen);
                          }}
                        >
                          Buy Now
                        </button>
                      </div>
                      {cartItems.some(item => item?._id === selectedVariant?._id) &&
                        <div className="selector-btns">
                          <button type="button" className="btn  btn-outline"
                            onClick={() => {
                              updateCartItemQuantity(
                                selectedVariant?._id,
                                0,
                                singleCatalogData,
                                selectedVariant,
                                1
                              );
                            }}>Remove Item</button>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Product-detail-section">
            <div className="container">
              <div className="product-details-flex ">
                <div className="product-Details-desc ">
                  <h6 className="product-desc-title">Product Details</h6>
                  <div className="product-text">
                    <table className="productDesc-Details">
                      <div class="product-detail-flex product-detail-pd">
                        <div class="product-name">Product Name:</div>
                        <div class="product-name-text">{selectedVariant?.productName ?? ""}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Net Weight:</div>
                        <div class="GrayText">{`${selectedVariant?.weight ?? ""} ${selectedVariant?.weightUnit ?? ""}`}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Country Of Origin:</div>
                        <div class="GrayText">India</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">EAN / Barcode number:</div>
                        <div class="GrayText">{singleCatalogData?.code ?? "-"}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Time To Ship:</div>
                        <div class="GrayText"> {singleCatalogData?.timeToShip ? parseDuration(singleCatalogData?.timeToShip) : '-'}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Cancellable:</div>
                        <div class="GrayText">{singleCatalogData?.isCancellable ? "Yes" : "No"}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Returnable:</div>
                        <div class="GrayText">{singleCatalogData?.isReturnable ? "Yes" : "No"}</div>
                      </div>
                      {singleCatalogData?.isReturnable && (
                        <div class="product-detail-flex product-detail-pd">
                          <div class="subtext">Returnable Window:</div>
                          <div class="GrayText">{singleCatalogData?.returnWindowTime ? parseDuration(singleCatalogData?.returnWindowTime) : '-'}</div>
                        </div>
                      )}
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Manufacture Name:</div>
                        <div class="GrayText">{singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_name ?? "NA"}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Nutritional Information:</div>
                        <div class="GrayText">{singleCatalogData?.nutritionalInfo?.nutritional_info ?? "NA"}</div>
                      </div>
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Addictive Information:</div>
                        <div class="GrayText">{singleCatalogData?.nutritionalInfo?.additives_info ?? "NA"}</div>
                      </div>
                    </table>
                  </div>
                </div>
                <div className="product-desc-block">
                  <h6 className="product-desc-title">Description</h6>
                  <p className="product-desc">{singleCatalogData?.description}</p>
                </div>
              </div>
            </div>
          </section>
          <div className="product-Details-desc-tabs">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#Description">Description</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#ProductDetails">Product Details</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane container active" id="Description">
                <p className="product-desc">{singleCatalogData?.description}</p>
              </div>
              <div class="tab-pane container fade" id="ProductDetails">
                <div className="product-text">
                  <table className="productDesc-Details">
                    <div class="product-detail-flex product-detail-pd">
                      <div class="product-name">Product Name:</div>
                      <div class="product-name-text">{selectedVariant?.productName ?? ""}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Net Weight:</div>
                      <div class="GrayText">{`${selectedVariant?.weight ?? ""} ${selectedVariant?.weightUnit ?? ""}`}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Country Of Origin:</div>
                      <div class="GrayText">India</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">EAN / Barcode number:</div>
                      <div class="GrayText">{singleCatalogData?.code ?? "-"}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Time To Ship:</div>
                      <div class="GrayText"> {singleCatalogData?.timeToShip ? parseDuration(singleCatalogData?.timeToShip) : '-'}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Cancellable:</div>
                      <div class="GrayText">{singleCatalogData?.isCancellable ? "Yes" : "No"}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Returnable:</div>
                      <div class="GrayText">{singleCatalogData?.isReturnable ? "Yes" : "No"}</div>
                    </div>
                    {singleCatalogData?.isReturnable && (
                      <div class="product-detail-flex product-detail-pd">
                        <div class="subtext">Returnable Window:</div>
                        <div class="GrayText">{singleCatalogData?.returnWindowTime ? parseDuration(singleCatalogData?.returnWindowTime) : '-'}</div>
                      </div>
                    )}
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Manufacture Name:</div>
                      <div class="GrayText">{singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_name ?? "NA"}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Nutritional Information:</div>
                      <div class="GrayText">{singleCatalogData?.nutritionalInfo?.nutritional_info ?? "NA"}</div>
                    </div>
                    <div class="product-detail-flex product-detail-pd">
                      <div class="subtext">Addictive Information:</div>
                      <div class="GrayText">{singleCatalogData?.nutritionalInfo?.additives_info ?? "NA"}</div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <section className="other-product-section">
            <div className="container">
              {otherProducts &&
                otherProducts?.length > 0 &&
                (
                  <div className="other-products-container">
                    <div className="Similar-product-title">
                      <h3 className="Similar-product-heading">{`Other Products Sold by ${singleCatalogData?.store?.name ?? "this store"}`}</h3>
                      <div>
                        <button type="button" className="similar-product-view"
                          onClick={() => {
                            onPressViewAll(otherProducts?.[0]?.providerId)
                          }}>View All
                          <img alt='Icon' src={Rightarrow} className="mobile-right-arrow" />
                        </button>
                      </div>
                    </div>
                    <Slider {...ProductDetailSliderSettings} >
                      {otherProducts && otherProducts.length > 0 ? (
                        otherProducts?.map((item, index) => (
                          <div>
                            <div className="store-product-item">
                              <div
                                className="store-product-img"
                                onClick={() => {
                                  setVarientId(item?._id)
                                  getCatalogueDetails(item?._id, item?.providerId)
                                  // setSingleCatalogData(item);
                                  // setSelectedVariant(item?.variants[0])
                                  // setQuantity(item?.variants?.[0]?.quantity);
                                  goToTop();
                                }}
                              >
                                <img
                                  src={item?.productImages?.[0]}
                                  alt="Product Catalog"
                                  className="categorys-imgs"
                                  onError={(event) => {
                                    if (event.target.src === item?.productImages?.[0] && item?.productImages?.[1]) {
                                      event.target.src = item.productImages[1];
                                    }
                                    else {
                                      event.target.onerror = null;
                                      event.target.src = BrandLogo;
                                      event.target.classList.add('default-img')
                                    }
                                  }}
                                />
                              </div>
                              <div className="store-content">
                                <div className="store-content-block">
                                  <div className="store-product-title">
                                    {item?.variants?.[0]?.productName}
                                  </div>
                                  <div class="store-product-para">{`SELLER: ${singleCatalogData?.store?.name ?? ""}`}</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="store-product-rate">
                                    <span className="purpleText">
                                      {`₹ ${item?.variants?.[0]?.price ?? ""}`}
                                    </span>
                                    <span className="line-through">{`₹ ${item?.variants?.[0]?.maximumPrice ?? ""}`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Loading products...</p>
                      )}
                    </Slider>
                  </div>
                )}
            </div>
          </section>
          <section className="similar-product-section">
            <div className="container">
              {similarProducts &&
                similarProducts?.length > 0 &&
                (
                  <div className="similar-products-container">
                    <div className="Similar-product-title">
                      <h3 className="Similar-product-heading">Similar Products</h3>
                      <div>
                        <button type="button" className="similar-product-view"
                          onClick={() => {
                            onPressViewAll(similarProducts?.[0]?.providerId)
                          }}>View All
                          <img alt='Icon' src={Rightarrow} className="mobile-right-arrow" />
                        </button>
                      </div>
                    </div>
                    <Slider {...ProductDetailSliderSettings} >
                      {similarProducts && similarProducts.length > 0 ? (
                        similarProducts?.map((item, index) => (
                          <div>
                            <div className="store-product-item">
                              <div
                                className="store-product-img"
                                onClick={() => {
                                  setVarientId(item?._id)
                                  getCatalogueDetails(item?._id, item?.providerId)
                                  // setSingleCatalogData(item);
                                  // setSelectedVariant(item?.variants?.[0])
                                  // setQuantity(item?.variants?.[0]?.quantity);
                                  goToTop();
                                }}
                              >
                                <img
                                  src={item?.productImages?.[0]}
                                  alt="Product Catalog"
                                  className="categorys-imgs"
                                  onError={(event) => {
                                    if (event.target.src === item?.productImages?.[0] && item?.productImages?.[1]) {
                                      event.target.src = item.productImages[1];
                                    }
                                    else {
                                      event.target.onerror = null;
                                      event.target.src = BrandLogo;
                                      event.target.classList.add('default-img')
                                    }
                                  }}
                                />
                              </div>
                              <div className="store-content">
                                <div className="store-content-block">
                                  <div className="store-product-title">
                                    {item?.variants?.[0]?.productName}
                                  </div>
                                  <div class="store-product-para">{`SELLER: ${singleCatalogData?.store?.name ?? ""}`}</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="store-product-rate">
                                    <span className="purpleText">
                                      {`₹ ${item?.variants?.[0]?.price ?? ""}`}
                                    </span>
                                    <span className="line-through">{`₹ ${item?.variants?.[0]?.maximumPrice ?? ""}`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Loading similar products...</p>
                      )}
                    </Slider>
                  </div>
                )}
            </div>
          </section>
        </div>}
      <Offcanvas isOpen={isCartOpen} toggle={closeCart} direction="end" backdrop={ondcSelectstatus === "loading" ? "static" : true} className="offcanvas offcanvas-end sidebaroffcanvas">
        <CartHeader
          isCartOpen={isCartOpen}
          closeCart={closeCart}
        />
      </Offcanvas>
    </>
  );
};
export default ProductDetails;