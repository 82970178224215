import React from "react";

const ShoppingCart = (props) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        {...props}
      >
        <g
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          clipPath="url(#clip0_11128_13480)"
        >
          <path d="M15 16.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M6.75 16.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M.75.75h3l2.01 10.043A1.5 1.5 0 0 0 7.26 12h7.29a1.5 1.5 0 0 0 1.5-1.207l1.2-6.293H4.5"></path>
        </g>
        <defs>
          <clipPath id="clip0_11128_13480">
            <path fill="currentColor" d="M0 0h18v18H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
}

export default ShoppingCart;
