import React, { useCallback, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../components/svgicons/searchicon";
import ShoppingCart from "../../components/svgicons/ShoppingCart";
import { ToastContainer } from "react-toastify";
import { Modal, ModalFooter, Offcanvas } from "reactstrap";
import LogoutLogo from "../../images/logout-logo.png";
import BrandLogo from "../../images/kikoimg.svg";
import ManageAddress from "../ManageAddress/component";
import { fetchCatelogues, updateCartItem } from "../../slices/catalogSlice";
import { GET_PROFILE, UPDATE_PROFILE } from "../../api/apiList";
import CartHeader from "../../components/common/CartHeader";
import Login from "../Login/component";
import PreloginAddress from "../ManageAddress/preloginaddress";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../slices/userSlice";
import "./styles.scss";
import Order from "../Order/component";
import AccountIcon from "../../images/account-icon.svg";
// import { debounce } from "lodash";
import pin from "../../images/pin.svg"
import ProfileImg from "../../images/profile-icon-gray.svg"
import Downarrow from "../../images/down-arrow-black.svg";
import YourAddress from "../YourAddress/component";
import API2 from "../../api/indexv2";
import { notify } from "../../components/common/utils";
import axios from "axios";
let cancelToken;
const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [YouraddressModal, setYourAddressModal] = useState(false);
  const [logout, setlogout] = useState(false);
  const [showPreLoginAddessModal, setShowPreLoginAddessModal] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const addressData = JSON.parse(localStorage.getItem("addressData"));
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const isCheckoutPage = window.location.href.includes("buyer-checkout");
  const [orderHistory, setOrderhistory] = useState(false)
  const [yourAddress, setYouraddress] = useState(false)
  const [Account, setAccount] = useState(false)
  const [users, setUsersData] = useState("")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [profile, setProfileData] = useState(null)
  const handleGenderChange = (e) => setGender(e.target.value);
  const token = useSelector((s) => s.user.token);
  const ondcSelectstatus = useSelector((s) => s.ondcPreorder.ondcSelectstatus);

  const userLogout = () => {
    dispatch(updateCartItem([]))
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("cart");
    localStorage.removeItem("sellerDetails");
    localStorage.removeItem("slug");
    if (defaultAddress?.address) {
      delete defaultAddress?.address;
      localStorage.setItem("defaultAddress", JSON.stringify(defaultAddress));
    }
    dispatch(logoutUser())
    navigate("/")
    setlogout(false)
    // window.location.reload();
  };

  const updateProfile = async () => {
    if (!name || !email || !gender) {
      notify('error', "Please fill all the fields");
      return;
    }

    try {
      const body = {
        userId: user.userId,
        name,
        email,
        gender,
      };
      const response = await API2.post(UPDATE_PROFILE, body);
      if (response.data) {
        notify('success', "Updated Successfully")
        let user = response.data.data;
        setUsersData(user);

      }
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };
  const getProfile = async () => {
    try {
      const body = {
        userId: user.userId,
      };
      const response = await API2.post(GET_PROFILE, body);
      if (response.data) {
        let user = response.data.user;
        setProfileData(user);
        setName(user.name || "");
        setEmail(user.email || "");
        setGender(user.gender || "");
      }
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };



  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //   setIsDropdownOpen(false);
    // }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  // const handleButtonClick = () => {
  //   if (token) {
  //     toggleDropdown();
  //     setIsDropdownOpen(!isDropdownOpen);
  //   } else {
  //     handleLoginClick();
  //   }
  // };


  // const getZipcodeFromAddress = () => {
  //   if (defaultAddress) {
  //     setZipcode(defaultAddress.zipcode);
  //   }
  // };
  const handleLoginClick = () => {
    if (!token) {
      setModalIsOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
    console.log(!isCartOpen, "Toggling cart state...");
  };
  const closeCart = () => {
    setIsCartOpen(false);
  };
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("user"));
    if (userId) {
      // dispatch(fetchAddress({ userId }));
    }
  }, [dispatch]);
  useEffect(() => {
    const getZipcodeFromAddress = () => {
      if (defaultAddress) {
        setZipcode(defaultAddress.zipcode);
        setCity(defaultAddress.address?.city);
      }
    };
    const addressData = JSON.parse(localStorage.getItem("addressData"));
    if (addressData && addressData?.zipcode) {
      setZipcode(addressData?.zipcode);
      setCity(addressData?.address.city)
    } else {
      getZipcodeFromAddress();
    }
  }, [addressData?.zipcode, defaultAddress]);

  useEffect(() => {
    if (!defaultAddress && !token) {
      setShowPreLoginAddessModal(true);
    }
  }, [defaultAddress, token]);

  const user = JSON.parse(localStorage.getItem("user"));

  // const getUserFromLocalStorage = () => {
  //   try {
  //     return JSON.parse(localStorage.getItem("user") || "");
  //   } catch (error) {
  //     return null;
  //   }
  // };

  const getSellerDetails = () => {
    try {
      return JSON.parse(localStorage.getItem("sellerDetails") || "");
    } catch (error) {
      return null;
    }
  };

  const sellerDetails = getSellerDetails();

  // const userData = getUserFromLocalStorage();

  const [catalogueData, setCatalogData] = useState([]);

  // let cartItemsLength = props.cartItemLength;
  let cartItems = JSON.parse(localStorage.getItem("cart"));
  let totalAmount = 0;
  if (cartItems) {
    cartItems.forEach((item) => {
      totalAmount += item?.quantity * item?.catalogData.price;
    });
  }
  let cartItemsLength = cartItems?.length || 0;
  const defaultLat = parseFloat(defaultAddress?.latitude);
  const defaultLon = parseFloat(defaultAddress?.longitude);
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };


  const callSearchApi = async (e = "") => {

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    const location = `${defaultLat},${defaultLon}`;
    if (e?.target?.value != "") {
      dispatch(fetchCatelogues({
        search: e.target?.value,
        location: location,
        areaCode: defaultAddress.zipcode,
        token: cancelToken.token,
        // sellerId: sellerDetails?._id,
      }))
    }
  }
  const optimizedUserFn = useCallback(debounce(callSearchApi), []);

  // const onChange = debounce((searchText) => {
  //   console.log("HEADER : SEARCH DATA", searchText);
  //   const location = `${defaultLat},${defaultLon}`;
  //   ;
  // }, 1000);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <header className="headerSection">
        <ToastContainer
          autoClose={300}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="right"
          position="top-right"
        />
        <div className="headermain">
          <div className="header-left">
            <div className="logo-address-flex">
              <a href="/" className="brand-logo">
                <img src={BrandLogo} alt="Logo" className="logo-icon" />
              </a>
              <div className="search-top">
                <div className="searchBar">
                  <div className="searchInput">
                    <input
                      type="text"
                      autoFocus={window.location.pathname === "/mobile-search-result"}
                      placeholder="Search for Products, Brand and Shops"
                      onChange={(e) => {
                        // onChange(e.target.value);
                        optimizedUserFn(e)
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (window.location.pathname !== "/mobile-search-result") {
                          navigate("/mobile-search-result");
                        }
                      }}
                    />
                    <button type="button">
                      <SearchIcon alt="Search Icon" />
                    </button>
                  </div>
                  {catalogueData && catalogueData?.length > 0 ? (
                    <div className="searchDropdown">
                      {catalogueData?.map((data) => {
                        return (
                          <p
                            onClick={() => {
                              !props.func &&
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: {},
                                    productCode: data?._id ?? "",
                                    providerId: data?.providerId ?? ""
                                  },
                                });
                              props.func && props.func(data);
                              props.func && setCatalogData([]);
                            }}
                          >
                            {data.productName}
                          </p>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="header-right">
            <div className="headerRight">
              <div
                className="buyer-address"
                onClick={() => {
                  if (!token) {
                    setShowPreLoginAddessModal(true)
                  } else {
                    setYourAddressModal(true);
                  }
                }}
              >
                <img src={pin} alt="location" className="location-icon" />
                {zipcode && zipcode.length !== 0 && (
                  <div className="pincode-text">
                    <div className=" buyer-address-pincode">{`${city ? city + " -" : ""}`}  {zipcode}</div>
                  </div>
                )}
              </div>
              <div className="verticalLine"></div>
              <div className="loginHeading">
                <div className="account-dropdown  account-dropdown-show" ref={dropdownRef}>
                  <button
                    className={token ? "btn " : "btn"}
                    type="button"
                    onClick={() => {
                      if (token) {
                        toggleDropdown();
                      } else {
                        handleLoginClick();
                      }
                    }}
                  >
                    {token ?
                      <div className="profile-flex">
                        <div className="profile-circle">
                          <img src={ProfileImg} alt="ProfileImage" />
                        </div>
                        <div className="profile-name">My Account</div>
                      </div>
                      : "Login"}
                  </button>
                  {isDropdownOpen &&
                    <ul
                      className="account-menu"
                    >
                      <li className="account-dropdown__account-info"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasAccount"
                        aria-controls="offcanvasRightAccount"
                        onClick={() => {
                          setAccount(true)
                          getProfile()
                        }}>
                        <div className="account-info--heading">My Account</div>
                        <div className="account-number">{user?.phone}</div>
                      </li>
                      <li>
                        <div
                          className="menu-logout-list"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasAddress"
                          aria-controls="offcanvasRightaddress"
                          onClick={() => {
                            setYouraddress(true)
                          }}
                        >
                          Your Addresss
                        </div>
                        <div
                          className="menu-logout-list"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasOrder"
                          aria-controls="offcanvasRightOrder"
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setOrderhistory(true)
                            setIsDropdownOpen(false);
                          }}
                        >
                          Order History
                        </div>
                        <div
                          type="button"
                          data-bs-dismiss="offcanvas"
                          className="menu-logout-list"
                          onClick={() => {
                            setlogout(true);
                          }}>
                          Logout
                        </div>
                      </li>
                    </ul>}
                </div>
              </div>
              <div className="verticalLine"></div>
              {!isCheckoutPage && (
                <div className="buyer-cart">
                  <button
                    className=" left--icon p-0"
                    type="button"
                    onClick={() => {
                      toggleCart();
                    }}
                  >
                    <div className="profileDetails">
                      <div style={{ position: "relative" }}>
                        <ShoppingCart className="Icon" />
                        {cartItemsLength && cartItemsLength > 0 ? (
                          <div className="cartCounter">{cartItemsLength}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      My Cart
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
          <Modal
            isOpen={YouraddressModal}
            size="lg"
            toggle={() => setYourAddressModal(false)}
          >
            <div className="addressmodalBlock">
              <YourAddress setZipcode={setZipcode} setYourAddressModal={setYourAddressModal} />
            </div>
          </Modal>
        </div>
        {modalIsOpen && (
          <Login
            isOpenFromCart={false}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
          ></Login>
        )}
        {showPreLoginAddessModal && (
          <PreloginAddress
            showPreLoginAddessModal={showPreLoginAddessModal}
            setShowPreLoginAddessModal={setShowPreLoginAddessModal}
          ></PreloginAddress>
        )}
      </header>
      <div className="Mobile-header">
        <div className="mobileHeaderMain">
          <div className="header-left">
            <div className="logo-mobile-flex">
              <a href="/" className="brand-logo">
                <img src={BrandLogo} alt="Logo" className="logo-icon" />
              </a>
              <div
                className="buyer-address"
                onClick={() => {
                  if (!token) {
                    setShowPreLoginAddessModal(true)
                  } else {
                    setYourAddressModal(true);
                  }
                }}
              >
                <img src={pin} alt="location" className="location-icon" />
                {zipcode && zipcode.length !== 0 && (
                  <div className="pincode-text">
                    <div className=" buyer-address-pincode">{city} - {zipcode}</div>
                    <div>
                      <img alt="Icon" src={Downarrow} style={{ maxWidth: '12px' }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="header-right">
            <div className="d-flex align-items-center gap-3 justify-content-end">
              <div className="loginHeading">
                <div className="account-dropdown  account-dropdown-show" ref={dropdownRef}>
                  <button
                    className={token ? "btn " : "btn"}
                    type="button"
                    onClick={() => {
                      if (token) {
                        toggleDropdown();
                      } else {
                        handleLoginClick();
                      }
                    }}
                  >
                    {token ?
                      <div className="profile-flex">
                        <div className="account-icon">
                          <img src={AccountIcon} alt="Icon" />
                        </div>
                      </div>
                      : "Login"}
                  </button>
                  {isDropdownOpen &&
                    <ul
                      className="account-menu"
                    >
                      <li className="account-dropdown__account-info"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasAccount"
                        aria-controls="offcanvasRightAccount"
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setAccount(true)
                        }}>
                        <div className="account-info--heading">My Account</div>
                        <div className="account-number">{user?.phone}</div>
                      </li>
                      <li>
                        <div
                          className="menu-logout-list"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasAddress"
                          aria-controls="offcanvasRightaddress"
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setYouraddress(true)
                          }}
                        >
                          Your Addresss
                        </div>
                        <div
                          className="menu-logout-list"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasOrder"
                          aria-controls="offcanvasRightOrder"
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setOrderhistory(true)
                          }}
                        >
                          Order History
                        </div>
                        <div
                          type="button"
                          data-bs-dismiss="offcanvas"
                          className="menu-logout-list"
                          onClick={() => {
                            setlogout(true);
                            setIsDropdownOpen(false);
                          }}
                        >
                          Logout
                        </div>
                      </li>
                    </ul>}
                </div>
              </div>
              {!isCheckoutPage && (

                <div className="buyer-cart">
                  <button
                    className=" left--icon p-0"
                    type="button"
                    onClick={() => {
                      toggleCart();
                    }}
                  >
                    <div className="profileDetails">
                      <div style={{ position: "relative" }}>
                        <ShoppingCart className="Icon" />
                      </div>
                      Cart
                    </div>
                  </button>
                  <button
                    className=" left_icon "
                    type="button"
                    onClick={() => {
                      toggleCart();
                    }}
                  >
                    <div className="profileDetails">
                      <div style={{ position: "relative" }}>
                        <ShoppingCart className="Icon" />
                      </div>
                    </div>
                    {cartItemsLength && cartItemsLength > 0 ? (
                      <div>{`${cartItemsLength} Item`}
                        <span style={{ display: 'block' }}>{`₹${totalAmount}`}</span>
                      </div>
                    ) : (
                      <span>My Cart</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="search-top">
          <div className="searchBar">
            <div className="searchInput">
              <button type="button">
                <SearchIcon alt="Search Icon" />
              </button>
              <input
                type="text"
                autoFocus={window.location.pathname === "/mobile-search-result"}
                placeholder="Search for Products, Brand and Shops"
                onChange={(e) => {
                  // onChange(e.target.value);
                  optimizedUserFn(e)
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/mobile-search-result") {
                    navigate("/mobile-search-result");
                  }
                }}
              />
            </div>
            {catalogueData && catalogueData?.length > 0 ? (
              <div className="searchDropdown">
                {catalogueData?.map((data) => {
                  return (
                    <p
                      onClick={() => {
                        !props.func &&
                          navigate("/productDetails", {
                            state: {
                              catalogDataArray: {},
                              productCode: data?._id ?? "",
                              providerId: data?.providerId ?? ""
                            },
                          });
                        props.func && props.func(data);
                        props.func && setCatalogData([]);
                      }}
                    >
                      {data.productName}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>

        </div>
      </div>
      <Modal
        isOpen={logout}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setlogout(false);
        }}
        centered
        className="clearcartmodal"
      >
        <div className="confirmLogout">
          <img src={LogoutLogo} alt="Logout" />
          <h1 className="confirm-subtitle">
            Thank you for visiting {sellerDetails?.storeName}
          </h1>
        </div>
        <ModalFooter className="border-0 flex-column">
          <h5 className="confirmtitle">Are you sure you want to logout?</h5>
          <div className="d-flex justify-content-center gap-2">
            <button
              type="button"
              onClick={() => {
                setlogout(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              onClick={() => {
                userLogout();
              }}
            >
              Yes
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Offcanvas isOpen={isCartOpen} toggle={closeCart} direction="end" backdrop={ondcSelectstatus === "loading" ? "static" : true} className="offcanvas offcanvas-end sidebaroffcanvas">
        <CartHeader
          isCartOpen={isCartOpen}
          closeCart={closeCart}
        />
      </Offcanvas>

      <div
        className="offcanvas offcanvas-end ProfileSidebar"
        tabIndex="-1"
        id="offcanvasAccount"
        sidebaroffcanvas
        aria-labelledby="offcanvasRightLabelAccount"
      // data-bs-backdrop={isCartOpen?"false":"true"}
      // data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <div
            className="profile-title"
          >
            Profile
          </div>

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={closeCart}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="profile-body">
            <div className="personal-details-heading">Personal Details</div>
            <div className="profile-form">
              <form className="form">
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="gender-heading">Gender</div>
            <div className="gender-list">
              <div className="gender-item">
                <input
                  type="radio"
                  id="Male"
                  name="gender"
                  value="Male"
                  checked={gender === "Male"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="Male">
                  <span className="gender-text">Male</span>
                </label>
              </div>
              <div className="gender-item">
                <input
                  type="radio"
                  id="Female"
                  name="gender"
                  value="Female"
                  checked={gender === "Female"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="Female">
                  <span className="gender-text">Female</span>
                </label>
              </div>
            </div>

            <button
              className="upload-profile-btn"
              type="button"
              onClick={updateProfile}>Update Profile</button>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end orderSidebar "
        tabIndex="-1"
        orderSidebar
        id="offcanvasOrder"
        aria-labelledby="offcanvasRightLabelOrder"
      >
        <div className="offcanvas-header">
          <p className="mb-0 profile-info-title">Order</p>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {orderHistory && token &&
            <Order />
          }
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end orderSidebar "
        orderSidebar
        id="offcanvasAddress"
        aria-labelledby="offcanvasRightLabelAddress"
      >
        <div className="offcanvas-body p-0" style={{ background: "#fff" }}>
          {yourAddress && token &&
            <ManageAddress setZipcode={setZipcode}
              setYourAddressModal={setYourAddressModal}
              isInHeader={true} />
          }
        </div>
      </div>
    </>
  );
};
export default Header;