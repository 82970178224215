import React from "react";

const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    fill="none"
    viewBox="0 0 16 18"
    {...props}
  >
    <path
      fill="#CE0606"
      d="M3 18q-.824 0-1.412-.587A1.93 1.93 0 0 1 1 16V3H0V1h5V0h6v1h5v2h-1v13q0 .824-.588 1.413A1.93 1.93 0 0 1 13 18zM13 3H3v13h10zM5 14h2V5H5zm4 0h2V5H9z"
    ></path>
  </svg>
);

export default DeleteIcon;
