import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header/component";
import API2 from "../../api/indexv2";
import { GET_CATEGORIES } from "../../api/apiList";
import { get, debounce } from "lodash";
import Loader from "../../components/common/loader";
import { ToastContainer } from "react-toastify";
import QrCode from "../../images/qr-code.png";
import ProfileStore from "../../images/profile-store.svg";
import ProfileLocation from "../../images/profile-location.svg";
import { Modal, ModalFooter } from "reactstrap";
import LogoutLogo from "../../images/logout-logo.png";
import Login from "../Login/component";
import { fetchSellersCatalog, updateCartItem } from "../../slices/catalogSlice";
import { getDistance, isStoreOpen, formatAndLocalizeText } from "../../components/common/utils";
import All from "../../images/All-catagory.png";
import "./styles.scss";
import { notify } from "../../components/common/utils";
import StoretimingModal from "../../components/common/StoreTimingModal";
import { DownArrow, MinusIcon, PlusIcon } from "../../components/svgicons";
import BrandLogo from "../../images/kikoimg.svg";
import QrModal from "./qrModal";
import Pagination from 'react-bootstrap/Pagination';
import NoProductFoung from "../../images/v2-images/no-product-found.svg"

let sortValue = -1;

const SellerComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let page1 = 1;
  const catalogues = useSelector((s) => s.catalog.catalogues);
  const catalogueStatus = useSelector((s) => s.catalog.catalogueStatus);
  const catalogueCount = useSelector((s) => s.catalog.catalogueCount);
  const cartItems = useSelector((s) => s.catalog.cartItems);
  const [qrPopUp, setQrPopUp] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [catalogDataTemp, setCatalogDataTemp] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [storeDetails, setStoreDetail] = useState({});
  const [logout, setlogout] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedSubcategory, setSelectedSubcategory] = useState(searchParams.get("category") || "All");
  const category = location?.state?.category || "All";
  const [storeTiming, setStoreTiming] = useState(false)

  useEffect(() => {
    catalogs(false, page1);
  }, [cartItems]);

  let sellerDetails = {};
  try {
    sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));
  } catch (error) {
    sellerDetails = null;
  }

  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };
  const queryParams = getQueryParams(location.search);
  const slug = queryParams.get('pid');
  const encodedValue = encodeURIComponent(slug);
  localStorage.setItem("slug", `/seller?pid=${encodedValue}`)

  const getCategoriesData = async () => {
    try {
      const response = await API2.post(GET_CATEGORIES);
      if (response.data) {
        const selectedCategory =
          category === "All"
            ? response?.data?.data
            : response?.data?.data.filter((subcategory) => {
              return category === subcategory?.title;
            });
        let tempSub = [];
        storeDetails?.subCategoryIds?.forEach((sellerCategory) => {
          selectedCategory.forEach((item) => {
            const tempSub1 = item?.subCategories.filter((subcategory) => {
              return sellerCategory === subcategory?.title;
            })[0];
            if (tempSub1) {
              tempSub.push(tempSub1);
            }
          });
        });
        setCategoriesData(tempSub);
      }
    } catch (error) {
      console.log("HOME : ERROR CATEGORIES ", error);
    }
  };

  useEffect(() => {
    if (searchParams.get("category")) {
      const categoryParam = searchParams.get("category");
      setSelectedSubcategory(categoryParam);
      setCatalogDataTemp([]);
      fetchCatalogData({ userId: slug, subCategoryId: categoryParam, page: page1, sortValue: sortValue });
    }
  }, [searchParams]);

  useEffect(() => {
    if (storeDetails && Object.keys(storeDetails).length > 0 && categoriesData.length === 0) {
      getCategoriesData();
      setIsOpen(isStoreOpen(storeDetails));
      setCatalogDataTemp([]);
      const categoryParam = searchParams.get("category") || selectedSubcategory;
      fetchCatalogData({ userId: slug, subCategoryId: categoryParam, page: 1, sortValue: sortValue });
    }
  }, [storeDetails, searchParams]);

  useEffect(() => {
    catalogs(true, page1);
  }, [catalogues]);

  useEffect(() => {
    setStoreDetail(sellerDetails);
    page1 = 1;
  }, [catalogueCount]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [catalogueCount]);

  const handleSortChange = (value) => {
    if (value === "PriceLowToHigh") {
      sortValue = 0;
    } else if (value === "PriceHighToLow") {
      sortValue = 1;
    } else {
      sortValue = -1;
    }
    setCatalogDataTemp([]);
    fetchCatalogData({ userId: slug, subCategoryId: selectedSubcategory, page: 1, sortValue: sortValue });
  };

  const fetchCatalogData = async (payload) => {
    try {
      dispatch(fetchSellersCatalog(payload));
    } catch (error) {
      console.log("HOME : ERROR fetchCatalogData ", error);
    }
  };

  const handleSubcategoryClick = (subcategory, page) => {
    if (subcategory !== selectedSubcategory) {
      setSelectedSubcategory(subcategory);
      setCatalogDataTemp([]);
      fetchCatalogData({ userId: slug, subCategoryId: subcategory, page: page, sortValue: sortValue });
      setSearchParams({ pid: slug, category: subcategory });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= documentHeight - 100) { // Trigger when near bottom
      const pageCount = Math.ceil(catalogueCount / 20);
      if (page1 < pageCount && catalogueStatus !== "loading") {
        debounce_fun(catalogueCount, catalogDataTemp);
      }
    }
  };

  let debounce_fun = debounce((catalogueCount, catalogDataTemp) => {
    page1 += 1;
    if (catalogueStatus !== "loading") {
      fetchCatalogData({
        userId: slug,
        subCategoryId: selectedSubcategory,
        page: page1,
        sortValue: sortValue
      })
    };
  }, 700);

  const catalogs = (isFromScroll, page) => {
    if (!catalogues) {
      return;
    }
    let temp = catalogDataTemp;
    const newData = isFromScroll ? catalogues.filter(item => !temp.some(existingItem => existingItem._id === item._id)) : catalogues;
    const tempData = isFromScroll ? temp.concat(newData) : temp;
    setCatalogDataTemp([])
    const data = tempData.map((data) => {
      const index = cartItems?.findIndex((item) => item?._id === data?._id);
      if (index !== -1) {
        return { ...data, quantity: cartItems[index].quantity };
      } else {
        return { ...data, quantity: 1 };
      }
    });
    setCatalogDataTemp([...data]);
  };

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems?.findIndex((item) => item._id === itemId);
    if (newQuantity >= parseInt(singleCatalogData?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleCatalogData?.availableQuantity);
    }
    else if (newQuantity > parseInt(singleCatalogData?.maximumQuantity)) {
      notify(
        "success",
        `Maximum quanity available is ${singleCatalogData?.maximumQuantity} for this product`
      );
    }
    else {
      let updatedCartItems;
      if (itemIndex !== -1) {
        // Create a new array with the updated item
        updatedCartItems = cartItems?.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        );
        // If the new quantity is 0, remove the item
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        }
      } else {
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: singleCatalogData._id,
            quantity: newQuantity,
            catalogData: singleCatalogData,
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));
    };
  }

  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const sellerLocation = sellerDetails?.locations[0]?.gps?.split(",");
  const sellerLat = parseFloat(sellerLocation?.[0]);
  const sellerLon = parseFloat(sellerLocation?.[1]);
  const sellerPhoto = sellerDetails?.photo || "";
  const defaultLat = parseFloat(defaultAddress?.latitude);
  const defaultLon = parseFloat(defaultAddress?.longitude);
  const distance = getDistance(defaultLat, defaultLon, sellerLat, sellerLon);

  const userLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("cart");
    localStorage.removeItem("sellerDetails");
    localStorage.removeItem("slug");
    if (defaultAddress?.address) {
      delete defaultAddress?.address;
      localStorage.setItem("defaultAddress", JSON.stringify(defaultAddress));
    }
    navigate("/")
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const setVariant = (variantId, data) => {
    if (variantId) {
      const singleVariant = data?.variants.find((item) => { return item?._id === variantId })
      const index = cartItems?.findIndex((item) => item?._id === variantId);
      const tempCatalogs = catalogDataTemp.map((catalog) => {
        if (catalog?._id === data?._id) {
          return {
            ...catalog,
            _id: singleVariant?._id,
            productName: singleVariant?.productName,
            productImages: singleVariant?.productImages,
            weight: singleVariant?.weight,
            weightUnit: singleVariant?.weightUnit,
            price: singleVariant?.price,
            maximumPrice: singleVariant?.maximumPrice,
            // description: singleVariant?.description,
            quantity: index !== -1 ? cartItems[index].quantity : 1
          }
        } else {
          return { ...catalog }
        }
      })
      setCatalogDataTemp(tempCatalogs)
      setSelectedVariant(variantId)
    }
  }
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <div className="Profile--section">
        <div className="profile-flex">
          <div className="profile-flex-left">
            <div className="profile-left">
              <div className="profile_img">
                <img
                  src={sellerPhoto}
                  alt="Seller Profile"
                  onError={(event) => {
                    event.target.onerror = null;
                    event.target.src = BrandLogo;
                    event.target.classList.add('default-img')
                  }} />
              </div>
              <div className="w-100">
                <h4 className="profile-text-heading">{get(storeDetails, "name", "")}</h4>
                <p className="profile-text-para">
                  <span>
                    {get(storeDetails, "locations[0].address.city", "")}
                  </span>
                  <span> </span>
                  <span>
                    {get(storeDetails, "locations[0].address.state", "")}
                  </span>
                  <span> </span>
                  <span>
                    {get(
                      storeDetails,
                      "locations[0].address.area_code",
                      ""
                    )}
                  </span>
                </p>
                <div className="d-flex gap-3 align-items-center">
                  <h6 className="profile-text-sub-para">
                    <img
                      className="icon"
                      alt="icon"
                      src={ProfileLocation}
                    />
                    {distance.toFixed(2)} Km
                  </h6>
                  <div className="select-store">
                    <img className="icon" alt="icon" src={ProfileStore} />
                    <button type="button" className="select-store" onClick={() => setStoreTiming(true)}>
                      {isOpen ? "OPEN" : "CLOSED"}
                      <DownArrow />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-flex-right">
            <div className="profile-right">
              <div className="qr-code-block">
                <img src={QrCode} alt="qr" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <section className="home-category-section">
        <div className="Mobile-category-flex">
          <h1 className="categorysHeading">{selectedSubcategory}

          </h1>
          <div className="sort-by">
            <label className="form-label">Sort By</label>
            <select className="form-select" onChange={(e) => handleSortChange(e.target.value)} >
              <option value="Relevance">Relevance (a-z)  </option>
              <option value="PriceLowToHigh">Price: Low to High</option>
              <option value="PriceHighToLow">Price: High to Low</option>
            </select>
          </div>
        </div>
        <div className="category-main">
          <div className="categorySidebar">
            <div className="category--left">
              <div className="Profile--section-desktop">
                <div className="category-profile-flex">
                  <div className="profile_img">
                    <img
                      src={sellerPhoto}
                      alt="Seller Profile" onError={(event) => {
                        event.target.onerror = null;
                        event.target.src = BrandLogo;
                        event.target.classList.add('default-img')
                      }} />
                  </div>
                  <div className="w-100">
                    <h4 className="profile-text-heading">{get(storeDetails, "name", "")}</h4>
                    <p className="profile-text-para">
                      <span>
                        {get(
                          storeDetails,
                          "locations[0].address.city",
                          ""
                        )}
                      </span>
                      <span> </span>
                      <span>
                        {get(
                          storeDetails,
                          "locations[0].address.state",
                          ""
                        )}
                      </span>
                      <span> </span>
                      <span>
                        {get(
                          storeDetails,
                          "locations[0].address.area_code",
                          ""
                        )}
                      </span>
                    </p>
                    <div className="product-distance-flex">
                      <div className="profile-text-sub-para">
                        <img
                          className="icon"
                          alt="icon"
                          src={ProfileLocation}
                        />
                        {distance.toFixed(2)} Km
                      </div>
                      <span className="divder"></span>
                      <div className="select_store_heading">
                        <button type="button" className="select-store" onClick={() => setStoreTiming(true)}>
                          <img
                            className="icon"
                            alt="icon"
                            src={ProfileStore}
                          />
                          {isOpen ? "OPEN" : "CLOSED"}
                          <DownArrow />
                        </button>
                      </div>
                      <span className="divder"></span>
                      <h6 className="profile-text-sub-para" type="button" onClick={() => setQrPopUp(true)}>Scan QR</h6>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li
                  className={
                    selectedSubcategory === "All" ? "active" : ""
                  }
                  onClick={() => handleSubcategoryClick("All")}
                >
                  <img src={All} alt="all" />
                  <p className="left-tabs"> All</p>
                </li>
                {categoriesData.map((filteredSubcategory, index) => (
                  <li
                    className={
                      filteredSubcategory.title === selectedSubcategory
                        ? "active"
                        : ""
                    }
                    key={index}
                    onClick={() => {
                      handleSubcategoryClick(filteredSubcategory?.title)
                      goToTop()
                    }}
                  >
                    <img
                      src={filteredSubcategory?.imgUrl}
                      alt={filteredSubcategory?.title}
                    />
                    <p className="left-tabs">{filteredSubcategory?.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="categoryCardsContainer">
            <div className="category-flex">
              <h1 className="categorysHeading">{selectedSubcategory}
                <span className="category-total-no">({catalogueCount})</span>
              </h1>
              <div className="sort-by">
                <label className="form-label">Sort By</label>
                <select className="form-select" onChange={(e) => handleSortChange(e.target.value)} >
                  <option value="Relevance">Relevance (a-z)  </option>
                  <option value="PriceLowToHigh">Price: Low to High</option>
                  <option value="PriceHighToLow">Price: High to Low</option>
                </select>
              </div>
            </div>
            {catalogDataTemp?.length === 0 ?
              <div className="stores-list-container">
                {catalogueStatus === "loading" ?
                  <Loader /> :
                  <div className="no-product-flex">
                    <div>
                      <img src={NoProductFoung} alt="Icon" />
                    </div>
                    <p className="m-0">No Product Found !</p>
                  </div>
                }
              </div> :
              <div className="stores-list-bg">
                <div className="store-product-list">
                  {
                    catalogDataTemp.map((data, index) => {
                      return (
                        <div key={index}
                          onClick={() =>
                            navigate("/productDetails", {
                              state: {
                                catalogDataArray: catalogDataTemp,
                                productCode: data?._id ?? "",
                                providerId: data?.providerId ?? ""
                              },
                            })
                          }
                          className="store-product-item"
                        >
                          <div
                            className="store-product-img" >
                            <img
                              className="categorys-imgs"
                              src={data?.productImages?.[0]}
                              alt="productImages"
                              onError={(event) => {
                                if (event.target.src === data?.productImages?.[0] && data?.productImages?.[1]) {
                                  event.target.src = data.productImages[1];
                                }
                                else {
                                  event.target.onerror = null;
                                  event.target.src = BrandLogo;
                                  event.target.classList.add('default-img')
                                }
                              }}
                            />
                          </div>
                          <div className="store-content">
                            <div>
                              <div className="store-product-title"> {data.productName}</div>
                              <div className="store-product-para">{`SELLER: ${storeDetails?.name ?? ""}`}</div>
                              <div className="store-product-rate">
                                <span className="purpleText">
                                  ₹{data?.price}
                                </span>
                                {data?.maximumPrice &&
                                  <span className="line-through">₹{data?.maximumPrice}</span>
                                }
                              </div>
                            </div>
                            <div className="select-variant-flex">
                              {data?.variants?.length > 1 ?
                                <div onClick={(e) => e.stopPropagation()} className=" variant-box">
                                  <div className="select-variant">
                                    <select
                                      className="form-select"
                                      onChange={(e) => setVariant(e.target.value, data)}
                                    >
                                      {data?.variants?.map((variant) => (
                                        <option value={variant?._id}> {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}</option>
                                      ))}

                                    </select>
                                  </div>
                                </div> :
                                <div className="variant-box">
                                  <button type="button" className="btn btn-outline"> {`${(data?.variants?.[0]?.weightUnit === "unit" && !data?.variants?.[0]?.weight) ? "1" : data?.variants?.[0]?.weight} ${formatAndLocalizeText(data?.variants?.[0]?.weightUnit)}`}</button>
                                </div>
                              }
                              <div className="addtoCart" onClick={(e) => e.stopPropagation()}>
                                <div className="add--cart-btn">
                                  <button type="button"
                                    id={data._id}
                                    className="addBtn"
                                    onClick={() => {
                                      updateCartItemQuantity(
                                        data._id,
                                        data.quantity,
                                        data
                                      );
                                    }}
                                  >
                                    Add
                                  </button>
                                  <div
                                    style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                    id={`ui${data._id}`}
                                    className="addtocart-qty"
                                  >
                                    <div
                                      className="addtocart-button button-down minus"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity <= 1
                                            ? 0
                                            : (data.quantity -= 1),
                                          data
                                        );
                                      }}
                                    >
                                      <MinusIcon />
                                    </div>
                                    <input
                                      type="number"
                                      className="addtocart-input"
                                      value={data.quantity}
                                    />
                                    <div
                                      className="addtocart-button button-up"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          (data.quantity += 1),
                                          data
                                        );
                                      }}
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                {/* <div className="pagination-block">
                <Pagination>{items}</Pagination>
                <div className="pagination-item">Items 1-18 of 200</div>
                </div> */}
                {/* <div style={{ marginTop: 30, marginBottom: 100, justifyContent: "center", marginLeft: "40%" }}> */}
                {catalogueStatus === "loading" && catalogDataTemp?.length > 0 &&
                  <Loader />}
              </div>
            }
          </div>
        </div >
      </section >
      <Modal
        isOpen={logout}
        aria-labelledby="contained-modal-title-vcenter"
        toggle={() => {
          setlogout(false);
        }}
        centered
        className="clearcartmodal"
      >
        <div className="confirmLogout">
          <img src={LogoutLogo} alt="Logout" />
          <h1 className="confirm-subtitle">
            Thank you for visiting {sellerDetails?.storeName}
          </h1>
        </div>
        <ModalFooter className="border-0 flex-column">
          <h5 className="confirmtitle">Are you sure you want to logout?</h5>
          <div className="d-flex justify-content-center gap-2">
            <button type="button"
              onClick={() => {
                setlogout(false);
              }}
            >
              No
            </button>
            <button type="button"
              onClick={() => {
                userLogout();
              }}
            >
              Yes
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {
        modalIsOpen && (
          <Login
            isOpenFromCart={false}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
          ></Login>
        )
      }
      <StoretimingModal
        isOpen={storeTiming}
        toggle={() => setStoreTiming(!storeTiming)}
        storeTimings={storeDetails}
      />
      <QrModal
        setQrPopUp={setQrPopUp}
        qrPopUp={qrPopUp}
      ></QrModal>
    </>
  );
};

export default SellerComponent;