import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSellersData, fetchFavouriteSellerData } from "../api/ApiService";

export const initialState = {
  loading: false,
  hasErrors: false,
  sellersStatus: "",
  sellers: [],
  buyItAgainSellers: [],
  favoriteSellers: [],
  favoriteBuyItAgainSellers: [],
  data: {},
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    toggleFavorite(state, action) {
      const sellerId = action.payload;
      
      // Handle toggle for regular sellers
      const seller = state.sellers.find((s) => s._id === sellerId);
      if (seller) {
        seller.isFavorite = !seller.isFavorite;
        if (seller.isFavorite) {
          state.favoriteSellers = [...state.favoriteSellers, seller];
        } else {
          state.favoriteSellers = state.favoriteSellers.filter(
            (favSeller) => favSeller._id !== sellerId
          );
        }
      }
      
      // Handle toggle for buyItAgain sellers
      const buyItAgainSeller = state.buyItAgainSellers.find((s) => s._id === sellerId);
      if (buyItAgainSeller) {
        buyItAgainSeller.isFavorite = !buyItAgainSeller.isFavorite;
        if (buyItAgainSeller.isFavorite) {
          state.favoriteBuyItAgainSellers = [...state.favoriteBuyItAgainSellers, buyItAgainSeller];
        } else {
          state.favoriteBuyItAgainSellers = state.favoriteBuyItAgainSellers.filter(
            (favSeller) => favSeller._id !== sellerId
          );
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellers.pending, (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.sellersStatus = "loading";
      })
      .addCase(fetchSellers.fulfilled, (state, action) => {
        state.sellers = action?.payload?.sellers || [];
        state.buyItAgainSellers = action?.payload?.buyItAgainSellers || [];
        state.favoriteSellers = action?.payload?.favoriteSellers || [];
        state.favoriteBuyItAgainSellers = action?.payload?.favoriteBuyItAgainSellers || [];
        state.data = action.payload;
        state.sellersStatus = "loaded";
        state.hasErrors = false;
      })
      .addCase(fetchSellers.rejected, (state) => {
        state.hasErrors = true;
        state.sellersStatus = "loaded";
      });
  },
});

export const fetchSellers = createAsyncThunk(
  'seller/fetchSellers',
  async (body) => {
    const data = await fetchSellersData(body);
    return data;
  }
);

export const favouriteSellers = createAsyncThunk(
  'seller/favoriteSellers',
  async (body) => {
    const data = await fetchFavouriteSellerData(body);
    return data;
  }
);

export const { toggleFavorite } = sellerSlice.actions;
export default sellerSlice.reducer;
