import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import "./styles.scss";
import { SELLER_BY_SUBCATEGORY } from "../../api/apiList";
import API2 from "../../api/indexv2";
import { notify } from "../../components/common/utils";
import { updateCartItem } from "../../slices/catalogSlice";
import { formatAndLocalizeText } from "../../components/common/utils";
import NoData from "../../components/NewImages/no-data.png";
import Loader from "../../components/common/loader";
import { ToastContainer } from "react-toastify";
import BrandLogo from "../../images/kikoimg.svg";





const CategorySection = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [sellerData, setSellerData] = useState([]);
  // const [catalogDataTemp, setCatalogDataTemp] = useState([]);

  const cartItems = useSelector((s) => s.catalog.cartItems);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [loading, setLoading] = useState(true);

  // const sellers = useSelector((s) => s.sellers.sellers);


  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const defaultLat = parseFloat(defaultAddress?.latitude);
  const defaultLon = parseFloat(defaultAddress?.longitude);

  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };

  const queryParams = getQueryParams(location.search);
  const categoryName = decodeURIComponent(queryParams.get('name'));
  // const { catalogData, catalogDataArray } = location.state;

  const encodedValue = encodeURIComponent(categoryName);
  localStorage.setItem("slug", `/category?name=${encodedValue}`);

  useEffect(() => {
    const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
    dispatch(updateCartItem(cartFromLocalStorage));
  }, [dispatch]);

  useEffect(() => {
    catalogs();
  }, [cartItems]);

  useEffect(() => {
    if (sellerData) {
      catalogs();
    }
  }, []);


  const sellerBySubcategory = async () => {
    try {
      const location = `${defaultLat},${defaultLon}`;
      const body = {
        subCategory: categoryName,
        location: location,
        areaCode: defaultAddress.zipcode
      };
      const response = await API2.post(SELLER_BY_SUBCATEGORY, body);
      if (response.data) {
        let sellers = response.data.data;
        setSellerData(sellers);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };



  useEffect(() => {
    sellerBySubcategory();
  }, []);


  const handleSellerClick = (seller, onPressViewAll) => {
    localStorage.setItem("sellerDetails", JSON.stringify(seller));
    const encodedValue = encodeURIComponent(seller?.providerId);
    if (onPressViewAll) {
      navigate(`/seller?pid=${encodedValue}`);
    }
  };



  const setVariant = (variantId, product) => {

    if (variantId) {
      // Find the selected variant
      const singleVariant = product?.variants.find((item) => item?._id === variantId);

      if (singleVariant) {
        // Update the catalog data immutably
        const updatedSellerData = sellerData.map((seller) => {
          // Update catalog data only if the product ID matches
          const updatedCataloguesArr = seller.cataloguesArr.map((catalog) => {
            if (catalog._id === product._id) {
              // If product ID matches, update the variant
              return {
                ...catalog,
                _id: singleVariant?._id,
                productName: singleVariant?.productName,
                productImages: singleVariant?.productImages,
                weight: singleVariant?.weight,
                weightUnit: singleVariant?.weightUnit,
                price: singleVariant?.price,
                maximumPrice: singleVariant?.maximumPrice,
                description: singleVariant?.description,
                quantity: 1,  // or use the existing cart quantity if needed
              };
            }
            return catalog;
          });

          return { ...seller, cataloguesArr: updatedCataloguesArr };
        });

        setSellerData(updatedSellerData);
        setSelectedVariant(variantId);
      } else {
        console.error("Variant not found");
      }
    }
  };

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems?.findIndex((item) => item?._id === itemId);

    // Handle quantity limits
    if (newQuantity >= parseInt(singleCatalogData?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleCatalogData?.availableQuantity);
    } else if (newQuantity > parseInt(singleCatalogData?.maximumQuantity)) {
      notify("success", `Maximum quantity available is ${singleCatalogData?.maximumQuantity} for this product`);
    } else {
      let updatedCartItems;
      let updatedSellerData;

      if (itemIndex !== -1) {
        // Update the cart item if it's already in the cart
        updatedCartItems = cartItems.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        );

        // If quantity is 0, remove the item from the cart
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        }
      } else {
        // Add the item if it’s not in the cart
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: singleCatalogData._id,
            quantity: newQuantity,
            catalogData: singleCatalogData,
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }

      // Update local storage and Redux state
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));

      // Update sellerData state with new quantity
      updatedSellerData = sellerData.map((seller) => {
        const updatedCataloguesArr = seller.cataloguesArr.map((catalogue) => {
          if (catalogue._id === itemId) {
            return { ...catalogue, quantity: newQuantity };  // Update the quantity in seller data
          }
          return catalogue;
        });
        return { ...seller, cataloguesArr: updatedCataloguesArr };
      });

      setSellerData(updatedSellerData); // Set the updated seller data state
    }
  };




  const catalogs = (isFromScroll, page) => {
    if (!sellerData || !sellerData.length) {
      return;
    }

    // Update seller data based on cart items
    const updatedSellerData = sellerData.map(seller => {
      const updatedCataloguesArr = seller.cataloguesArr.map(catalog => {
        const cartItem = cartItems.find(item => item._id === catalog._id);
        return cartItem
          ? { ...catalog, quantity: cartItem.quantity }
          : { ...catalog, quantity: 1 };  // Default to 1 if not in the cart
      });
      return { ...seller, cataloguesArr: updatedCataloguesArr };
    });

    setSellerData(updatedSellerData);
  };


  // const ProductDetailSliderSettings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   // responsive: [
  //   //   {
  //   //     breakpoint: 1378,
  //   //     settings: {
  //   //       slidesToShow: 5,
  //   //       slidesToScroll: 1,
  //   //       infinite: true,
  //   //     },
  //   //   },
  //   //   {
  //   //     breakpoint: 1160,
  //   //     settings: {
  //   //       slidesToShow: 4,
  //   //       slidesToScroll: 1,
  //   //       infinite: true,
  //   //     },
  //   //   },
  //   //   {
  //   //     breakpoint: 992,
  //   //     settings: {
  //   //       slidesToShow: 3,
  //   //       slidesToScroll: 1,
  //   //       arrows: false,
  //   //     },
  //   //   },
  //   //   {
  //   //     breakpoint: 580,
  //   //     settings: {
  //   //       slidesToShow: 2,
  //   //       slidesToScroll: 1,
  //   //       arrows: false,
  //   //     },
  //   //   },
  //   // ],
  // };

  const sliderProduct = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };



  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <section className="product-header-section">
        <div className="category-page-heading">{categoryName}</div>
        <div className="category-page-para">
          <a href="# ">Home /</a> <a href="# ">Grocery /</a> <span>{categoryName}</span>
        </div>
      </section>


      <section className="similar-product-block">
        <div className="container">
          <div className="similar-product-flex">
            {loading ? <Loader /> : sellerData?.length > 0 ? (
              sellerData?.map((seller, sellerIndex) => (
                <div className="similar-products-container" key={sellerIndex}>
                  <div className="Similar-product-title">
                    <div className="Similar-product-heading">{seller?.name}</div>
                    <div></div>
                    <div>
                      <button
                        type="button"
                        className="similar-product-view"
                        onClick={() => handleSellerClick(seller, true)}
                      >
                        View All
                      </button>
                    </div>
                  </div>
                  <Slider
                    className="slider-product"
                    {...sliderProduct}
                  >
                    {seller?.cataloguesArr.map((product, index) => {
                      if (!product || !product._id) return null;
                      const isProductInCart = cartItems.some((item) => item?._id === product?._id);
                      return (
                        <div key={`${product._id}-${index}`}>
                          <div
                            className="product-item-card">
                            <div onClick={() => {
                              handleSellerClick(seller, false)
                              navigate("/productDetails", {
                                state: {
                                  catalogDataArray: seller.cataloguesArr,
                                  productCode: product?._id ?? "",
                                  providerId: product?.providerId ?? ""
                                },
                              })
                            }}>
                              <div
                                className="product-item-card-thumb-container">
                                <div className="product-item-card-thumb-updated">
                                  <img
                                    className="product-card-thumb"
                                    src={product?.productImages?.[0]}
                                    alt="Product Catalog"
                                    lazyLoad
                                    onError={(event) => {
                                      event.target.onerror = null;
                                      event.target.src = BrandLogo;
                                      event.target.classList.add("default-img");
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="product-item-card-info">
                                <div className="product-title">
                                  {product?.productName}
                                </div>
                                {/* <div className="product-store-name">SELLER: {seller?.name}</div> */}
                                <div className="product-price">
                                  <span className="product-final-price">
                                    ₹{product?.price}
                                  </span>
                                  <span className="product-mrp-price">₹{product?.price}</span>
                                </div>

                              </div>
                            </div>
                            <div className="product-card-footer">
                              {product?.variants?.length > 1 ?
                                <div className="product-variant" onClick={(e) => e.stopPropagation()} >
                                  <select
                                    className="form-select select-variant"
                                    onChange={(e) => setVariant(e.target.value, product)}
                                  >
                                    {product?.variants?.map((variant) => (
                                      <option key={variant?._id} value={variant?._id}>
                                        {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}
                                      </option>))}
                                  </select>
                                </div>
                                :
                                <div className="product-variant">
                                  <button type="button" className="btn product-variant-default btn-outline"> {`${(product?.variants?.[0]?.weightUnit === "unit" && !product?.variants?.[0]?.weight) ? "1" : product?.variants?.[0]?.weight} ${formatAndLocalizeText(product?.variants?.[0]?.weightUnit)}`}</button>
                                </div>
                              }

                              <div className="product-actions">
                                {isProductInCart ? (
                                  <div className="product-add-to-cart">
                                    <div
                                      className="add-to-cart-btn btn-minus"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          product._id,
                                          product.quantity <= 1 ? 0 : product.quantity - 1,
                                          product
                                        );
                                      }}
                                    >
                                      <MinusIcon />
                                    </div>
                                    <input
                                      type="number"
                                      className="add-to-cart-input"
                                      value={product.quantity || 1}
                                      readOnly
                                    />
                                    <div className="add-to-cart-btn btn-add" onClick={() => {
                                      updateCartItemQuantity(product._id, (product.quantity + 1), product);
                                    }}>
                                      <PlusIcon />
                                    </div>


                                  </div>
                                ) : (
                                  <div className="product-add-cart-wrapper">
                                    <button
                                      type="button"
                                      id={product._id}
                                      className="btn product-add-btn"
                                      onClick={() => updateCartItemQuantity(product._id, 1, product)}
                                    >
                                      Add
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ))
            ) : (
              <div className="nothing-match-page">
                <div className="nothing-match">
                  <div className="no-cart-img">
                    <img alt="NoDataImage" src={NoData} />
                  </div>
                  <h6 className="text-center">No Stores Found</h6>
                  <h6 className="text-center" style={{ whiteSpace: "nowrap" }}>
                    for {categoryName} Category !!
                  </h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>


    </>
  );


};

export default CategorySection;