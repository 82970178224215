import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Header from "../Header/component";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NewImages/no-data.png"
import { notify, formatAndLocalizeText } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { updateCartItem } from "../../slices/catalogSlice";
import Loader from "../../components/common/loader";
import { MinusIcon, PlusIcon, StarIcon } from "../../components/svgicons";
import BrandLogo from "../../images/kikoimg.svg";
import ViewAllArrow from "../../images/view-all-arrow.svg";
import "./styles.scss"

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let cartItems = [];
  const [catalogDataTemp, setCatalogDataTemp] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const searchedcatalogues = useSelector((s) => s.catalog.searchedcatalogues);
  const searchCatalogueStatus = useSelector((s) => s.catalog.searchCatalogueStatus);
  const cartItems2 = useSelector((s) => s.catalog.cartItems);
  cartItems = JSON.parse(localStorage.getItem("cart")) || [];

  useEffect(() => {
    catalogs();
  }, [cartItems2]);

  useEffect(() => {
    if (searchedcatalogues) {
      catalogs();
    }
  }, [searchedcatalogues]);

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItems.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleCatalogData.availableQuantity)
    }
    else if (newQuantity > parseInt(singleCatalogData?.maximumQuantity)) {
      notify(
        "success",
        `Maximum quanity available is ${singleCatalogData?.maximumQuantity} for this product`
      );
    }
    else {
      let updatedCartItems = [...cartItems];
      if (itemIndex !== -1) {
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        } else {
          updatedCartItems[itemIndex].quantity = newQuantity;
        }
      } else {
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: singleCatalogData._id,
            quantity: newQuantity,
            catalogData: singleCatalogData,
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));
    }
  };

  const catalogs = () => {
    if (!searchedcatalogues) {
      return;
    }
    const data = searchedcatalogues?.map((data) => {
      const index = cartItems?.findIndex((item) => item._id === data._id);
      if (index !== -1) {
        return { ...data, quantity: cartItems[index].quantity };
      } else {
        return { ...data, quantity: 1 };
      }
    });
    setCatalogDataTemp([...data]);
  };
  const setVariant = (variantId, data) => {
    if (variantId) {
      const singleVariant = data?.variants.find((item) => { return item?._id === variantId })
      const index = cartItems?.findIndex((item) => item?._id === variantId);
      const tempCatalogs = catalogDataTemp.map((catalog) => {
        if (catalog?._id === data?._id) {
          return {
            ...catalog,
            _id: singleVariant?._id,
            productName: singleVariant?.productName,
            productImages: singleVariant?.productImages,
            weight: singleVariant?.weight,
            weightUnit: singleVariant?.weightUnit,
            price: singleVariant?.price,
            maximumPrice: singleVariant?.maximumPrice,
            description: singleVariant?.description,
            quantity: index !== -1 ? cartItems[index].quantity : 1
          }
        } else {
          return { ...catalog }
        }
      })
      setCatalogDataTemp(tempCatalogs)
      setSelectedVariant(variantId)
    }
  }
  var SearchProductSetting = {
    dots: false,
    arrow: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
    ]
  };
  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <div>
        {searchCatalogueStatus === "loaded" && catalogDataTemp && catalogDataTemp.length > 0 ? (
          <div className="searchComponent">
            <div className="container">
              <div className="search-result-heading">Search Result for ‘Aata’</div>
              <div className="store-products-name">
                <div className="search-heading-flex">
                  <div className="search-store-name">Aggarwal Provision Store</div>
                  <div className="search-view-all">
                    <a href="# " className="search-view-link">View All
                      <img src={ViewAllArrow} alt="Icon" />
                    </a>
                  </div>
                </div>
                <div className="categoryCardsContainer w-100">
                  {/* <div className="stores-list mt-0"> */}

                  <Slider {...SearchProductSetting}>
                    {catalogDataTemp?.map((data, index) => (
                      <div key={index}>
                        <div className="search-product-block">
                          <div className="store-product-item clickable">
                            <div
                              className="store-product-img"
                              onClick={() =>
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: catalogDataTemp,
                                    productCode: data?._id ?? "",
                                    providerId: data?.providerId ?? ""
                                  },
                                })
                              }
                            >
                              {data?.productImages && data?.productImages.length > 0 && (
                                <img className="categorys-imgs" src={data?.productImages?.[0]} alt={data?.productName}
                                  onError={(event) => {
                                    event.target.onerror = null;
                                    event.target.src = BrandLogo;
                                    event.target.classList.add('default-img')
                                  }}
                                />
                              )}
                            </div>
                            <div className="store-content">
                              <div>
                                <div className="store-product-title"> {data?.productName}</div>
                                <div className="product-rating">
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon className="active" />
                                  <span className="product-rating-number">(4.0)</span>
                                </div>
                                <div className="store-product-para">SELLER: Sriveda Sattva Limited</div>
                                <div className="store-product-rate">
                                  <span className="purpleText">
                                    ₹{data?.price}
                                  </span>
                                  <span className="line-through">₹294</span>
                                </div>
                              </div>

                              <div className="select-variant-flex">
                                {data?.variants?.length > 1 ?
                                  <div onClick={(e) => e.stopPropagation()} className=" variant-box">
                                    <div className="select-variant">
                                      <select
                                        className="form-select"
                                        onChange={(e) => setVariant(e.target.value, data)}
                                      >
                                        {data?.variants?.map((variant) => (
                                          <option value={variant?._id}> {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}</option>
                                        ))}

                                      </select>
                                    </div>
                                  </div> :
                                  <div className="variant-box">
                                    <button type="button" className="btn btn-outline"> {`${(data?.variants?.[0]?.weightUnit === "unit" && !data?.variants?.[0]?.weight) ? "1" : data?.variants?.[0]?.weight} ${formatAndLocalizeText(data?.variants?.[0]?.weightUnit)}`}</button>
                                  </div>
                                }

                                <div className="addtoCart">
                                  <div className="add--cart-btn">
                                    <button type="button"
                                      id={data._id}
                                      className="addBtn"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity,
                                          data
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                    <div
                                      style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                      id={`ui${data._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className="addtocart-button button-down minus"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            data.quantity <= 1
                                              ? 0
                                              : (data.quantity -= 1),
                                            data
                                          );
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={data.quantity}
                                      />
                                      <div
                                        className="addtocart-button button-up"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            (data.quantity += 1),
                                            data
                                          );
                                        }}
                                      >
                                        <PlusIcon />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
              <div className="store-products-name">
                <div className="search-heading-flex">
                  <div className="search-store-name">Aggarwal Provision Store</div>
                  <div className="search-view-all">
                    <a href="# " className="search-view-link">View All
                      <img src={ViewAllArrow} alt="Icon" />
                    </a>
                  </div>
                </div>
                <div className="categoryCardsContainer w-100">
                  {/* <div className="stores-list mt-0"> */}

                  <Slider {...SearchProductSetting}>
                    {catalogDataTemp?.map((data, index) => (
                      <div key={index}>
                        <div className="search-product-block">
                          <div className="store-product-item clickable">
                            <div
                              className="store-product-img"
                              onClick={() =>
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: catalogDataTemp,
                                    productCode: data?._id ?? "",
                                    providerId: data?.providerId ?? ""
                                  },
                                })
                              }
                            >
                              {data?.productImages && data?.productImages.length > 0 && (
                                <img className="categorys-imgs" src={data?.productImages?.[0]} alt={data?.productName}
                                  onError={(event) => {
                                    event.target.onerror = null;
                                    event.target.src = BrandLogo;
                                    event.target.classList.add('default-img')
                                  }}
                                />
                              )}
                            </div>
                            <div className="store-content">
                              <div>
                                <div className="store-product-title"> {data?.productName}</div>
                                <div className="product-rating">
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon className="active" />
                                  <span className="product-rating-number">(4.0)</span>
                                </div>
                                <div className="store-product-para">SELLER: Sriveda Sattva Limited</div>
                                <div className="store-product-rate">
                                  <span className="purpleText">
                                    ₹{data?.price}
                                  </span>
                                  <span className="line-through">₹294</span>
                                </div>
                              </div>

                              <div className="select-variant-flex">
                                {data?.variants?.length > 1 ?
                                  <div onClick={(e) => e.stopPropagation()} className=" variant-box">
                                    <div className="select-variant">
                                      <select
                                        className="form-select"
                                        onChange={(e) => setVariant(e.target.value, data)}
                                      >
                                        {data?.variants?.map((variant) => (
                                          <option value={variant?._id}> {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}</option>
                                        ))}

                                      </select>
                                    </div>
                                  </div> :
                                  <div className="variant-box">
                                    <button type="button" className="btn btn-outline"> {`${(data?.variants?.[0]?.weightUnit === "unit" && !data?.variants?.[0]?.weight) ? "1" : data?.variants?.[0]?.weight} ${formatAndLocalizeText(data?.variants?.[0]?.weightUnit)}`}</button>
                                  </div>
                                }

                                <div className="addtoCart">
                                  <div className="add--cart-btn">
                                    <button type="button"
                                      id={data._id}
                                      className="addBtn"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity,
                                          data
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                    <div
                                      style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                      id={`ui${data._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className="addtocart-button button-down minus"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            data.quantity <= 1
                                              ? 0
                                              : (data.quantity -= 1),
                                            data
                                          );
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={data.quantity}
                                      />
                                      <div
                                        className="addtocart-button button-up"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            (data.quantity += 1),
                                            data
                                          );
                                        }}
                                      >
                                        <PlusIcon />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
              <div className="store-products-name">
                <div className="search-heading-flex">
                  <div className="search-store-name">Aggarwal Provision Store</div>
                  <div className="search-view-all">
                    <a href="# " className="search-view-link">View All
                      <img src={ViewAllArrow} alt="Icon" />
                    </a>
                  </div>
                </div>
                <div className="categoryCardsContainer w-100">
                  {/* <div className="stores-list mt-0"> */}

                  <Slider {...SearchProductSetting}>
                    {catalogDataTemp?.map((data, index) => (
                      <div key={index}>
                        <div className="search-product-block">
                          <div className="store-product-item clickable">
                            <div
                              className="store-product-img"
                              onClick={() =>
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: catalogDataTemp,
                                    productCode: data?._id ?? "",
                                    providerId: data?.providerId ?? ""
                                  },
                                })
                              }
                            >
                              {data?.productImages && data?.productImages.length > 0 && (
                                <img className="categorys-imgs" src={data?.productImages?.[0]} alt={data?.productName}
                                  onError={(event) => {
                                    event.target.onerror = null;
                                    event.target.src = BrandLogo;
                                    event.target.classList.add('default-img')
                                  }}
                                />
                              )}
                            </div>
                            <div className="store-content">
                              <div>
                                <div className="store-product-title"> {data?.productName}</div>
                                <div className="product-rating">
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon className="active" />
                                  <span className="product-rating-number">(4.0)</span>
                                </div>
                                <div className="store-product-para">SELLER: Sriveda Sattva Limited</div>
                                <div className="store-product-rate">
                                  <span className="purpleText">
                                    ₹{data?.price}
                                  </span>
                                  <span className="line-through">₹294</span>
                                </div>
                              </div>

                              <div className="select-variant-flex">
                                {data?.variants?.length > 1 ?
                                  <div onClick={(e) => e.stopPropagation()} className=" variant-box">
                                    <div className="select-variant">
                                      <select
                                        className="form-select"
                                        onChange={(e) => setVariant(e.target.value, data)}
                                      >
                                        {data?.variants?.map((variant) => (
                                          <option value={variant?._id}> {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}</option>
                                        ))}

                                      </select>
                                    </div>
                                  </div> :
                                  <div className="variant-box">
                                    <button type="button" className="btn btn-outline"> {`${(data?.variants?.[0]?.weightUnit === "unit" && !data?.variants?.[0]?.weight) ? "1" : data?.variants?.[0]?.weight} ${formatAndLocalizeText(data?.variants?.[0]?.weightUnit)}`}</button>
                                  </div>
                                }

                                <div className="addtoCart">
                                  <div className="add--cart-btn">
                                    <button type="button"
                                      id={data._id}
                                      className="addBtn"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity,
                                          data
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                    <div
                                      style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                      id={`ui${data._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className="addtocart-button button-down minus"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            data.quantity <= 1
                                              ? 0
                                              : (data.quantity -= 1),
                                            data
                                          );
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={data.quantity}
                                      />
                                      <div
                                        className="addtocart-button button-up"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            (data.quantity += 1),
                                            data
                                          );
                                        }}
                                      >
                                        <PlusIcon />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
              <div className="store-products-name">
                <div className="search-heading-flex">
                  <div className="search-store-name">Aggarwal Provision Store</div>
                  <div className="search-view-all">
                    <a href="# " className="search-view-link">View All
                      <img src={ViewAllArrow} alt="Icon" />
                    </a>
                  </div>
                </div>
                <div className="categoryCardsContainer w-100">
                  {/* <div className="stores-list mt-0"> */}

                  <Slider {...SearchProductSetting}>
                    {catalogDataTemp?.map((data, index) => (
                      <div key={index}>
                        <div className="search-product-block">
                          <div className="store-product-item clickable">
                            <div
                              className="store-product-img"
                              onClick={() =>
                                navigate("/productDetails", {
                                  state: {
                                    catalogDataArray: catalogDataTemp,
                                    productCode: data?._id ?? "",
                                    providerId: data?.providerId ?? ""
                                  },
                                })
                              }
                            >
                              {data?.productImages && data?.productImages.length > 0 && (
                                <img className="categorys-imgs" src={data?.productImages?.[0]} alt={data?.productName}
                                  onError={(event) => {
                                    event.target.onerror = null;
                                    event.target.src = BrandLogo;
                                    event.target.classList.add('default-img')
                                  }}
                                />
                              )}
                            </div>
                            <div className="store-content">
                              <div>
                                <div className="store-product-title"> {data?.productName}</div>
                                <div className="product-rating">
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon />
                                  <StarIcon className="active" />
                                  <span className="product-rating-number">(4.0)</span>
                                </div>
                                <div className="store-product-para">SELLER: Sriveda Sattva Limited</div>
                                <div className="store-product-rate">
                                  <span className="purpleText">
                                    ₹{data?.price}
                                  </span>
                                  <span className="line-through">₹294</span>
                                </div>
                              </div>

                              <div className="select-variant-flex">
                                {data?.variants?.length > 1 ?
                                  <div onClick={(e) => e.stopPropagation()} className=" variant-box">
                                    <div className="select-variant">
                                      <select
                                        className="form-select"
                                        onChange={(e) => setVariant(e.target.value, data)}
                                      >
                                        {data?.variants?.map((variant) => (
                                          <option value={variant?._id}> {`${(variant?.weightUnit === "unit" && !variant?.weight) ? "1" : variant?.weight} ${formatAndLocalizeText(variant?.weightUnit)}`}</option>
                                        ))}

                                      </select>
                                    </div>
                                  </div> :
                                  <div className="variant-box">
                                    <button type="button" className="btn btn-outline"> {`${(data?.variants?.[0]?.weightUnit === "unit" && !data?.variants?.[0]?.weight) ? "1" : data?.variants?.[0]?.weight} ${formatAndLocalizeText(data?.variants?.[0]?.weightUnit)}`}</button>
                                  </div>
                                }

                                <div className="addtoCart">
                                  <div className="add--cart-btn">
                                    <button type="button"
                                      id={data._id}
                                      className="addBtn"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity,
                                          data
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                    <div
                                      style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                      id={`ui${data._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className="addtocart-button button-down minus"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            data.quantity <= 1
                                              ? 0
                                              : (data.quantity -= 1),
                                            data
                                          );
                                        }}
                                      >
                                        <MinusIcon />
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={data.quantity}
                                      />
                                      <div
                                        className="addtocart-button button-up"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            (data.quantity += 1),
                                            data
                                          );
                                        }}
                                      >
                                        <PlusIcon />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          searchCatalogueStatus === "loading" ?
            <p className="search-loader"><Loader /></p> :
            (searchCatalogueStatus !== "loading" && searchCatalogueStatus !== "loaded") ?
              <div className="nothing-match">
                <p></p>
              </div> :
              <div className="nothing-match">
                <div className="text-center">
                  <img src={NoData} alt="oops not found" style={{ maxWidth: "180px" }} />
                </div>
                <p>Nothing here yet</p>
              </div>
        )}
      </div>
    </>
  );
};

export default Search;