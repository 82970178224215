import React from "react";

const Rightarrow = (props) => (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    fill="none"
    viewBox="0 0 6 10"
  >
    <path
      fill="#444955"
      d="M.967 7.91 3.877 5 .967 2.09a.747.747 0 1 1 1.058-1.058l3.442 3.442a.747.747 0 0 1 0 1.058L2.025 8.974a.747.747 0 0 1-1.058 0 .763.763 0 0 1 0-1.065"
    ></path>
  </svg>
);


export default Rightarrow;
