import React, { useEffect, useState } from "react";
import Plus from "../../components/svgicons/plus";
import ThreeDots from "../../images/DotsThree.svg";
import API from "../../api/indexv2";
import { get } from "lodash";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GET_USER_ADDRESS } from "../../api/apiList";
import { handleError } from "../../components/common/utils";
import { useSelector, useDispatch } from "react-redux";
import blacksearchIcon from "../../images/blacksearchicon.svg";
import locationIcon from "../../images/location-icon.svg";
import searchIcon from "../../images/searchIcon.svg";
import WrappedMap from "../../components/Map/map";
import Loader from "../../components/common/loader";
import {
  getPlacesDetails,
  placeDetail,
  handleAddress,
} from "../../api/ApiService";
import store from "../../store";
import { getLocations, startLoadingMap } from "../../slices/mapSlice";
import debounce from "lodash/debounce";
import { USER_DATA } from "../../components/common/constant";
import "./styles.scss";
import { fetchSellers } from "../../slices/sellerSlice";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";

const ManageAddress = (props) => {
  const dispatch = useDispatch();
  const { setZipcode, setYourAddressModal, isInHeader, onAddressSelected } = props;
  const [userAddress, setUserAddress] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectDeleteAddress, setDeletedAddress] = useState({});
  const [addressStatus, setAddressStatus] = useState("");
  const [editAddress, setEditAddress] = useState({});
  const address = useSelector((s) => s.mapApi.address);
  const addresses = useSelector((s) => s.address);
  const [tempAddress, setTempAddress] = useState({});
  const [getCurrentLocationLoader, setGetCurrentLocationLoader] =
    useState(false);
  const [loadingAddAddress, setLoadingAddAddress] = useState(false);
  const [searchmodal, setSearchModal] = useState(false);
  const [openFromEdit, setOpenFromEdit] = useState(false);
  const locations = useSelector((s) => s.mapApi.locations);
  const loadingMapAddress = useSelector((s) => s.mapApi.startLoadingMap);
  const selectAddressLoader = useSelector(
    (s) => s.mapApi.selectAddressMapLoading
  );
  const [map, setMap] = useState(false);
  const [building, setBuilding] = useState("");
  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };
  const userData = getUserFromLocalStorage();
  const [emailId, setEmailId] = useState(userData ? userData.email : "");
  const [buyerName, setBuyerName] = useState(userData ? userData.name : "");
  const [buyerNumber, setBuyerNumber] = useState(
    userData ? userData.mobile : ""
  );

  const handleSeller = (defaultAddress) => {
    dispatch(
      fetchSellers({
        latitude: defaultAddress?.region?.latitude,
        longitude: defaultAddress?.region?.longitude,
        areaCode: defaultAddress?.zipcode,
      })
    );
  };

  useEffect(() => {
    setTempAddress({ ...address, tag: "HOME" });
    setEditAddress({
      ...editAddress, ...address,
      tag: "HOME",
      region: {
        latitude: address?.latitude,
        longitude: address?.longitude,
      },
    });
    if (address?.latitude && searchmodal) {
      setMap(true);
      setSearchModal(false);
    }
  }, [address]);

  const checkValidation = (value) => {
    if (!value || value === undefined || value == null || value === "") {
      return false;
    } else {
      return true;
    }
  };

  const checkEmailPhoneValidation = (type, value) => {
    if (type === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    } else if (type === "phone") {
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(value);
    } else {
      return true;
    }
  };

  const onChangePincodeDetail = (e) => {
    if (e.target.value !== "" && e.target.value.length >= 3) {
      handleAddress(e.target.value);
    } else {
      store.dispatch(startLoadingMap(false));
      store.dispatch(getLocations([]));
    }
  };
  const debouncedOnChange = debounce(onChangePincodeDetail, 500);
  const SearchResult = () => {
    const searchLocations = locations.map((item) => (
      <div
        className="resultList"
        onClick={() => {
          setTempAddress({});
          placeDetail(item?.place_id);
          setOpenFromEdit(false);
        }}
      >
        <img src={blacksearchIcon} alt="Search Icon" />
        <div className="resultText">
          <h4>{item?.structured_formatting?.main_text}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    ));
    return <>{searchLocations}</>;
  };

  const updateAddress = () => {
    setLoadingAddAddress(true);
    const addressId = editAddress.addressId;
    if (!addressId) {
      notify("error", "Address ID is missing in editAddress.");
      setLoadingAddAddress(false);
      return;
    }
    const region = {
      latitude: parseFloat(
        editAddress.latitude
          ? editAddress.latitude
          : editAddress.region?.latitude
      ),
      longitude: parseFloat(
        editAddress.longitude
          ? editAddress.longitude
          : editAddress.region?.longitude
      ),
    };
    if ((buyerName + building + tempAddress.address_line2)?.length > 190) {
      notify("warning", "Max Character limit exceeded, Please write short address");
      return;
    }
    const updatedBody = {
      addressId: addressId,
      contactName: buyerName,
      contactPhone: `+91${buyerNumber}`,
      mobile: buyerNumber,
      city: editAddress?.city,
      state: editAddress?.state,
      zipcode: editAddress?.pincode ? editAddress?.pincode : editAddress?.zipcode,
      address_line1: building ? building : "",
      address_line2: editAddress?.address_line2,
      building: building,
      status: editAddress?.status,
      tag: editAddress?.tag,
      region: region,
      emailId: emailId,
    };

    API.post("/edit-address", updatedBody)
      .then((res) => {
        const data = res?.data;
        if (data?.success) {
          fetchAddress();
        } else {
          notify(
            "error",
            "Error occurred while updating address. Please try again."
          );
        }
        setLoadingAddAddress(false);
      })
      .catch((error) => {
        notify(
          "error",
          "An error occurred while updating address. Please try again."
        );
        setLoadingAddAddress(false);
      });
  };

  const deleteAddress = (address) => {
    API.post("/delete-address", { addressId: address._id })
      .then(({ data }) => {
        if (data) {
          fetchAddress();
          setTempAddress({});
        }
      })
      .catch((error) => {
        handleError(error);
        setTempAddress({});
      });
  };

  const saveAddress = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (userAddress && userAddress.length > 0) {
      const defaultAddress = {
        latitude: userAddress[0]?.region?.latitude,
        longitude: userAddress[0]?.region?.longitude,
        zipcode: userAddress[0]?.zipcode,
        address: userAddress[0],
      };
      localStorage.setItem("defaultAddress", JSON.stringify(defaultAddress));
    }
    const isTagValid = checkValidation(tempAddress?.tag);
    const isBuyerEmailValid = checkValidation(emailId);
    const isPhoneNumberValid = checkValidation(buyerNumber);
    const isPinCodeValid = checkValidation(
      tempAddress?.pincode ? tempAddress?.pincode : tempAddress?.zipcode
    );
    const isLandmarkValid = checkValidation(building);
    const isStateValid = checkValidation(tempAddress?.state);
    const isAddressLine2Valid = checkValidation(tempAddress?.address_line2);
    const isCityValid = checkValidation(tempAddress?.city);
    const isLatitudeValid = checkValidation(
      tempAddress.latitude ? tempAddress?.latitude : tempAddress?.region?.latitude
    );
    const isLongitudeValid = checkValidation(
      tempAddress?.longitude
        ? tempAddress?.longitude
        : tempAddress?.region?.longitude
    );
    if (
      !isTagValid ||
      !isPhoneNumberValid ||
      !isPinCodeValid ||
      !isLandmarkValid ||
      !isStateValid ||
      !isAddressLine2Valid ||
      !isCityValid
    ) {
      notify("warning", "Please enter valid details");
    } else if (
      !openFromEdit &&
      (!isBuyerEmailValid ||
        !isPhoneNumberValid ||
        !isPinCodeValid ||
        !isLandmarkValid ||
        !isStateValid ||
        !isAddressLine2Valid ||
        !isCityValid ||
        !isLatitudeValid ||
        !isLongitudeValid)
    ) {
      notify("warning", "Please enter valid details");
    } else {
      setLoadingAddAddress(true);
      const region = {
        latitude: parseFloat(
          tempAddress.latitude
            ? tempAddress.latitude
            : tempAddress.region?.latitude
        ),
        longitude: parseFloat(
          tempAddress.longitude
            ? tempAddress.longitude
            : tempAddress.region?.longitude
        ),
      };
      if ((buyerName + building + tempAddress.address_line2)?.length > 190) {
        notify("warning", "Max Character limit exceeded, Please write short address");
        return;
      }
      const body = {
        contactName: buyerName,
        contactPhone: `+91${buyerNumber}`,
        mobile: buyerNumber,
        city: tempAddress.city,
        state: tempAddress.state,
        zipcode: tempAddress?.pincode
          ? tempAddress.pincode
          : tempAddress.zipcode,
        address_line1: building,
        address_line2: tempAddress.address_line2,
        building: building,
        status: tempAddress.status,
        tag: tempAddress.tag,
        emailId: emailId,
        region: region,
        userId: get(user, "userId", ""),
        uId: get(user, "_id", ""),
      };
      API.post("/create-address", body)
        .then((res) => {
          const data = res?.data;
          if (data.success) {
            fetchAddress();
          } else {
            notify(
              "error",
              "Error occurred while saving address. Please try again."
            );
          }
          setLoadingAddAddress(false);
        })
        .catch((error) => {
          notify(
            "error",
            "Error occurred while saving address. Please try again."
          );
          setLoadingAddAddress(false);
        });
    }
  };

  const fetchAddress = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setAddressStatus("loading");
    API.get(GET_USER_ADDRESS, { params: { userId: get(user, "_id", "") } })
      .then(({ data }) => {
        if (data) {
          setUserAddress(data?.addresses);
        }
        setAddressStatus("loaded");
      })
      .catch((error) => {
        setAddressStatus("error");
        handleError(error);
      });
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setGetCurrentLocationLoader(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (openFromEdit) {
            setEditAddress({
              ...editAddress,
              region: {
                latitude: latitude,
                longitude: longitude,
              },
            });
          }
          else {
            setTempAddress({
              ...tempAddress,
              latitude: latitude,
              longitude: longitude,
            });
          }
          const obj = { latitude, longitude };
          getPlacesDetails(obj);
          setOpenFromEdit(false);
          setMap(true);
          setSearchModal(false);
          setGetCurrentLocationLoader(false);
        },
        (error) => {
          setGetCurrentLocationLoader(false);
          notify("error", "Error in facting your current location Try Again!");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleGetCurrentLocations = () => {
    const { region } = tempAddress;
    if (
      region &&
      region.latitude !== undefined &&
      region.longitude !== undefined
    ) {
      const latitude = Number(region.latitude);
      const longitude = Number(region.longitude);
      setMap(true);
      setSearchModal(false);
      getPlacesDetails({ latitude, longitude });
    } else {
      console.error(
        "Latitude or longitude is not available in the saved address."
      );
    }
  };

  const handleEditButtonClick = (data) => {
    const addressId = data.addressId || data._id || "";
    setTempAddress({})
    setEditAddress({
      ...data,
      addressId: addressId,
    });
    setOpenFromEdit(true);
    setMap(true);
    setBuyerName(data.contactName || "");
    setBuyerNumber(data.mobile || "");
    setEmailId(data.emailId || "");
    setBuilding(data.building || "");
    if (
      data.region &&
      data.region.latitude !== undefined &&
      data.region.longitude !== undefined
    ) {
      handleGetCurrentLocations();
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />{" "}
      <div className="productSection p-0">
        <div className="DetailsSection">
          <div className="modal-header">
            <div className="d-flex align-items-center">
              <div className="your-address-title">Your Addresses</div>
            </div>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="detail-modal-btn">
            <button
              type="button"
              className=" btn btn-primary"
              onClick={() => {
                setSearchModal(true);
                setOpenFromEdit();
              }}
            >
              <Plus className="me-2" type="button" />
              Add Address
            </button>
          </div>
          <div className="cardGrid ">
            {addressStatus === "loading" && <Loader />}
            {addressStatus === "loaded" &&
              userAddress &&
              userAddress.length > 0 ? (
              userAddress.map((data, index) => {
                return (
                  <div className="card" key={data?._id}>
                    <label>
                      {!isInHeader && (
                        <input
                          name="plan"
                          defaultChecked={
                            localStorage.getItem("defaultAddress") &&
                            JSON.parse(localStorage.getItem("defaultAddress"))
                              ?.address?._id === data._id
                          }
                          className="radio"
                          type="radio"
                          onClick={() => {
                            setZipcode(data?.zipcode);
                            const defaultAddress = {
                              latitude: data?.region?.latitude,
                              longitude: data?.region?.longitude,
                              zipcode: data?.zipcode,
                              address: data,
                            };
                            handleSeller(defaultAddress);
                            localStorage.setItem(
                              "defaultAddress",
                              JSON.stringify(defaultAddress)
                            );
                            setYourAddressModal(false);
                            if (onAddressSelected) {
                              onAddressSelected();
                            }
                          }}
                        />
                      )}
                      <div className="plan-details" aria-hidden="true">
                        <div className="plan-type p-0">
                          <span>
                            {data.contactName}{" "}
                            <span>({data.tag ? data.tag : "Other"})</span>
                          </span>
                        </div>
                        <p className="plan-cost">{data.contactPhone}</p>
                        <p className="plan-cost">{data.emailId}</p>
                        <p className="plan-cost">{data.address_line2}</p>
                        <p className="plan-cost m-0">
                          {data.city} {data.zipcode}
                        </p>
                      </div>
                    </label>
                    <div className="threedotsDropdown">
                      <div className="dropdown">
                        <button
                          className=" threedots"
                          type="button"
                          id="dropdownMenuButton1"
                          aria-haspopup="true"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={ThreeDots}
                            className="three-dots"
                            alt="Three Dots"
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <p
                            className="dropdown-item m-0"
                            onClick={() => {
                              setOpenFromEdit(true);
                              fetchAddress();
                              setTempAddress(data);
                              setEditAddress(data);
                              handleEditButtonClick(data);
                              document
                                .getElementById("dropdownMenuButton1")
                                .click();
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className="dropdown-item m-0"
                            onClick={() => {
                              setOpenDeletePopup(true);
                              setDeletedAddress(data);
                            }}
                          >
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                {addressStatus === "loaded" && addresses.length === 0 && (
                  <p>No Address Found!</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={searchmodal}
        toggle={() => {
          setSearchModal(false);
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        style={{ maxWidth: "600px" }}
        centered
      >
        <div className="addressmodalBlock">
          <h2>Search Location</h2>
          <div className="searchBlock">
            <img src={searchIcon} alt="Search1 Icon" />
            <input
              type="search"
              placeholder="Search for an area, location name"
              onChange={debouncedOnChange}
            />
          </div>
          {loadingMapAddress && !selectAddressLoader && <Loader />}
          <div className="currenLocation">
            <img src={locationIcon} alt="Location Icon" />
            {getCurrentLocationLoader ? (
              <Loader />
            ) : (
              <button
                type="button"
                className="AddAddress"
                onClick={() => {
                  handleGetCurrentLocation();
                  setBuyerName()
                  setBuilding()
                  setEmailId()

                }}
              >
                Use Current Location{" "}
              </button>
            )}
          </div>
          <div className={locations.length > 0 && "redultListBlock"}>
            {locations.length > 0 && SearchResult()}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={map}
        aria-labelledby="contained-modal-title-v"
        ed
        toggle={() => {
          setMap(false);
        }}
        className="addressMap-modal"
        centered
      >
        <ModalHeader>
          <div className="edit-address-title">Edit Address</div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setMap(false);
            }}
            style={{ fontSize: '13px' }}
          ></button>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name*"
                value={buyerName}
                onChange={(e) => {
                  setBuyerName(e.target.value);
                }}
              />
              {checkValidation(buyerName) ? null : (
                <p className="error">This field is required.</p>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Alternate Number*"
                value={buyerNumber}
                onChange={(e) => {
                  setBuyerNumber(e.target.value);
                }}
              />
              {checkEmailPhoneValidation("phone", buyerNumber) ? null : (
                <p className="error">
                  Please enter a valid 10-digit phone number.
                </p>
              )}
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                className="form-control "
                placeholder="Email Id*"
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                }}
              />
              {checkEmailPhoneValidation("email", emailId) ? null : (
                <p className="error">Please enter a valid email address.</p>
              )}
            </div>
            <div className="DropLocationModal">
              <h2>Set Delivery Location</h2>
              <div className="DroMapBlock">
                {
                  <WrappedMap
                    currLatlong1={{
                      latitude: openFromEdit ? parseFloat(editAddress?.region?.latitude) : tempAddress.latitude,
                      longitude: openFromEdit ? parseFloat(editAddress?.region?.longitude) : tempAddress.longitude,
                    }}
                    func={(obj) => {
                      getPlacesDetails(obj);
                    }}
                  />
                }
              </div>
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Building Name*"
                value={building}
                onChange={(e) => {
                  setBuilding(e.target.value);
                }}
              />
              {checkValidation(building) ? null : (
                <p className="error">This feild is required.</p>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Pincode*"
                value={
                  (tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode || editAddress?.pincode ? editAddress.pincode : editAddress.zipcode)
                }
                onChange={(e) => {
                  setTempAddress({ ...tempAddress, pincode: e.target.value });
                  setEditAddress({ ...editAddress, pincode: e.target.value });
                }}
              />
              {checkValidation(
                tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode || editAddress?.pincode ? editAddress.pincode : editAddress.zipcode
              ) ? null : (
                <p className="error">This feild is required.</p>
              )}
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="City*"
                value={tempAddress?.city || editAddress?.city}
                onChange={(e) => {
                  setTempAddress({ ...tempAddress, city: e.target.value });
                  setEditAddress({ ...editAddress, city: e.target.value });
                }}
              />
              {checkValidation(tempAddress?.city || editAddress?.city) ? null : (
                <p className="error">This feild is required.</p>
              )}
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="State*"
                value={tempAddress?.state || editAddress?.state}
                onChange={(e) => {
                  setTempAddress({ ...tempAddress, state: e.target.value });
                  setEditAddress({ ...editAddress, state: e.target.value });
                }}
              />
              {checkValidation(
                tempAddress?.state || editAddress?.state
              ) ? null : (
                <p className="error">This feild is required.</p>
              )}
            </div>
            <div className="col-12 mb-3">
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Area, Colony, Street*"
                value={tempAddress.address_line2 || editAddress.address_line2}
                onChange={(e) => {
                  setTempAddress({
                    ...tempAddress,
                    address_line2: e.target.value,
                  });
                  setEditAddress({ ...editAddress, address_line2: e.target.value });
                }}
              />
              {checkValidation(tempAddress?.address_line2 || editAddress?.address_line2) ? null : (
                <p className="error">This feild is required.</p>
              )}
            </div>
            <div className="col-12 mb-3">
              <label>Address Type</label>
              <div className="address-type">
                <label>
                  Home
                  <input
                    type="radio"
                    name="gender"
                    defaultChecked={tempAddress?.tag?.toLowerCase() === "home" || editAddress?.tag?.toLowerCase() === "home"}
                    onChange={(e) => {
                      setTempAddress({ ...tempAddress, tag: "HOME" });
                      setEditAddress({ ...editAddress, tag: "HOME" });
                    }}
                  />
                </label>
                <label>
                  Work
                  <input
                    type="radio"
                    name="gender"
                    defaultChecked={tempAddress?.tag?.toLowerCase() === "work" || editAddress?.tag?.toLowerCase() === "work"}
                    onChange={(e) => {
                      setTempAddress({ ...tempAddress, tag: "WORK" });
                      setEditAddress({ ...editAddress, tag: "WORK" });
                    }}
                  />
                </label>
                <label>
                  Other
                  <input
                    type="radio"
                    name="gender"
                    defaultChecked={tempAddress?.tag?.toLowerCase() === "other" || editAddress?.tag?.toLowerCase() === "other"}
                    onChange={(e) => {
                      setTempAddress({ ...tempAddress, tag: "OTHER" });
                      setEditAddress({ ...editAddress, tag: "OTHER" });
                    }}
                  />
                </label>
              </div>
              {checkValidation(tempAddress?.tag || editAddress.tag) ? null : (
                <p className="error">Please Select Tag.</p>
              )}
            </div>
          </div>
          <div className="button-loader mt-3">
            <button
              disabled={
                !checkValidation(buyerName) ||
                !checkValidation(buyerNumber) ||
                !checkEmailPhoneValidation("email", emailId) ||
                !checkValidation(building)
              }
              className="btn btn-primary w-100"
              type="button"
              onClick={() => {
                if (openFromEdit) {
                  updateAddress();
                } else {
                  saveAddress();
                }
                setMap(false);
              }}
            >
              {openFromEdit ? "UPDATE" : "SAVE & PROCEED"}
            </button>
            <div className="dotLoader">{loadingAddAddress && <Loader />}</div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openDeletePopup}
        toggle={() => {
          setOpenDeletePopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ maxWidth: "600px" }}
      >
        <div className="addressmodalBlock">
          <h2>Delete Address</h2>
          <p>Are you sure you want to delete this address?</p>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setOpenDeletePopup(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => {
                setOpenDeletePopup(false);
                deleteAddress(selectDeleteAddress);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={selectAddressLoader}
        aria-labelledby="contained-modal-title-v "
        ed
        className="loader-modal"
        style={{ maxWidth: "600px", maxHeight: "600px" }}
        centered
      >
        <Loader></Loader>
      </Modal>
    </>
  );
};
export default ManageAddress;
